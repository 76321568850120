import { Fragment, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { PasswordInput, Input, Button, useSnackbar } from '../../../components';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { getStaticTexts } from '../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../services';
import { SET_PROFILE_DETAILS, UPDATE_LOGIN_STATUS } from '../../../redux';
import { startLoader, stopLoader } from '../../../utils';
import { createItem } from '../../../services/aggregation';
import { LocalStorageKeys, UserType } from '../../../constants/AppConstants';

interface FormFields {
  email: string;
  password: string;
}

/**
 * Renders the business login form.
 *
 * The form will accept an email and password for a business user to log in.
 * If the user has not yet registered, they can click on the 'Don't have an account' link to get more info.
 *
 * @returns {ReactElement} The business login form.
 */

export const BusinessLogin = () => {
  const dispatch = useDispatch();
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const enqueueSnackbar = useSnackbar();

  /**
   * Submits the business login form.
   *
   * This function will take the given form data and use the login service to authenticate the user.
   * If the user is successfully authenticated, their token will be stored in local storage, and they will be redirected to the organization page.
   * If there is an error authenticating the user, an error message will be logged to the console.
   *
   * @param {FormFields} data - The form data to be used for authentication.
   */
  const onSubmit = async (data: FormFields) => {
    try {
      startLoader();
      const reportFoundItemData = sessionStorage.getItem('report-found-item-data');
      const response = (await login({
        ...data,
        type: UserType.BUSINESS,
      })) as any;
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, response.data.token);
      localStorage.setItem(LocalStorageKeys.USER_TYPE, response.data.user.type);

      if (reportFoundItemData) {
        const payload = JSON.parse(reportFoundItemData) || {};
        await createItem(payload);
        sessionStorage.removeItem('report-found-item-data');
        enqueueSnackbar(staticTexts.itemPostedSuccessfully);
      };

      dispatch({
        type: UPDATE_LOGIN_STATUS,
        payload: {
          isLoggedIn: true,
        },
      });

      const user = response.data.user
      // Store Default Language
      localStorage.setItem(LocalStorageKeys.LANGUAGE, user.defaultLanguage);

      // Set user details in the redux store
      dispatch({
        type: SET_PROFILE_DETAILS,
        payload: {
          _id: user?._id,
          name: user?.name,
          email: user?.email,
          profile: user?.profile,
          phone: user?.phone,
          gender: user?.gender,
          dob: user?.dob,
          address: user?.address,
          description: user?.description,
          coverProfile: user?.coverProfile,
          type: user?.type,
          defaultLanguage: user?.defaultLanguage,
        },
      });

      // Dismiss Loading
      stopLoader();

      // Navigate to Business Manage Items Screen
      navigate('/business-manage-items', { state: { showOverview: true } });
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message || staticTexts.somethingWentWrong)
      stopLoader();
    }
  };

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <Typography
            component='p'
            fontSize='24px'
            variant='body2'
            className='font-medium'
          >
            {staticTexts.welcomeToLqeetBusiness}
          </Typography>
          <Typography
            component='p'
            fontSize='14px'
            className='text-secondary-200 mt-2'
          >
            {staticTexts.manageTrackAndListItems}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className='mt-6'>
            <Box className='flex items-center rounded-md py-2'>
              <Input
                name='email'
                className='!mb-1'
                label={staticTexts.email}
                control={control}
                placeholder={staticTexts.enterYourRegisteredEmail}
                error={errors.email}
                isRequired
              />
            </Box>
            <Box className='flex items-center rounded-md py-2'>
              <PasswordInput
                name='password'
                label={staticTexts.password}
                className='!mb-1'
                control={control}
                placeholder={staticTexts.enterYourPassword}
                error={errors.password}
                validationRules={{
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters',
                  },
                }}
              />
            </Box>
            <Typography
              className='text-accent text-right text-xs cursor-pointer'
              onClick={() => {
                // Navigate to Forgot Password OTP Screen
                navigate('/auth/business-forgot-password');
              }}

            >
              {staticTexts.forgotPassword}
            </Typography>
          </Box>

          <Box className='flex gap-4 mt-8 justify-center items-center'>
            <Button
              type='submit'
              className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
            >
              {staticTexts.logInToDashboard}
            </Button>
          </Box>
        </form>
        <Typography
          component='p'
          fontSize='12px'
          marginTop='32px'
          variant='body1'
          color='#776E65'
          className='text-center'
        >
          {staticTexts.dontHaveAnAccount}
          <span
            className='underline cursor-pointer'
            onClick={() => navigate('/contact-support')}
          >
            {staticTexts.contactSupport}
          </span>
        </Typography>
      </Box>
    </Fragment>
  );
};
