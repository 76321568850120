import { combineReducers } from '@reduxjs/toolkit';
import { itemsViewReducer } from './my-items';
import { languageReducer } from './language';
import { categoryReducer } from './category';
import { profileReducer } from './profile';
import { messagesReducer } from './messages';
import { chatListReducer } from './chat-list';
import { mobileSearchFilterReducer, searchFilterReducer, searchProductReducer } from './search-products';
import { loaderReducer } from './loader';
import { authReducer } from './auth';
import { claimReducer } from './claim';
import chatWindowReducer from './chat-window';
import { notificationReducer } from './notification';

export const reducer = combineReducers({
  items: itemsViewReducer,
  language: languageReducer,
  category: categoryReducer,
  profile: profileReducer,
  messages: messagesReducer,
  chatlist: chatListReducer,
  searchProducts: searchProductReducer,
  searchFilters: searchFilterReducer,
  loader: loaderReducer,
  auth: authReducer,
  claim: claimReducer,
  chatWindow: chatWindowReducer,
  notification: notificationReducer,
  mobileSearchFilter: mobileSearchFilterReducer,
});
