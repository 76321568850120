import { Fragment, useCallback, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { Button, PhoneNumberInput, useSnackbar } from '../../../components';
import { getStaticTexts } from '../../../assets';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { login } from '../../../services';
import { UserType } from '../../../constants/AppConstants';

interface FormFields {
  prefix: string;
  number: string;
}

/**
 * @description This component renders the login form with an option to log in either with an OTP or password.
 *
 * @param {Object} props - The component props
 * @param {string} props.language - The language of the application
 *
 * @returns {ReactElement} - The Login component
 */

export const Login = () => {
  const navigate = useNavigate();
  const [dialCode, setDialCode] = useState('+966');
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const enqueueSnackbar = useSnackbar();
  const { pathname } = useLocation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      prefix: '+966',
      number: '',
    },
  });

  const handleLogin = useCallback(
    async (data: FormFields, event: any) => {
      const submitType = event.nativeEvent.submitter.value;
      const { prefix, number } = data;

      sessionStorage.setItem('login-data', JSON.stringify({ phone: { prefix : prefix.replace('+', ''), number } }));
      try {
        switch (submitType) {
          case 'otp':
            await login({
              phone: {
                prefix : prefix.replace('+', ''),
                number,
              },
              type: UserType.INDIVIDUAL,
              loginWithOtp: true,
            });
            navigate('/auth/login/otp');
            break;
          case 'password':
            navigate('/auth/login/password');
            break;
          default:
            navigate('/auth/login/password');
            break;
        }
      } catch (error: any) {
        console.log(error);
        enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);

      }
    },
    [navigate]
  );

  const getSubText = useCallback(
    (type: string) =>
      type === 'forgot_password' ? (
        <Fragment>
            <Typography
            component='p'
            fontSize='24px'
            variant='body2'
            className='font-medium'
          >
            {staticTexts.enterYourRegisteredMobileNumber}
          </Typography>
          <Typography
            component='p'
            fontSize='14px'
            className='text-secondary-200 mt-2'
          >
            {staticTexts.aResetCode}
          </Typography>
        </Fragment>
      ) : (
        staticTexts.findReportAndRecoverItemsEasily
      ),
    [staticTexts]
  );
  const handleCountryCodeChange = (dialCode: SelectChangeEvent<string>) => {
    setDialCode(String(dialCode));
    setValue('prefix', String(dialCode).replace('+', ''));
  };

  const showForm = !['password', 'otp'].includes((pathname || '').split('/').pop() || '');

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md mt-16 px-4'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <Typography
            variant='body2'
            component='p'
            fontSize='26px'
            color='#4A403A'
            letterSpacing='0.01px'
          >
            {staticTexts.welcomeText}
          </Typography>
          <Typography
            variant='body1'
            component='p'
            fontSize='16px'
            color='#655B53FA'
            className='text-secondary-200 text-sm mt-2'
          >
            {staticTexts.findReportAndRecoverItemsEasily}
          </Typography>
        </Box>

        {showForm && (
          <form
            className='w-full'
            onSubmit={handleSubmit(handleLogin)}
          >
            <Box className='mt-6'>
              <Box className='flex items-center rounded-md py-2'>
                <PhoneNumberInput
                  name='number'
                  label={staticTexts.phoneNumber}
                  helperText={errors.number?.message || staticTexts.youWillReceiveOTPforVerification}
                  countryCode={dialCode}
                  control={control}
                  error={errors.number}
                  handleCountryCodeChange={handleCountryCodeChange}
                  helperTextColor='#776E65'
                  validationRules={{
                    required: `${staticTexts.phoneNumber} ${staticTexts.isRequired}`,
                  }}
                />
              </Box>
              <Typography
                component='div'
                fontSize='12px'
                color='#776E65'
                marginTop='16px'
              >
                {staticTexts.byLogginInYouWillAgreeToOur}{' '}
                <span
                  className='underline cursor-pointer'
                  onClick={() => navigate('/terms-and-condition')}
                >
                  {staticTexts.termsOfService}
                </span>
                <span className='mx-1'>{staticTexts.and}</span>
                <span
                  className='underline cursor-pointer'
                  onClick={() => navigate('/privacy-policy')}
                >
                  {staticTexts.privacyPolicy}
                </span>
              </Typography>
            </Box>

            <Box className='flex gap-4 mt-8 justify-center items-center'>
              <Button
                type='submit'
                variant='outlined'
                className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
                name='submitType'
                value='password'
              >
                {staticTexts.loginWithPassword}
              </Button>

              <Button
                type='submit'
                className='px-6 py-2 rounded-lg w-fit text-center text-sm'
                name='submitType'
                value='otp'
              >
                {staticTexts.continueWithOTP}
              </Button>
            </Box>
          </form>
        )}
        <Outlet />
        <Typography
          variant='body1'
          fontSize='12px'
          color='#776E65'
          marginTop='24px'
          className='text-center cursor-pointer'
          onClick={() => navigate('/contact-support')}
        >
          {staticTexts.needHelp} <span className='underline'>{staticTexts.contactSupport}</span>
        </Typography>
      </Box>
    </Fragment>
  );
};
