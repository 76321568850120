import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button, SearchBar } from '../../../components';
import { foundSomething, getStaticTexts, lostSomething } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * A card component to display an image, title, description, and a button.
 *
 * @param {string} image - The image URL to be displayed.
 * @param {string} title - The title to be displayed.
 * @param {string} description - The description to be displayed.
 * @param {string} btnText - The button text.
 * @param {() => void} handleClick - The callback function when the button is clicked.
 *
 * @returns {JSX.Element} - The card component.
 */
const Card = (props: {
  image: string;
  title: string;
  description: string;
  btnText: string;
  handleClick: () => void;
}) => {
  const { handleClick } = props;
  return (
    <Box
      className='shadow-sm'
      bgcolor='#FFF7E6'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      borderRadius='8px'
      padding='40px'
      maxWidth='312px'
      minHeight='312px'
    >
      <Box
        component='img'
        src={props.image}
        width='64px'
        height='64px'
        marginBottom='40px'
        className='w-16 h-16 mb-10 rtl:scale-x-[-1]'
      />
      <Typography
        component='p'
        fontSize='16px'
        color='#4A403A'
        textAlign='center'
      >
        {props.title}
      </Typography>
      <Typography
        component='p'
        fontSize='14px'
        color='#655B53FA'
        textAlign='center'
      >
        {props.description}
      </Typography>

      <Button
        buttonStyle={{
          background: '#FF6F61',
          marginTop: '24px',
        }}
        onClick={handleClick}
      >
        {props.btnText}
      </Button>
    </Box>
  );
};

/**
 * A component that renders a screen for adding the first item, with two options:
 * report a lost item or report a found item.
 *
 * @returns {JSX.Element} - The JSX element for the add first item screen.
 */
const AddFirstItem = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <Box className='mx-auto mt-16 w-1/2 min-w-96 flex flex-col justify-center items-center pb-20'>
      <Typography
        color='#4A403A'
        fontSize='26px'
        textAlign='center'
      >
        {staticTexts.addYourFirstItemOrSearchLostAndFound}
      </Typography>
      <Box marginTop='40px'>
        <SearchBar />
      </Box>
      <Box
        className='w-full sm:gap-6 gap-2 mt-12'
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          className='mt-4 mb-4'
          sx={{
            display: 'inline-block',
          }}
        >
          <Card
            image={lostSomething}
            title={staticTexts.iHaveLostSomething}
            description={staticTexts.addDetailsOfLostItem}
            btnText={staticTexts.reportLostItem}
            handleClick={() => navigate('/report-lost-item')}
          />
        </Box>
        <Box
          className='mt-4 mb-4'
          sx={{
            display: 'inline-block',
          }}
        >
          <Card
            image={foundSomething}
            title={staticTexts.iHaveFoundSomething}
            description={staticTexts.addDetailsOfFoundItem}
            btnText={staticTexts.reportFoundItem}
            handleClick={() => navigate('/report-found-item')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddFirstItem;
