import { useState, useEffect } from "react";
import {
  DateInput,
  Dropdown,
  Input,
  ImageUpload,
  LocationInput,
  Modal,
  ContentWrapper,
  useSnackbar,
} from "../../components";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../assets";
import {
  createItem,
  getQuestionnaire,
  updateItem,
} from "../../services/aggregation";
import {
  AppButtonVariant,
  LocalStorageKeys,
  UserType,
} from "../../constants/AppConstants";
import { startLoader, stopLoader } from "../../utils";
import CustomButton from "../../components/Button/CustomButton";

/**
 * Page to report a found item.
 *
 * This page allows users to report a found item. It displays a form with fields
 * for the item's name, category, description, location, date found, and privacy
 * settings. The user can also upload images of the item. The page also displays
 * any questions associated with the category.
 *
 * If the user is not logged in, the page redirects to the login page after
 * submitting the form. If the user is logged in, the page submits the form to
 * the server and displays a success message.
 *
 * The page also handles the case where the user is impersonating a business
 * user. In this case, after submitting the form, the page redirects to the
 * business manage items page.
 *
 * @return {JSX.Element} The page component.
 */

interface Question {
  _id: string;
  label: {
    en: string;
    ar: string;
  };
  _category: {
    _id: string;
    name: string;
    description: string;
  };
  type: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export const ReportFoundItem = () => {
  const { state } = useLocation();
  const language = useSelector((state: any) => state.language);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    itemName: string;
    category: { label: string; value: string };
    description: string;
    date: string;
    location: string;
    privacy: string;
    public: boolean;
    questions: { [key: string]: string };
  }>({
    defaultValues: {
      category: state?._category
        ? {
            label: state._category.name?.[language]?.charAt(0).toUpperCase() + state._category.name?.[language]?.slice(1).toLowerCase(),
            value: state._category._id,
          }
        : undefined,
    },
  });
  const navigate = useNavigate();
  const [locationValue, setLocationValue] = useState({
    longitude: 0,
    latitude: 0,
    description: "",
  });
  const enqueueSnackbar = useSnackbar();
  const [images, setImages] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const staticTexts = getStaticTexts(language);
  const categories = useSelector((state: any) => state.category);
  const selectedCategory = watch("category");
  const profileType = useSelector((state: any) => state.profile?.type);
  const userType =
    localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE) || profileType;
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (selectedCategory) {
      fetchQuestions(selectedCategory);
    }
  }, [selectedCategory]);

  /**
   * Handles the closing of the modal.
   * Reloading the window to reset the state and closes the modal.
   */
  const handleClose = () => {
    window.location.reload();
    setIsOpen(false);
  };
  /**
   * Handles the confirmation of the modal.
   * Navigates to the list of items based on the user type and closes the modal.
   */
  const handleConfirm = () => {
    const isBusinessUser =
      localStorage.getItem(LocalStorageKeys.USER_TYPE)?.toUpperCase() ===
      UserType.BUSINESS;
    isBusinessUser ? navigate("/business-manage-items") : navigate("/my-items");
    setIsOpen(false);
  };

  useEffect(() => {
    if (state) {
      setValue("itemName", state.name || "");
      setValue("description", state.description || "");
      setValue("date", state.eventDate || "");
      setValue("location", state.location?.name || "");
      setValue("public", state.visibleToEveryone || false);
      setImages(state.images || []);

      if (state.location) {
        setLocationValue({
          longitude: state.location.coordinates[0] || 0,
          latitude: state.location.coordinates[1] || 0,
          description: state.location.name || "",
        });
      }

      if (state.questions) {
        const questionValues: { [key: string]: string } = {};
        state.questions.forEach((q: any) => {
          questionValues[q._question._id] = q.value;
        });
        setValue("questions", questionValues);
      }
    }
  }, [state, setValue]);

  /**
   * Fetches the questions for a given category ID.
   * @param {Object} category - The category object containing the category ID.
   * @returns {Promise<void>} - A promise that resolves when the questions have been fetched.
   */
  const fetchQuestions = async (category: any) => {
    try {
      const questionData = (await getQuestionnaire(
        "FOUND",
        category?.value
      )) as any;

      setQuestions(questionData.questions);
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong
      );
    }
  };

  /**
   * Handles changes to the user's selected location.
   * @param {Object} location - An object containing the latitude, longitude, and description of the selected location.
   */
  const handleLocationValueChange = (location: { lat: number; lng: number; description: string }) => {
    setLocationValue({
      latitude: location.lat,
      longitude: location.lng,
      description: location.description,
    });
  };

  /**
   * Handles the user clicking on the "Use current location" button.
   * @param {Object} location - An object containing the latitude and longitude of the user's current location.
   * @param {String} error - An error message if there was an issue getting the user's location.
   */
  const handleCurrentLocationClick = (
    location: { latitude: number; longitude: number } | null,
    error: string | null
  ) => {
    if (error) {
      enqueueSnackbar(error);
      return;
    }

    if (location) {
      setLocationValue({
        latitude: location.latitude,
        longitude: location.longitude,
        description: "",
      });
      setValue("location", "");
    }
  };

  /**
   * Handles the creation of a new found item report.
   * This function prepares a payload based on the provided data and sends a request to create the found item.
   * It also manages the loading state and displays appropriate feedback messages based on the success or failure of the operation.
   * @param {Object} data - The form data containing information about the found item, including its name, images, category, description, location, date, visibility, and related questions.
   * @returns {Promise<void>} - A promise that resolves when the item has been successfully created or rejects if there is an error.
   */
  const handleCreateItem = async (data: any) => {
    try {
      // Show Loading
      startLoader();

      // Prepare Request Payload
      const payload = {
        name: data.itemName,
        images: images,
        categoryId: data.category.value,
        description: data.description,
        location: {
          name: locationValue.description,
          coordinates: [locationValue.longitude, locationValue.latitude],
        },
        eventDate: new Date(data.date).toISOString(),
        visibleToEveryone: data.public,
        type: "FOUND",
        questions: Object.entries(data.questions || {}).map(([id, answer]) => ({
          _question: id,
          value: answer,
        })),
      };

      if (!isLoggedIn) {
        sessionStorage.setItem(
          "report-found-item-data",
          JSON.stringify(payload)
        );
        navigate("/auth/login");
        return;
      }

      // API Call: Report Found Item
      await createItem(payload);

      // Show Message Modal
      setIsOpen(true);
    } catch (error: any) {
      // Show Message
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong
      );
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };

  /**
   * Handles the update of an existing found item report.
   * This function prepares a payload based on the provided data and sends a request to update the found item.
   * It also manages the loading state and displays appropriate feedback messages based on the success or failure of the operation.
   *
   * @param {Object} data - The form data containing information about the found item, including its name, images, category, description, location, date, visibility, and related questions.
   *
   * @returns {Promise<void>} - A promise that resolves when the item has been successfully updated or rejects if there is an error.
   */
  const handleUpdateItem = async (data: any) => {
    try {
      const payload = {
        name: data.itemName,
        images: images,
        categoryId: data.category.value,
        description: data.description,
        location: {
          name: locationValue.description,
          type: "Point",
          coordinates: [locationValue.longitude, locationValue.latitude],
        },
        eventDate: new Date(data.date).toISOString(),
        visibleToEveryone: data.public,
        type: "FOUND",
        questions: Object.entries(data.questions || {}).map(([id, answer]) => ({
          _question: id,
          value: answer,
        })),
      };
      await updateItem(state._id, payload);
      enqueueSnackbar(staticTexts.itemUpdatedSuccessfully);
      if (userType?.toUpperCase() === UserType.BUSINESS) {
        navigate("/business-manage-items");
      } else {
        navigate("/my-items");
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong
      );
    }
  };

  /**
   * Handles changes to the user's selected images.
   * @param {string[]} fileUrls - An array of URLs for the selected images.
   */
  const handleFileUrls = (fileUrls: string[]) => {
    setImages(fileUrls);
  };

  /**
   * Handles the submission of the form.
   * If the item is being updated, it calls handleUpdateItem.
   * If the item is being created, it calls handleCreateItem.
   * It also catches any errors that may occur and displays the error message.
   *
   * @param {Object} data - The form data containing information about the found item, including its name, images, category, description, location, date, visibility, and related questions.
   *
   * @returns {Promise<void>} - A promise that resolves when the item has been successfully created or updated, or rejects if there is an error.
   */
  const onSubmit = async (data: any) => {
    try {
      if (state) {
        await handleUpdateItem(data);
      } else {
        await handleCreateItem(data);
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong
      );
    }
  };

  return (
    <ContentWrapper title={staticTexts.whatHaveYouFound}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="itemName"
          className="!mb-6"
          label={staticTexts.itemNameWhatIFound}
          placeholder={staticTexts.egWalletLaptopKeys}
          control={control}
          error={errors.itemName}
          isRequired
        />

        <Dropdown
          name='category'
          dropdownStyle={{ marginBottom: '20px' }}
          control={control}
          placeholderText={staticTexts.selectCategory}
          options={(categories || []).map((category: any) => ({
            label:
              category?.name?.charAt(0).toUpperCase() +
              category?.name?.slice(1).toLowerCase(),
            value: category?._id,
          }))}
          labelText={staticTexts.selectCategory}
          isRequired={true}
          error={
            errors.category?.message
              ? {
                  type: 'manual',
                  message: errors.category.message,
                }
              : undefined
          }
        />

        <label className='text-sm text-secondary-400'>{staticTexts.uploadImages}</label>
        <ImageUpload
          uploadedImages={images}
          onFileChange={handleFileUrls}
        />

        <Input
          name="description"
          label={staticTexts.foundDescription}
          className="!mb-6"
          placeholder={staticTexts.describeItemInDetail}
          fullWidth
          multiline
          rows={4}
          control={control}
          error={errors.description}
          isRequired
          onBlur={(e) => {
            if (!e.target.value) {
              setValue("description", "");
            }
          }}
        />

        <Controller
          name="location"
          control={control}
          rules={{
            required: staticTexts.locationIsRequired,
          }}
          render={({ field: { onChange, ...field } }) => (
            <LocationInput
              {...field}
              control={control}
              label={staticTexts.foundLocation}
              onCurrentLocationClick={handleCurrentLocationClick}
              handleLocationSelect={(location) => {
                handleLocationValueChange(location);
                onChange(location.description);
              }}
            />
          )}
        />

        <DateInput
          name="date"
          className="w-full"
          label={staticTexts.whenYouHaveFoundIt}
          control={control}
          error={errors.date}
          helperText={errors.date?.message || " "}
          validationRules={{
            required: staticTexts.dateIsRequired,
          }}
        />

        <div className="mt-6">
          {(questions || []).map((question) => {
            console.log("before", question);
            const stateQuestion = state?.questions?.find(
              (q: any) => q._question._id === question._id
            );
            return (
              <Input
                key={question._id}
                name={`questions.${question._id}`}
                label={
                  language === "en" ? question.label.en : question.label.ar
                }
                control={control}
                sx={{ marginBottom: "24px" }}
                fullWidth
                multiline
                minRows={1}
                maxRows={4}
                defaultValue={stateQuestion?.value || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setValue(`questions.${question._id}`, "");
                  }
                }}
              />
            );
          })}
        </div>

        <Box marginTop="8px">
          <FormControl>
            <FormLabel
              id="privacy-setting"
              sx={{
                color: "#4A403A",
                fontSize: "14px",
                "&.Mui-focused": {
                  color: "#4A403A",
                },
              }}
            >
              {staticTexts.privacySetting}
            </FormLabel>
            <Controller
              name="public"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="privacy-setting"
                  className="flex gap-4 !flex-row"
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={staticTexts.private}
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={staticTexts.public}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>

        {/* Buttons Container */}
        <Box display="flex" justifyContent="space-between" marginTop="24px">
          {/* Back Button */}
          <CustomButton
            buttonStyle={{ marginLeft: -2.1 }}
            variant={AppButtonVariant.TEXT}
            title={staticTexts.back}
            onPress={() => navigate(-1)}
          />

          {/* Submit Button */}
          <CustomButton
            title={staticTexts.submit}
            type="submit"
            onPress={handleSubmit(onSubmit)}
          />
        </Box>
      </form>

      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={staticTexts.viewMyListings}
        cancelText="Close"
        customStyles={{
          width: "360px",
          bgcolor: "#FFF6E4",
          textAlign: "center",
          borderRadius: "12px",
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 40, color: "#FF6F59", mb: 2 }} />
        <Typography
          component="p"
          letterSpacing="0.01px"
          fontWeight={400}
          fontSize="22px"
          sx={{ mb: 1 }}
        >
          {staticTexts.itemPostedSuccessfully}
        </Typography>
        <Typography variant="body1" fontSize="14px" sx={{ color: "#655B53FA" }}>
          {staticTexts.yourFoundItemIsListed}
        </Typography>
      </Modal>
    </ContentWrapper>
  );
};
