import { useSelector } from 'react-redux';
import { StyledContainer, SectionWrapper, Section, SectionTitle, SectionText, SectionHeader } from '../Static.styles';
import { getStaticTexts } from '../../../assets';

export const About = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <StyledContainer maxWidth='md'>
      {/* Header Section */}
      <Section>
        <SectionHeader variant='h4'>{staticTexts.aboutUs}</SectionHeader>
      </Section>

      {/* Content Section */}
      <SectionWrapper>
        {/* About Lqeet */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.aboutLqeetText}</SectionTitle>
          <SectionText>{staticTexts.aboutLqeetDescription}</SectionText>
        </Section>

        {/* Our Mission */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.ourMissionText}</SectionTitle>
          <SectionText>{staticTexts.ourMissionDescription}</SectionText>
        </Section>

        {/* How It Works */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.howItWorks}</SectionTitle>
          <SectionText>
            1. <b>{staticTexts.reportLostItems}</b>: {staticTexts.reportLostItemDescription} <br />
            2. <b>{staticTexts.findLostItemText}</b>: {staticTexts.findLostItemDescription} <br />
            3. <b>{staticTexts.privacyAndSecurity}</b>: {staticTexts.privacyAndSecurityDescription}
          </SectionText>
        </Section>

        {/* Our Vision */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.ourVision}</SectionTitle>
          <SectionText>{staticTexts.ourVisionDescription}</SectionText>
        </Section>

        {/* Our Values */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.ourValues}</SectionTitle>
          <SectionText>
            <b>{staticTexts.security}</b>: {staticTexts.securityDescription} <br />• <b>{staticTexts.trust}</b>:{' '}
            {staticTexts.trustDescription} <br />• <b>{staticTexts.easeOfUse}</b>: {staticTexts.easeOfUseDescription}
          </SectionText>
          <SectionText>{staticTexts.ourValuesDescriptionText}</SectionText>
        </Section>
      </SectionWrapper>
    </StyledContainer>
  );
};
