import { ChangeEvent, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useSnackbar } from '../../Snackbar';
import { upload } from '../../../services/common';
import { getStaticTexts } from '../../../assets';
import { startLoader, stopLoader } from '../../../utils';
import { useSelector } from 'react-redux';

interface UploadBoxProps {
  defaultImage: string;
  onFileChange?: (fileUrls: string) => void;
  disabled?: boolean;
}

export const UploadBox: React.FC<UploadBoxProps> = ({ defaultImage, onFileChange, disabled = false }) => {
  const [image, setImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [hovered, setHovered] = useState(false);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const enqueueSnackbar = useSnackbar();

  const uploadImage = async (e: ChangeEvent<HTMLInputElement> | any) => {
    try {
      startLoader();
      const files = e.target.files;
      const maxSize = 5 * 1024 * 1024;
      const maxDimension = 1024;
  
      // Show File Selection Error
      if (!files || !files[0]) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile);
        stopLoader();
        return;
      }
  
      // Show File Size Error
      const fileSize = files[0].size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan5MB);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        stopLoader();
        return;
      }
  
      // Check image dimensions
      const img = new Image();
      const objectUrl = URL.createObjectURL(files[0]);
  
      img.onload = async () => {
        URL.revokeObjectURL(objectUrl);
  
        if (img.width > maxDimension || img.height > maxDimension) {
          // Create canvas to resize image
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > maxDimension) {
              height = Math.round((height * maxDimension) / width);
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width = Math.round((width * maxDimension) / height);
              height = maxDimension;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
  
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);
  
          // Convert canvas to blob
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                try {
                  const profileUrl = await upload(blob);
                  if (profileUrl) {
                    setImage(profileUrl);
                    setFileUrls((prev) => [...prev, profileUrl]);
                    if (onFileChange) {
                      onFileChange(profileUrl);
                    }
                  }
                } catch (err: any) {
                  enqueueSnackbar(err.response?.data?.message || err.message || staticTexts.somethingWentWrong);
                } finally {
                  stopLoader();
                }
              } else {
                enqueueSnackbar(staticTexts.somethingWentWrong);
                stopLoader();
              }
            },
            'image/jpeg',
            0.9
          );
        } else {
          try {
            const profileUrl = await upload(files[0]);
            if (profileUrl) {
              setImage(profileUrl);
              setFileUrls((prev) => [...prev, profileUrl]);
              if (onFileChange) {
                onFileChange(profileUrl);
              }
            }
          } catch (err: any) {
            enqueueSnackbar(err.response?.data?.message || err.message || staticTexts.somethingWentWrong);
          } finally {
            stopLoader();
          }
        }
      };
  
      img.src = objectUrl;
    } catch (error: any) {
      stopLoader();
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } 
  };
  

  const handleDeleteImage = () => {
    setImage(null);
  };

  return (
    <div
      className='relative w-20 h-20 rounded-full overflow-hidden cursor-pointer border border-[#C9C2B4]'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <label
        htmlFor='imageUpload'
        className='w-full h-full flex items-center justify-center'
      >
        {/* Show default image or uploaded image */}
        <img
          src={image || defaultImage}
          alt='profile-img'
          className='w-full h-full object-cover'
        />

        {/* Overlay for hover icon */}
        {hovered && (
          <div className='absolute inset-0 bg-black bg-opacity-1 flex items-center justify-center'>
            {image ? (
              <DeleteIcon
                className='text-white'
                onClick={handleDeleteImage}
              />
            ) : (
              <AddPhotoAlternateIcon className='text-white' />
            )}
          </div>
        )}
      </label>

      {/* Hidden input for file upload */}
      <input
        id='imageUpload'
        ref={fileInputRef}
        type='file'
        accept='.jpg, .jpeg, .png, .heif'
        onChange={(e) => uploadImage(e)}
        className='hidden'
        disabled={disabled}
      />
    </div>
  );
};
