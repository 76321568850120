import styled from '@emotion/styled';
import { Container, Typography, Box } from '@mui/material';

export const StyledContainer = styled(Container)`
  min-height: 100vh;
  padding: 4rem 0;
  color: #655b53fa;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const SectionWrapper = styled(Box)`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled(Typography)`
  font-size: 1.75rem;
  font-weight: 600;
  color: #4a403a;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const SectionTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a403a;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const SectionText = styled(Typography)`
  font-size: 1rem;
  line-height: 1.75;
  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const FaqWrapper = styled(Box)(() => ({}));

// Styled Question Component
export const LeadText = styled(Typography)(() => ({
  fontWeight: 'bold',
  margin: '10px 0',
  '&:before': {
    content: '"• "',
  },
}));
export const IndentedText = styled(Typography)(() => ({
  marginLeft: '20px',
  fontWeight: 'normal',
  '&:before': {
    content: '"◦ "',
    fontWeight: 'bold',
  },
}));
