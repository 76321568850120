import { Box, Button, Grid2, Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { ControlToolbar, useSnackbar, Modal } from '../../components';
import { useSelector } from 'react-redux';
import { getStaticTexts, iPhoneImg, organizationLgIcon } from '../../assets';
import ProductCard from '../../components/Layouts/ProductCard';
import { Search } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LaunchIcon from '@mui/icons-material/Launch';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';
import { deleteItem, getItems } from '../../services/aggregation';
import { textTransform } from '../../services/common';
import { startLoader, stopLoader } from '../../utils';
import NoItems from '../../components/Layouts/NoItems';
import { LocalStorageKeys } from '../../constants/AppConstants';
import ProductList from '../../components/Layouts/ProductList';
import { Dialog } from '../../components/Dialog';
import DateTimeFormatting from '../../utils/extensions/DateTimeFormatting';
import { Product } from '../../interfaces';
import FilterDrawer from '../../components/Layouts/FilterDrawer';
import { ExpandedSectionsType } from '../homepage/search-items/mobile-view';
import CustomPagination from '../../components/Pagination';

/**
 * Organization page for the user to view all the items they have lost
 *
 * @returns The JSX element for the organization page
 */
const OrganizationPage = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [viewType, setViewType] = useState('grid');
  const [anchorEl, setAnchorEl] = useState(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [searchText, setSearchText] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [state, setState] = useState({
    location: '',
    category: staticTexts.category,
    categoryId: '',
    isDialogOpen: false,
  });
  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 20,
  });

  const [filters, setFilters] = useState({
    viewMode: viewType,
    _category: 'all',
  });

  const [expandedSections, setExpandedSections] = useState<Partial<ExpandedSectionsType>>({
    viewMode: false,
    category: false,
  });

  const navigate = useNavigate();
  const user = useSelector((state: any) => state.profile);
  const userType =
    localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE) || localStorage.getItem(LocalStorageKeys.USER_TYPE);
  const categories = useSelector((state: any) => [{ _id: 'all', name: staticTexts.all }, ...state.category]);
  const enqueueSnackbar = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => setOpenDialog(false);
  const [page, setPage] = useState(1);

  /**
   * Close Modal
   */
  const handleClose = () => {
    setIsOpen(false);
    setProduct(null);
  };

  /**
   * API Call: Delete Item
   */
  const handleConfirm = async () => {
    try {
      // Show Loading
      startLoader();

      // API Call: Delete Item
      await deleteItem([product?._id]);

      // Show Message
      enqueueSnackbar(staticTexts.itemDeletedSuccessfully);

      // Fetch Updated Items
      fetchItems();
    } catch (error: any) {
      // Show Message
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Modal
      setIsOpen(false);

      // Dismiss Loading
      stopLoader();
    }
  };

  /**
   * Fetches the items of the organization from the server.
   *
   * This function initiates a loading state, calls the getItems service with the
   * specified parameters, and updates the products state with the response.
   * If an error occurs during the fetch operation, an error message is displayed.
   * Finally, the loading state is stopped.
   */
  const fetchItems = async () => {
    startLoader();
    try {
      const params: any = {
        page: page,
        limit: 8,
        type: 'FOUND',
        status: 'ACTIVE',
      };

      if (state.categoryId) {
        params.categoryId = state.categoryId === 'all' ? '' : state.categoryId;
      }

      if (searchText) {
        params.text = searchText;
      }

      const response = (await getItems(params)) as any;
      // setProducts(response?.items);
      const copiedItems = Array(20).fill(response?.items).flat();

      // Set the products by spreading the copied items and original response
      setProducts([...copiedItems, ...response?.items]);
      setMeta({
        totalPages: response?.meta?.totalPages || 0,
        page: response?.meta?.page || 1,
        limit: response?.meta?.limit || 20,
      });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    fetchItems();
  };
  /**
   * Handles the view type change event for the view type toggle buttons.
   * @param {string} value - The new view type to be set.
   */
  const handleViewTypeChange = (value: string) => {
    setViewType(value);
  };

  /**
   * Handles the menu open event for the view type menu button.
   * @param {any} event - The event object for the menu open event.
   */
  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the menu close event for the view type menu button.
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (state: boolean) => {
    setOpenFilter(state);
    setExpandedSections({
      viewMode: false,
      dateRange: false,
    });
  };

  /**
   * Handles the category selection event for the category dropdown.
   * @param {any} category - The selected category object.
   */
  const handleOptionSelect = (category: any) => {
    setState((prev) => ({
      ...prev,
      category: textTransform(category.name),
      categoryId: category._id,
    }));
    handleMenuClose();
  };

  useEffect(() => {
    fetchItems();
  }, [state.categoryId]);

  /**
   * Handles the search input key down event.
   *
   * If the pressed key is the Enter key, it triggers the fetchItems function
   * to retrieve items based on the current search criteria.
   *
   * @param {any} event - The keyboard event.
   */
  const handleSearch = (event: any) => {
    if (event.key === 'Enter') {
      fetchItems();
    }
  };

  const handleViewModeChange = (event: any) => {
    setFilters({
      ...filters,
      viewMode: event.target.value,
    });
  };

  const handleClearAll = () => {
    setFilters({
      viewMode: 'grid',
      _category: 'all',
    });

    setState((prev) => ({
      ...prev,
      categoryId: '',
    }));

    toggleDrawer(false);
  };

  const handleFilter = () => {
    fetchItems();
    setViewType(filters.viewMode);
    toggleDrawer(false);
  };

  const handleCategoryChange = (event: any) => {
    setFilters({
      ...filters,
      _category: event.target.value,
    });

    setState((prev) => ({
      ...prev,
      categoryId: event.target.value,
    }));
  };

  return (
    <Fragment>
      <Box
        width='100%'
        bgcolor='#FFE0B5 !important'
        minHeight='200px'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <img
          src={user?.coverProfile || organizationLgIcon}
          alt='organization-icon'
          style={{ width: '100%', height: '350px', objectFit: 'cover' }}
        />
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className='mb-10'
      >
        <Box
          className='lg:flex'
          gap='12px'
          width={{
            xs: '95%',
            md: '80%',
          }}
          marginTop='36px'
        >
          <Box
            className='hidden lg:block min-w-72'
            width='30%'
          >
            <Box
              className='flex justify-center items-center flex-col rounded-lg p-8'
              bgcolor='#FFF7E6'
            >
              <img
                className='rounded-full'
                src={user?.profile || organizationLgIcon}
                alt='Item 1'
                style={{ width: '60px', height: '60px', objectFit: 'cover' }}
              />
              {/* Business Name */}
              <Typography
                color='#4A403A'
                fontSize='18px'
                textAlign='center'
                marginTop='24px'
              >
                {user?.name} <VerifiedIcon sx={{ color: '#FF6F61', fontSize: '18px' }} />
              </Typography>

              {/* Business Address */}
              <Typography
                fontSize='14px'
                color='#655B53FA'
                textAlign='center'
              >
                {user?.address}
              </Typography>

              {/* Business Description */}
              <Typography
                fontSize='12px'
                color='#655B53FA'
                textAlign='center'
                marginTop='16px'
              >
                {user?.description}
              </Typography>
            </Box>

            <Box
              className='rounded-lg'
              marginTop='12px'
              padding='32px'
              bgcolor='#FFF7E6'
            >
              <Typography
                color='#4A403A'
                textAlign='left'
                marginBottom='12px'
                fontSize='16px'
              >
                {staticTexts.wereYouAbleToFindWhatYouLost}
              </Typography>

              <Typography
                color='#655B53FA'
                fontSize='14px'
                textAlign='left'
                marginBottom='40px'
              >
                {staticTexts.wereYouNotAbleToFindWhatYouLost}{' '}
              </Typography>

              <Button
                variant='outlined'
                sx={{
                  border: '1px solid #C9C2B4',
                  fontWeight: '400',
                  color: '#4A403A',
                }}
                onClick={() =>
                  navigate('/business-manage-items', {
                    state: { showOverview: true },
                  })
                }
              >
                {staticTexts.searchAllFoundItem}
              </Button>
            </Box>
          </Box>

          <Box
            className='rounded-lg'
            overflow='scroll'
            style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}
          >
            <div className='flex items-center rounded-lg flex-wrap bg-[#FFF7E6] mb-2 w-full px-2 py-1'>
              <input
                type='text'
                placeholder={staticTexts.searchFoundItems}
                value={searchText}
                onChange={(event: any) => setSearchText(event.target.value)}
                className='self-start w-3/4 px-4 py-2 lg:rounded-l-lg rounded focus:outline-none bg-transparent custom-placeholder'
                onKeyUp={handleSearch}
              />

              <div className='relative ms-auto px-1 lg:flex rounded-r-lg items-center'>
                <button
                  className='lg:flex hidden items-center px-4 py-2 focus:outline-none'
                  onClick={handleMenuOpen}
                >
                  {textTransform(state.category)}
                  <span className='ml-2'>
                    <KeyboardArrowDownIcon />
                  </span>
                </button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  {categories.map((category: any) => (
                    <MenuItem
                      key={category._id}
                      onClick={() => handleOptionSelect(category)}
                    >
                      {textTransform(category.name)}
                    </MenuItem>
                  ))}
                </Menu>
                <button
                  className='bg-[#EC2A3B] text-white px-4 py-2 rounded-full h-8 w-8 flex justify-center items-center'
                  onClick={() => fetchItems()}
                >
                  <Search />
                </button>
              </div>
            </div>
            <Box
              className='rounded-lg'
              padding={{ xs: '0px 16px 16px 16px', md: '0px 24px 24px 24px' }}
              bgcolor='#FFF7E6'
              style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}
            >
              <ControlToolbar
                selectedTab={staticTexts.foundItems}
                showDateFilter={false}
                viewType={viewType}
                onViewChange={handleViewTypeChange}
                itemCount={products?.length}
                showButton={false}
                handleFilters={() => setOpenFilter(true)}
                onSearch={handleSearch}
              />
              {products?.length > 0 ? (
                viewType.toLowerCase() === 'grid' ? (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: {
                        xs: '1fr 1fr',
                        sm: '1fr 1fr 1fr',
                        lg: '1fr 1fr 1fr',
                      },
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    {(products || []).map((product, index) => (
                      <ProductCard
                        userType={userType?.toUpperCase() as string}
                        key={index}
                        image={product?.images[0]}
                        images={product?.images}
                        itemCategory={product?._category?.name}
                        itemName={product?.name}
                        description={product?.description}
                        location={product?.location?.name}
                        date={product?.createdAt}
                        type={product?.visibleToEveryone ? staticTexts.public : staticTexts.private}
                        showPrivacy={false}
                        showClaimButton={true}
                        onEdit={() => navigate('/report-found-item', { state: product })}
                        onDelete={() => {
                          setIsOpen(true);
                          setProduct(product);
                        }}
                        showEditButton={true}
                        menuOptions={[
                          {
                            label: staticTexts.view,
                            onClick: () => {
                              setOpenDialog(true);
                              setProduct(product);
                            },
                          },
                        ]}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap='8px'
                    marginTop='18px'
                    width='100%'
                  >
                    <div className='grid grid-cols-1 gap-2'>
                      {(products || []).map((product, index) => (
                        <ProductList
                          key={index}
                          title={product?.name}
                          description={product?.description}
                          location={product?.location?.name}
                          visibility={product?.visibleToEveryone}
                          date={product?.eventDate}
                          imageUrl={product?.images[0]}
                          menuOptions={[
                            {
                              label: staticTexts.view,
                              onClick: () => {
                                setOpenDialog(true);
                                setProduct(product);
                              },
                            },
                          ]}
                          showEdit={true}
                          onDelete={() => {
                            setIsOpen(true);
                            setProduct(product);
                          }}
                          onEdit={() => navigate('/report-found-item', { state: product })}
                        />
                      ))}
                    </div>
                  </Box>
                )
              ) : (
                <NoItems />
              )}
            </Box>
            {meta.totalPages > 8 && (
              <CustomPagination
                count={10}
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Box>

          <Box
            className='block lg:hidden max-w-full'
            padding='4px'
          >
            <Box
              className='flex justify-center items-center flex-col min-h-80 rounded-lg p-8'
              bgcolor='#FFF7E6'
              marginTop={{
                xs: '8px',
                md: '0px',
              }}
            >
              <img
                className='rounded-full'
                src={user?.profile || organizationLgIcon}
                alt='Item 1'
                style={{ width: '60px', height: '60px' }}
              />

              <Typography
                color='#4A403A'
                fontSize='18px'
                textAlign='center'
                marginTop='24px'
              >
                {user?.name || 'N/A'} <VerifiedIcon sx={{ color: '#FF6F61', fontSize: '18px' }} />
              </Typography>

              <Typography
                fontSize='14px'
                color='#655B53FA'
                textAlign='center'
              >
                {user?.address || 'N/A'} <LaunchIcon sx={{ color: '#FF6F61', fontSize: '18px' }} />
              </Typography>

              <Button
                variant='contained'
                sx={{
                  background: '#FF6F61',
                  color: '#FFF7E6',
                  marginTop: '40px',
                  fontWeight: '400',
                }}
              >
                Chat with organizations
              </Button>
            </Box>

            <Box
              className='rounded-lg'
              marginTop='12px'
              padding='32px'
              bgcolor='#FFF7E6'
            >
              <Typography
                color='#4A403A'
                textAlign='left'
                marginBottom='12px'
                fontSize='16px'
              >
                {staticTexts.wereYouAbleToFindWhatYouLost}
              </Typography>

              <Typography
                color='#655B53FA'
                fontSize='14px'
                textAlign='left'
                marginBottom='40px'
              >
                {staticTexts.wereYouNotAbleToFindWhatYouLost}
              </Typography>

              <Button
                variant='outlined'
                sx={{
                  border: '1px solid #C9C2B4',
                  fontWeight: '400',
                  color: '#4A403A',
                }}
              >
                {staticTexts.searchAllFoundItem}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <FilterDrawer
        filters={filters}
        expandedSections={expandedSections}
        showCategoryFilter={true}
        setExpandedSections={setExpandedSections}
        handleCategoryChange={handleCategoryChange}
        onApply={handleFilter}
        categories={categories}
        isOpen={openFilter}
        toggleDrawer={toggleDrawer}
        handleClearAll={handleClearAll}
        handleClose={handleClose}
        showDateFilter={false}
        showSortFilter={false}
        handleViewModeChange={handleViewModeChange}
      />

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        title={`${staticTexts.foundItems} ${staticTexts.details}`}
        maxWidth='lg'
        titlePadding='16px 24px'
        contentPadding='0px 24px'
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Typography
              variant='h6'
              fontSize='16px'
              color='#4A403A'
              gutterBottom
            >
              {staticTexts.itemDetails}
            </Typography>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.name}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ textTransform: 'capitalize' }}
                >
                  {product?.name}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.description}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {product?.description}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.location}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {product?.location?.name}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.dateLost}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                >
                  {DateTimeFormatting.getDateByFormatCustomUTC(
                    product?.createdAt ?? '',
                    true,
                    DateTimeFormatting.DateTimeFormat.DD_MMM_yyyy
                  )}
                </Typography>
              </Box>
            </Grid2>

            {/* Uploaded Images Section */}
            <Box mt={4}>
              <Typography
                variant='h6'
                fontSize='16px'
                color='#4A403A'
                gutterBottom
              >
                {staticTexts.uploadImages}
              </Typography>
              <Box
                display='flex'
                gap='8px'
                paddingBottom='24px'
              >
                {(product?.images || []).map((image: string, index: number) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Item ${index + 1}`}
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '8px',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        }
      />

      {/* Delete Item Confirm Modal */}
      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={() => handleConfirm()}
        confirmText={staticTexts.delete}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
        cancelButtonStyle={{ color: '#4A403A', border: '1px solid #C9C2B4' }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.deleteItem}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.deleteItemWarning}
        </Typography>
      </Modal>
    </Fragment>
  );
};

export default OrganizationPage;
