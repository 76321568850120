import React, { useState } from 'react';
import { Container, Typography, Box, Grid2 as MuiGrid, SelectChangeEvent } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Button, Input, PhoneNumberInput, useSnackbar } from '../../../components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import Checkbox from '../../../components/Checkbox';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { submitEnquiry } from '../../../services';

interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  prefix: string;
  number: string;
  description?: string;
}

export const ContactSupport = () => {
  const [dialCode, setDialCode] = useState('+966');
  const [isChecked, setIsChecked] = useState(false);
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const enqueueSnackbar = useSnackbar();
  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      prefix: '966',
      number: '',
      description: '',
    },
  });
  const isButtonDisabled =
  !isChecked || 
  !watch('firstName') ||
  !watch('lastName') ||
  !watch('email') ||
  !watch('prefix') ||
  !watch('number');


  const onSubmit = (data: FormFields) => {
    submitEnquiry(data)
      .then(() => enqueueSnackbar(staticTexts.querySubmitted))
      .catch((error) =>
        enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong)
      );
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };
  const handleCountryCodeChange = (dialCode: SelectChangeEvent<string>) => {
    setDialCode(String(dialCode));
    setValue('prefix', String(dialCode).replace('+', ''));
  };

  return (
    <Container
      maxWidth='lg'
      className='py-16'
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: '48px',
      }}
    >
      {/* Right Content Section */}
      <Box
        className='p-4'
        sx={{
          width: { xs: '100%', md: '40%' },
        }}
      >
        <Typography
          variant='h4'
          sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
          className='font-semibold text-secondary-400'
        >
          {staticTexts.contactOur}
        </Typography>
        <Typography
          variant='h4'
          sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
          className='font-semibold text-secondary-400'
        >
          {staticTexts.supportTeam}
        </Typography>

        <Box className='mt-8'>
          <Typography
            className='text-lg text-left !mb-4 leading-relaxed'
            sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}
          >
            {staticTexts.contactSupportDescription}
          </Typography>

          <Typography
            className='text-lg leading-relaxed !mt-4'
            sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}
          >
            <strong>{staticTexts.contactOptions}:</strong>
            <ul className='list-disc list-inside mt-2 space-y-2'>
              <li>
                <EmailIcon fontSize='small' />{' '}
                <a
                  href='mailto:support@lqeet.com'
                  className='text-accent'
                >
                  support@lqeet.com
                </a>
              </li>
              <li>
                <LocalPhoneIcon fontSize='small' /> {staticTexts.phoneSupport}
                <strong> +1 (123) 456-7890</strong>
              </li>
            </ul>

            <Box
              display='flex'
              gap={2}
              mt={2}
            >
              <a
                href='https://facebook.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FacebookIcon
                  fontSize='medium'
                  sx={{ color: '#0866ff' }}
                />
              </a>
              <a
                href='https://twitter.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <XIcon fontSize='medium' />
              </a>
              <a
                href='https://instagram.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png'
                  alt='Instagram'
                  width='24'
                  height='24'
                />
              </a>
              <a
                href='https://linkedin.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <LinkedInIcon
                  fontSize='medium'
                  sx={{ color: '#0077b5' }}
                />
              </a>
            </Box>
          </Typography>
        </Box>
      </Box>

      {/* Left Content Section */}
      <Box
        className='p-4'
        sx={{
          width: { xs: '100%', md: '60%' },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MuiGrid
            container
            spacing={2}
          >
            <MuiGrid size={{ xs: 12, md: 6 }}>
              <Input
                name='firstName'
                label={staticTexts.firstName}
                placeholder={staticTexts.enterYourFirstName}
                control={control}
                error={errors.firstName}
                validationRules={{
                  required: `${staticTexts.firstName} ${staticTexts.isRequired}`,
                  maxLength: 20,
                  message: `${staticTexts.firstName} ${staticTexts.nameShouldBeLessThan}`,
                }}
              />
            </MuiGrid>

            <MuiGrid size={{ xs: 12, md: 6 }}>
              <Input
                name='lastName'
                label={staticTexts.lastName}
                placeholder={staticTexts.enterYourLastName}
                control={control}
                error={errors.lastName}
                validationRules={{
                  required: `${staticTexts.lastName} ${staticTexts.isRequired}`,
                  maxLength: 20,
                  message: `${staticTexts.lastName} ${staticTexts.nameShouldBeLessThan}`,
                }}
              />
            </MuiGrid>

            <MuiGrid size={{ xs: 12, md: 6 }}>
              <Input
                name='email'
                label={staticTexts.email}
                placeholder={staticTexts.enterYourEmail}
                control={control}
                error={errors.email}
                validationRules={{
                  required: `${staticTexts.email} ${staticTexts.isRequired}`,
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: staticTexts.invalidEmail,
                  },
                }}
              />
            </MuiGrid>

            <MuiGrid size={{ xs: 12, md: 6 }}>
              <PhoneNumberInput
                name='number'
                label={staticTexts.phoneNumber}
                countryCode={dialCode}
                control={control}
                error={errors.number}
                handleCountryCodeChange={handleCountryCodeChange}
                validationRules={{
                  required: `${staticTexts.phoneNumber} ${staticTexts.isRequired}`,
                }}
              />
            </MuiGrid>

            <MuiGrid size={{ xs: 12 }}>
              <Input
                name='description'
                className='!mb-2'
                multiline
                fullWidth
                rows={4}
                label={staticTexts.description}
                placeholder={staticTexts.enterYourDescriptionHere}
                control={control}
                error={errors.description}
                validationRules={{ maxLength: 20, message: `${staticTexts.description} ${staticTexts.isRequired}` }}
              />
            </MuiGrid>
          </MuiGrid>

          <Box
            display='flex'
            gap='12px'
            marginBottom='16px'
            alignItems='center'
          >
            <Checkbox
              checked={isChecked}
              onChange={handleCheck}
            />
            <Typography
              component='p'
              fontSize='12px'
              color='#776E65'
            >
              {staticTexts.iAgreeToLqeetCommunication}
            </Typography>
          </Box>
          <Button
            disabled={isButtonDisabled}
            type='submit'
          >
            {staticTexts.submit}
          </Button>
        </form>
      </Box>
    </Container>
  );
};
