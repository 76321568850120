import { Box, IconButton, Menu, MenuItem, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import React, { useReducer } from 'react';
import ViewListIcon from '@mui/icons-material/ViewListRounded';
import GridViewIcon from '@mui/icons-material/GridViewRounded';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, DateInput, Modal } from '../../../components';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { getStaticTexts } from '../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { formatDate } from '../../../utils';
import { Search } from '@mui/icons-material';
import { LocalStorageKeys, UserType } from '../../../constants/AppConstants';
import { RESET_DATE_FILTER } from '../../../redux';

interface ControlToolbarProps {
  viewType: string;
  selectedTab?: string;
  itemCount: number;
  showButton?: boolean;
  showDateFilter?: boolean;
  onButtonClick?: () => void;
  buttonText?: string;
  onViewChange: (viewType: string) => void;
  handleFilters?: () => void;
  showSearchInput?: boolean;
  padding?: string;
  handleDateFilter?: (filters: any) => void;
  handleSorting?: (filters: any) => void;
  searchValue?: string;
  onSearch: (event: any) => void;
}

export const ControlToolbar: React.FC<ControlToolbarProps> = ({
  viewType,
  itemCount,
  selectedTab,
  buttonText,
  showButton = true,
  showSearchInput = false,
  showDateFilter = true,
  onButtonClick,
  onViewChange,
  handleFilters,
  handleDateFilter,
  handleSorting,
  padding = '24px 8px 12px 8px',
  searchValue,
  onSearch,
}) => {
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(null);
  const [anchorElDate, setAnchorElDate] = React.useState<null | HTMLElement>(null);
  const [anchorElSort, setAnchorElSort] = React.useState<null | HTMLElement>(null);
  const [openDateRangeModal, setOpendDateRangeModal] = React.useState(false);
  const [selectedSort, setSelectedSort] = React.useState('');
  const appDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<{
    from: string;
    to: string;
  }>();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const onSubmit = () => {};

  const initialState = {
    dateSortOptions: [
      {label: staticTexts.all, value: 'all'},
      {
        label: staticTexts.today,
        value: {
          from: moment().startOf('day').toISOString(),
          to: moment().endOf('day').toISOString(),
        },
      },
      {
        label: staticTexts.yesterday,
        value: {
          from: moment().subtract(1, 'day').startOf('day').toISOString(),
          to: moment().subtract(1, 'day').endOf('day').toISOString(),
        },
      },
      {
        label: staticTexts.lastWeek,
        value: {
          from: moment().subtract(1, 'week').startOf('week').toISOString(),
          to: moment().subtract(1, 'week').endOf('week').toISOString(),
        },
      },
      {
        label: staticTexts.lastMonth,
        value: {
          from: moment().subtract(1, 'month').startOf('month').toISOString(),
          to: moment().subtract(1, 'month').endOf('month').toISOString(),
        },
      },
      {
        label: staticTexts.lastYear,
        value: {
          from: moment().subtract(1, 'year').startOf('year').toISOString(),
          to: moment().subtract(1, 'year').endOf('year').toISOString(),
        },
      },
      { label: staticTexts.customDate, value: 'custom' },
    ],
    sortOptions: [
      { label: staticTexts.newestToOldest, value: 'NEWEST' },
      { label: staticTexts.oldestToNewest, value: 'OLDEST' },
      { label: staticTexts.alphabeticalAtoZ, value: 'A-Z' },
      { label: staticTexts.alphabeticalZtoA, value: 'Z-A' },
    ],
    selectedDateFilter: staticTexts.all,
    selectedSort: 'NEWEST',
  };

  const [state, dispatch] = useReducer((state: typeof initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
      case 'UPDATE_STATE':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }, initialState);

  const profileType = useSelector((state: any) => state.profile?.type);
  const userType = localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE) || profileType;

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>) => {
    setAnchor(null);
  };

  const handleDateFilterChange = (option: { label: string; value: string }) => {
    if (option.value === 'custom') {
      setOpendDateRangeModal(true);
      handleMenuClose(setAnchorElDate)
      return;
    }

    if (option.value === 'all') {
      appDispatch({
        type: RESET_DATE_FILTER
      });
      dispatch({
        type: 'UPDATE_STATE',
        payload: { selectedDateFilter: option.label },
      });
      handleMenuClose(setAnchorElDate)
      return;
    }
   
    handleDateFilter?.({ date: option.value });
    dispatch({
      type: 'UPDATE_STATE',
      payload: { selectedDateFilter: option.label },
    });
    handleMenuClose(setAnchorElDate);
  };

  const handleSortChange = (sortValue: any) => {
    dispatch({ type: 'UPDATE_STATE', payload: { selectedSort: sortValue } });
    handleSorting?.({ sort: sortValue });
    handleMenuClose(setAnchorElSort);
  };

  const handleSelect = (value: string) => {
    setSelectedSort(value);
  }

  const SearchInput = () => {
    return (
      <div className='relative self-start md:w-[250px] lg:[304px] sm:w-1/2 rounded-lg border border-[#C9C2B4]'>
        <input
          type='text'
          placeholder={staticTexts.search}
          defaultValue={searchValue}
          className='w-full ps-10 pr-4 py-2 rounded-lg border-none outline-none bg-[#FFF7E6] custom-placeholder'
          onKeyUp={(e: any) => onSearch(e)}
        />
        <span className='absolute left-3 top-1/2 transform -translate-y-1/2 text-[#776E65]'>
          <Search />
        </span>
      </div>
    );
  };

  return (
    <Box
      className='flex items-center justify-between pt-6 pb-2 min-w-16'
      padding={padding || '8px'}
    >
      {/* Title and Count */}
      {!showSearchInput && (
        <Box className='flex items-center space-x-4'>
          <Typography
            variant='h6'
            className='text-2xl font-semibold'
          >
            {selectedTab}
          </Typography>
        </Box>
      )}
      {showSearchInput && <SearchInput />}
      {/* View Options and Report Button */}
      <Box className='hidden md:flex items-center space-x-2'>
        <span className='text-secondary-200 text-sm'>
          {itemCount}{' '}
          {userType?.toUpperCase() === UserType.BUSINESS ? staticTexts.foundItems.toLowerCase() : staticTexts.lostItems.toLowerCase()}
        </span>

        {/* View Dropdown */}
        <IconButton
          className='border border-blue-500'
          size='small'
          disableRipple
          onClick={(event) => handleMenuClick(event, setAnchorElView)}
        >
          {state.view === staticTexts.grid ? <GridViewIcon /> : <ViewListIcon />}
          <span className='ms-1 text-sm'>{viewType === staticTexts.grid ? staticTexts.grid : staticTexts.list}</span>
          <ArrowDownRoundedIcon fontSize='small' />
        </IconButton>
        <Menu
          anchorEl={anchorElView}
          open={Boolean(anchorElView)}
          onClose={() => handleMenuClose(setAnchorElView)}
        >
          <MenuItem
            onClick={() => {
              onViewChange(staticTexts.grid);
              handleMenuClose(setAnchorElView);
            }}
          >
            {staticTexts.grid}
          </MenuItem>
          <MenuItem
            onClick={() => {
              onViewChange(staticTexts.list);
              handleMenuClose(setAnchorElView);
            }}
          >
            {staticTexts.list}
          </MenuItem>
        </Menu>

        {/* Date Filter Dropdown */}
        {userType !== UserType.BUSINESS && showDateFilter && (
          <>
            <IconButton
              className='border border-blue-500'
              size='small'
              disableRipple
              onClick={(event) => handleMenuClick(event, setAnchorElDate)}
            >
              <CalendarMonthIcon />
              <span className='ms-1 text-sm'>{state.selectedDateFilter}</span>
              <ArrowDownRoundedIcon fontSize='small' />
            </IconButton>
            <Menu
              anchorEl={anchorElDate}
              open={Boolean(anchorElDate)}
              onClose={() => handleMenuClose(setAnchorElDate)}
            >
              {state.dateSortOptions.map((option: { value: any; label: any }) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleDateFilterChange(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        {/* Sort Dropdown */}
        {userType !== UserType.BUSINESS && showDateFilter && (
          <IconButton
            className='border border-blue-500 !text-sm'
            size='small'
            onClick={(event) => handleMenuClick(event, setAnchorElSort)}
            disableRipple
          >
            {staticTexts.sort}
            <ArrowDownRoundedIcon fontSize='small' />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorElSort}
          open={Boolean(anchorElSort)}
          onClose={() => handleMenuClose(setAnchorElSort)}
        >
          <RadioGroup
            sx={{
              '& .MuiButtonBase-root-MuiRadio-root': {
                paddingInlineStart: 0,
              },
            }}
             value={selectedSort}
             onChange={(e) => handleSelect(e.target.value)}
          >
            {state.sortOptions.map(
              (option: {
                value: string;
                label:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | null
                  | undefined;
              }) => (
                <MenuItem
                  key={option.value}
                  disableRipple
                  onClick={() => handleSortChange(option)}
                >
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    style={{ margin: 0, textAlign: 'left' }}
                  />
                </MenuItem>
              )
            )}
          </RadioGroup>
        </Menu>

        {/* Report Lost Item Button */}
        {showButton && (
          <Button
            variant='contained'
            size='small'
            buttonStyle={{
              background: '#FF6F61',
              display: 'inline-flex',
              fontWeight: 'normal',
            }}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
      <IconButton
        className='block md:!hidden'
        size='small'
        disableRipple
        onClick={handleFilters}
      >
        <FilterAltIcon />
      </IconButton>

      <Modal
        open={openDateRangeModal}
        onClose={() => setOpendDateRangeModal(false)}
        onConfirm={() => {
          if (getValues('from') && getValues('to')) {
            const from = formatDate(String(new Date(getValues('from'))));
            const to = formatDate(String(new Date(getValues('to'))));

            dispatch({
              type: 'UPDATE_STATE',
              payload: { selectedDateFilter: from + ' → ' + to },
            });

            handleDateFilter?.({
              date: {
              from: getValues('from'),
              to: getValues('to'),
            }
            });
          }
          setOpendDateRangeModal(false);
        }}
        confirmText='Apply Filter'
        cancelText='Cancel'
        customStyles={{
          width: '412px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
          padding: '24px',
        }}
        buttonContainerStyle={{ justifyContent: 'space-between' }}
        cancelButtonStyle={{
          color: '#FF6F61',
          paddingInlineStart: '0px',
          justifySelf: 'self-start',
        }}
      >
        <Box position='relative'>
          <IconButton
            aria-label='close'
            onClick={() => setOpendDateRangeModal(false)}
            edge='end'
            style={{
              marginLeft: 'auto',
              position: 'absolute',
              right: '-2px',
              top: '-16px',
              backgroundColor: '#FFE0B5',
              zIndex: 1,
              height: '28px',
              width: '28px',
            }}
          >
            <CloseIcon sx={{ fontSize: '18px' }} />
          </IconButton>
          <Typography
            variant='body2'
            component='p'
            letterSpacing='0.01px'
            fontWeight={400}
            fontSize='22px'
            textAlign='left'
          >
            {staticTexts.filterDateByRange}
          </Typography>
          <Typography
            variant='body1'
            fontSize='14px'
            sx={{ color: '#655B53FA' }}
          >
            {staticTexts.filterYourDataByChoosingCustomDateRange}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                mt: '24px',
              }}
            >
              <DateInput
                name='from'
                className='w-1/2'
                label='From'
                control={control}
                error={errors.from}
                helperText={errors.to?.message}
                validationRules={{
                  required: 'From date is required',
                }}
              />
              <DateInput
                name='to'
                className='w-1/2'
                label='To'
                control={control}
                error={errors.to}
                helperText={errors.to?.message}
                validationRules={{
                  required: 'To date is required',
                }}
              />
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};
