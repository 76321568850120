import React, { Fragment, useReducer, useCallback, Suspense, useMemo, useState, useEffect } from 'react';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Button,
  Popover,
  Grid2,
} from '@mui/material';
import { arabicLogo, defaultProfileImg, getStaticTexts, iPhoneImg, logo, ProfileIcon } from '../../../assets';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from '../../Dialog';
import { BadgeAvatars } from '../../BadgedAvatar';
import { useDispatch, useSelector } from 'react-redux';
import {
  RESET_PROFILE_DETAILS,
  SET_LANGUAGE,
  SET_NOTIFICATION_COUNT,
  UPDATE_CATEGORY_LANGUAGE,
  UPDATE_LOGIN_STATUS,
  UPDATE_PROFILE_DETAILS,
} from '../../../redux';
import { Modal } from '../../Modal';
import { deleteNotifications, getNotifications } from '../../../services/notification';
import { formatDate, startLoader, stopLoader } from '../../../utils';
import { handleCharacterLimit, logout, updateProfile } from '../../../services/common';
import { useSnackbar } from '../../Snackbar';
import { CustomButton } from '../../Button/CustomButton';
import { AppButtonVariant, Language, LocalStorageKeys, UserType } from '../../../constants/AppConstants';
import { arabicFlag, englishFlag } from '../../../assets/icons';
import { emit } from '../../../services';

/**
 * Author: Mayank
 * Date: 19 Nov 2024
 * Description: Header component with logo, navigation links, notification, and profile section.
 * @returns {JSX.Element} Header component
 */

export const Header = () => {
  const language = useSelector((state: any) => state.language);
  const [openLogoutModal, setLogoutModal] = useState(false);
  // Initial state for useReducer
  const initialState = {
    anchorEl: null,
    businessAnchorEl: null,
    profileAnchorEl: null,
    isOpen: false,
    selectedLanguage: language === 'en' ? 'English' : 'عربي',
  };

  const user = useSelector((state: any) => state.profile);
  // Reducer function for state management
  function headerReducer(state = initialState, action: { type: string; payload: any }) {
    switch (action.type) {
      case 'SET_ANCHOR_EL':
        return { ...state, anchorEl: action.payload };
      case 'SET_PROFILE_ANCHOR_EL':
        return { ...state, profileAnchorEl: action.payload };
      case 'SET_BUSINESS_ANCHOR_EL':
        return { ...state, businessAnchorEl: action.payload };
      case 'CLOSE_MENUS':
        return {
          ...state,
          anchorEl: null,
          profileAnchorEl: null,
          businessAnchorEl: null,
        };
      case 'UPDATE_STATE':
        return { ...state, ...action.payload };
      case 'TOGGLE_DRAWER':
        return { ...state, isOpen: !state.isOpen };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(headerReducer, initialState);
  const isLoggedIn = !!(
    localStorage.getItem(LocalStorageKeys.IMPERSONATE_TOKEN) || localStorage.getItem(LocalStorageKeys.AUTH_TOKEN)
  );
  const userType =
    localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE) || localStorage.getItem(LocalStorageKeys.USER_TYPE);
  const isBusinessUser = userType?.toUpperCase() === UserType.BUSINESS.toUpperCase();
  const appDispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { pathname } = useLocation();
  const handleDialogClose = () => setOpenDialog(false);
  const tabs = ['All', 'Claim Requests', 'Messages'];
  const [activeTab, setActiveTab] = useState('All');
  const notificationCount = useSelector((state: any) => state?.notification?.count ?? 0);
  const [notifications, setNotifications] = useState([]);
  const enqueueSnackbar = useSnackbar();

  // Fet Translations
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    appDispatch({
      type: SET_NOTIFICATION_COUNT,
      payload: 0,
    })
    emit('read-notification', {});
  };

  const individualMenuItems = [
    { label: staticTexts.myItems, path: '/my-items' },
    { label: staticTexts.addItem, path: '/add-first-item' },
    { label: staticTexts.claimRequests, path: '/claim-requests' },
    { label: staticTexts.myAccount, path: '/profile' },
    { label: staticTexts.signOut, path: null },
  ];

  const guestUserOptions = [
    { label: staticTexts.login, path: '/auth/login' },
    { label: staticTexts.signUp, path: '/auth/signup' },
  ];

  // Menu options for business users
  const businessMenuItems = [
    { label: staticTexts.manageItems, path: '/business-manage-items' },
    { label: staticTexts.addItem, path: '/business-add-first-item' },
    { label: staticTexts.claimRequests, path: '/claim-requests' },
    { label: staticTexts.myAccount, path: '/business/profile' },
    { label: staticTexts.signOut, path: null },
  ];

  const handleClearAll = async () => {
    try {
      startLoader();
      setAnchorEl(null);
      await deleteNotifications();
      setNotifications([]);
    } catch (error) {
      console.log(error);
    } finally {
      stopLoader();
    }
  };

  const handleNotificationClose = async () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleProfileClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => dispatch({ type: 'SET_PROFILE_ANCHOR_EL', payload: event.currentTarget }),
    []
  );

  const handleClose = useCallback(() => {
    dispatch({
      type: 'CLOSE_MENUS',
      payload: undefined,
    });
  }, []);

  const toggleDrawer = useCallback(
    () =>
      dispatch({
        type: 'TOGGLE_DRAWER',
        payload: undefined,
      }),
    []
  );

  const notificationsLabel = useCallback((count: number) => {
    if (count === 0) return 'no notifications';
    if (count > 99) return 'more than 99 notifications';
    return `${count} notifications`;
  }, []);

  const handleLogout = async() => {
    try {
      const isImpersonate = localStorage.getItem(LocalStorageKeys.IMPERSONATE_TOKEN);

      if(isImpersonate) {
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_TOKEN);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_USER_TYPE);

      } else {
        await logout();
        localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
        localStorage.removeItem(LocalStorageKeys.USER_TYPE);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_TOKEN);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_USER_TYPE);
      };

      appDispatch({
        type: RESET_PROFILE_DETAILS,
      });
      appDispatch({
        type: UPDATE_LOGIN_STATUS,
        payload: {
          isLoggedIn: false,
        },
      });
      setLogoutModal(false);
      enqueueSnackbar(staticTexts.logoutSuccessfully);
      navigate('/home');
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message || staticTexts.somethingWentWrong);
    }
  };

  const fetchNotifications = async () => {
    try {
      startLoader();
      const params = {
        page: 1,
        limit: 10,
      } as any;

      switch (activeTab) {
        case 'Claim Requests':
          params.type = 'NEW_CLAIM_REQUEST';
          break;
        case 'Messages':
          params.type = 'NEW_CHAT_MESSAGE';
          break;
        default:
          break;
      }
      const notificationsData = (await getNotifications(params)) as any;

      setNotifications(notificationsData?.notifications || []);
      emit('read-notification', {});
      dispatch({
        type: SET_NOTIFICATION_COUNT,
        payload: 0,
      });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchNotifications();
    }
  }, [activeTab, isLoggedIn]);

  const getNotificationTitle = (type: string, senderName: string) => {
    switch (type) {
      case 'NEW_CLAIM_REQUEST':
        return `${staticTexts.newClaimRequestFrom} ${senderName}`;
      case 'NEW_CHAT_MESSAGE':
        return `${staticTexts.newMessageFrom} ${senderName}`;
      default:
        return '';
    }
  };

  const getMenuOptions = useCallback(() => {
    const options = (() => {
      if (isLoggedIn && !isBusinessUser) {
        return [
          {
            label: staticTexts.addItem,
            redirectTo: '/add-first-item',
            onClick: () => navigate('/add-first-item'),
          },
          {
            label: staticTexts.myItems,
            redirectTo: '/my-items',
            onClick: () => navigate('/my-items'),
          },
          {
            label: staticTexts.claimRequest,
            path: '/claim-requests',
            onClick: () => navigate('/claim-requests'),
          },
          {
            label: staticTexts.myAccount,
            redirectTo: '/profile',
            onClick: () => navigate('/profile'),
          },
        ];
      } else if (isBusinessUser) {
        return [
          {
            label: staticTexts.addItem,
            path: '/business-add-first-item',
            onClick: () => navigate('/business-add-first-item'),
          },
          {
            label: staticTexts.manageItems,
            path: '/business-manage-items',
            onClick: () => navigate('/business-manage-items'),
          },
          {
            label: staticTexts.claimRequests,
            path: '/claim-requests',
            onClick: () => navigate('/claim-requests'),
          },
          {
            label: staticTexts.myAccount,
            path: '/business/profile',
            onClick: () => navigate('/profile'),
          },
        ];
      } else {
        return [
          {
            label: staticTexts.signIn,
            redirectTo: '/auth/login',
            onClick: () => {},
          },
          {
            label: staticTexts.signUp,
            redirectTo: '/auth/signup',
            onClick: () => {},
          },
        ];
      }
    })();

    return (
      <Fragment>
        {isLoggedIn ? (
          <Fragment>
            {/* Options for the logged-in user */}
            {options.map((option, index) => (
              <ListItem
                key={option.label}
                disablePadding
              >
                <ListItemButton
                  sx={{
                    paddingX: '24px',
                    fontSize: '18px',
                  }}
                >
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        letterSpacing: '0.01px',
                        fontSize: '18px',
                        color: '#FFF7E6',
                      },
                    }}
                    primary={option.label}
                    onClick={() => {
                      option.onClick();
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Fragment>
        ) : (
          // Guest User
          options.map((option: any, index) => (
            <ListItem
              key={option.label}
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      letterSpacing: '0.01px',
                      fontSize: '18px',
                    },
                  }}
                  primary={option.label}
                  onClick={() => navigate(option.redirectTo)}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </Fragment>
    );
  }, [
    staticTexts.addItem,
    staticTexts.login,
    staticTexts.myAccount,
    staticTexts.myItems,
    staticTexts.signOut,
    staticTexts.signUp,
    isBusinessUser,
    isLoggedIn,
  ]);

  const getPayload = (updatedLanguage: string) => {
    const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE);
    switch (userType) {
      case 'BUSINESS':
        return {
          name: user?.name,
          address: user?.address,
          email: user?.email,
          defaultLanguage: updatedLanguage,
          ...(user?.profile && { profile: user?.profile }),
          ...(user?.coverProfile && { coverProfile: user?.coverProfile }),
          description: user?.description,
        };

      case 'INDIVIDUAL':
        return {
          name: user?.fullName,
          email: user?.email,
          dob: user?.dob,
          gender: user?.gender.toUpperCase(),
          defaultLanguage: updatedLanguage,
          ...(user?.profile && { profile: user?.profile }),
        };
      default:
        break;
    }
  };

  const handleLanguageChange = () => {
    const updatedLanguage = localStorage.getItem(LocalStorageKeys.LANGUAGE) === Language.EN ? Language.AR : Language.EN;
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        selectedLanguage: updatedLanguage === Language.EN ? 'English' : 'عربي',
      },
    });
    
    appDispatch({
      type: SET_LANGUAGE,
      payload: updatedLanguage,
    });

    appDispatch({
      type: UPDATE_CATEGORY_LANGUAGE,
      payload: {language: updatedLanguage},
    });

    if (isLoggedIn) {
      try {
        startLoader();

        const payload = getPayload(updatedLanguage);

        updateProfile(payload);
        appDispatch({
          type: UPDATE_PROFILE_DETAILS,
          payload: {
            defaultLanguage: updatedLanguage,
          },
        });
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message || staticTexts.somethingWentWrong);
      } finally {
        stopLoader();
      }
    }
    localStorage.setItem(LocalStorageKeys.LANGUAGE, updatedLanguage);
    handleClose();
    return;
  };

  return (
    <nav className='min-w-96'>
      <div className='w-full h-14 bg-secondary-400 opacity-100 flex justify-center items-center'>
        <Box
          className='w-full flex justify-between items-center min-h-[56px]'
          sx={{
            width: {
              xs: '92%',
              md: '80%',
            },
          }}
        >
          {/* App Logo Section */}
          <Suspense fallback={<div>{staticTexts.loading}</div>}>
            <div
              className='w-24 h-full opacity-100 ps-6 md:pl-0 font-extrabold cursor-pointer flex justify-start items-center -mb-0.5'
              onClick={() =>
                userType?.toUpperCase() === UserType.BUSINESS
                  ? navigate('/business-manage-items', {
                      state: { showOverview: true },
                    })
                  : navigate('/home')
              }
            >
              <img
                src={language === Language.AR ? arabicLogo : logo}
                alt='app-logo'
                loading='lazy'
              />
            </div>
          </Suspense>

          {/* Right Section */}
          {!pathname.includes('auth') && (
            <>
              <div className='flex items-center text-center gap-2'>
                {/* Navigation Links */}
                <div className={`lg:flex hidden space-x-4 rtl:space-x-reverse text-white text-sm`}>
                  {/* Lqeet For Business Button */}
                  {userType?.toUpperCase() !== UserType.BUSINESS && (
                    <>
                      {/* Sign in for Business Button */}
                      <button
                        className='flex items-center cursor-pointer justify-center rounded-md py-1'
                        onClick={() => {
                          navigate('/auth/business-login');
                          handleClose();
                        }}
                      >
                        <span className='text-FFF7E6'>{staticTexts.signInForBusiness}</span>
                      </button>
                    </>
                  )}

                  {/* Vertical Divide Line */}
                  {userType?.toUpperCase() !== UserType.BUSINESS && (
                    <span className='bg-FFFFFF4D w-[1px] rounded-md'></span>
                  )}

                  {/* Help Button */}
                  <span className='flex items-center justify-center cursor-pointer py-1 text-FFF7E6'>
                    <span>
                      <span
                        onClick={() => navigate('/help')}
                        style={{ alignItems: 'center', display: 'flex' }}
                      >
                        {staticTexts.help}
                      </span>
                      <span>
                        <div
                          style={{
                            marginBottom: -3,
                            backgroundColor: pathname.includes('help') ? '#FF6F61' : 'transparent',
                          }}
                          className='h-1 w-full rounded-full'
                        ></div>
                      </span>
                    </span>
                  </span>

                  {/* Home Button */}
                  <span className='flex items-center justify-center cursor-pointer py-1 text-FFF7E6'>
                    <span>
                      <span
                        style={{ alignItems: 'center', display: 'flex' }}
                        onClick={() =>
                          navigate(
                            isBusinessUser ? '/business-manage-items' : '/home',
                            isBusinessUser ? { state: { showOverview: true } } : undefined
                          )
                        }
                      >
                        {staticTexts.home}
                      </span>
                      <span>
                        <div
                          style={{
                            marginBottom: -3,
                            backgroundColor:
                              pathname.includes('home') || pathname.includes('/business-manage-items')
                                ? '#FF6F61'
                                : 'transparent',
                          }}
                          className='h-1 w-full rounded-full'
                        />
                      </span>
                    </span>
                  </span>

                  {/* Vertical Divide Line */}
                  <span className='bg-FFFFFF4D w-[1px] rounded-md'></span>

                  {/* Language Button */}
                  <button
                    onClick={handleLanguageChange}
                    className='flex items-center cursor-pointer justify-center rounded-md'
                  >
                    {/* Flag Icon */}
                    <img
                      src={language === Language.AR ? englishFlag : arabicFlag}
                      alt='language'
                      loading='lazy'
                      width='20'
                      style={{ borderRadius: 2 }}
                    />
                    <span className='rtl:mr-1 ltr:ms-1 text-FFF7E6'>{staticTexts.language}</span>
                  </button>

                  {/* Vertical Divide Line */}
                  <span className='bg-FFFFFF4D w-[1px] rounded-md'></span>
                </div>

                {/* Notification Section */}
                {isLoggedIn && (
                  <div className='flex items-center gap-2'>
                    {/* Chat Icon */}
                    <IconButton
                      aria-label={notificationsLabel(100)}
                      onClick={() => navigate('/chat')}
                    >
                      <Badge
                        badgeContent=' '
                        // sx={{
                        //   '& .MuiBadge-badge': {
                        //     backgroundColor: '#FF6F61',
                        //     color: '#FFF7E6',
                        //     width: '12px',
                        //     height: '12px',
                        //     top: '4px',
                        //     right: '2px',
                        //     minWidth: 'unset',
                        //   },
                        // }}
                      >
                        <ChatBubbleIcon className='text-white' />
                      </Badge>
                    </IconButton>

                    {/* Notification Icon */}
                    <IconButton
                      className='cursor-pointer'
                      aria-label={notificationsLabel(notificationCount)}
                      onClick={(e) => {
                        fetchNotifications();
                        handleOpen(e);
                      }}
                    >
                      <Badge
                        badgeContent={notificationCount}
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: '#FF6F61',
                            color: '#FFF7E6',
                            width: '18px',
                            height: '18px',
                            fontSize: '8px',
                            top: '4px',
                            right: '2px',
                            minWidth: 'unset',
                            fontWeight: 'bold',
                          },
                        }}
                      >
                        <NotificationsIcon className='text-white' />
                      </Badge>
                    </IconButton>
                  </div>
                )}

                {/* Notification List View */}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleNotificationClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box
                    className=' max-h-[675px] sm:min-w-[412px] min-w-[365px] overflow-y-auto bg-FFF7E6 shadow-md sm:py-2 py-1 rounded-lg'
                    sx={{
                      maxHeight: 'calc(100vh - 20px)',
                      width: '100%',
                      maxWidth: '412px',
                      '@media (max-width: 768px)': {
                        maxHeight: '100vh',
                        borderRadius: '0',
                        width: '100%',
                      },
                      paddingBottom: '0px',
                    }}
                  >
                    {/* Heading, Close Button */}
                    <div className='flex justify-between items-center mx-4'>
                      {/* Notifications Title */}
                      <span className='text-primary_text font-medium sm:text-xl text-base '>
                        {staticTexts.notifications}
                      </span>

                      {/* Close Button */}
                      <CustomButton
                        buttonStyle={{ marginRight: -2 }}
                        variant={AppButtonVariant.TEXT}
                        title={staticTexts.clearAll}
                        onPress={handleClearAll}
                      />
                    </div>

                    {/* Notification Tabs */}
                    <Box
                      className='px-4'
                      sx={{
                        display: 'flex',
                        gap: '4px',
                        marginTop: '6px',
                        marginBottom: '6px',
                        backgroundColor: '#fff7e5',
                        borderRadius: '6px',
                        flexWrap: 'wrap',
                        '@media (max-width: 768px)': {
                          gap: '4px',
                        },
                      }}
                    >
                      {tabs.map((tab) => (
                        <Box
                          key={tab}
                          onClick={() => handleTabClick(tab)}
                          className='cursor-pointer text-xs font-regular'
                          sx={{
                            backgroundColor: activeTab === tab ? '#4A403A' : '#FFE0B5',
                            color: activeTab === tab ? '#FFF7E6' : '#655B53FA',
                            textTransform: 'none',
                            borderRadius: '6px',
                            padding: '4px 10px',
                            '&:hover': {
                              backgroundColor: activeTab === tab ? '#3c342b' : '#f9dcb8',
                            },
                            '@media (max-width: 768px)': {
                              fontSize: '12px',
                              padding: '6px 8px',
                            },
                          }}
                        >
                          {tab}
                        </Box>
                      ))}
                    </Box>

                    {/* Notifications List */}
                    {(notifications || []).map((notif: any) => (
                      <Box
                        className='flex border-b border-[#FFE0B5] last:border-none px-4 py-2 w-full'
                        sx={{ display: 'flex' }}
                      >
                        {/* Profile Picture */}
                        <BadgeAvatars senderImage={notif.meta.senderProfile} />

                        {/* Notification Title, Message, Date */}
                        <Box
                          key={notif._id}
                          className='flex flex-col align-center justify-center'
                          sx={{
                            '@media (max-width: 768px)': {
                              gap: '2px',
                            },
                            marginLeft: '8px',
                            display: 'flex',
                            flex: '1',
                          }}
                          width={'100%'}
                          display={'flex'}
                        >
                          <div className='flex justify-between items-start'>
                            {/* Notification Title */}
                            <span className='text-xs font-medium text-primary_text mr-4'>
                              {getNotificationTitle(notif.type, notif.meta.senderName)}
                            </span>

                            {/* Notification Time */}
                            <Typography
                              fontSize='12px'
                              color='#655B53FA'
                              sx={{
                                '@media (max-width: 768px)': {
                                  fontSize: '10px',
                                },
                              }}
                            >
                              {formatDate(notif.createdAt)}
                            </Typography>
                          </div>

                          {/* Notification Message */}

                          <span className='text-xs font-regular text-secondary-200'>
                            {handleCharacterLimit(notif.meta.message, 20)}
                          </span>

                          {/* Notification Actions */}
                          {notif.actions && (
                            <Box className='mt-2 flex space-x-2'>
                              {notif.actions.map((action: any) => (
                                <Button
                                  key={action}
                                  size='small'
                                  variant={action === 'View' ? 'contained' : 'outlined'}
                                  sx={{
                                    color: action === 'View' ? '#FFF7E6' : '#4A403A',
                                    backgroundColor: action === 'View' ? '#FF6F61' : '',
                                    borderColor: action === 'View' ? '' : '#C9C2B4',
                                    '@media (max-width: 768px)': {
                                      fontSize: '10px',
                                      padding: '4px 6px',
                                    },
                                  }}
                                  onClick={() => {
                                    if (action === 'View') {
                                      setOpenDialog(true);
                                      handleNotificationClose();
                                    }
                                  }}
                                >
                                  {action}
                                </Button>
                              ))}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}

                    {/* No Data Found View */}
                    {!notifications?.length && (
                      <span className='text-center text-help_text p-6 w-full text-sm font-regular flex justify-center items-center pb-7'>
                        {staticTexts.noNotificationsAvailable}
                      </span>
                    )}
                  </Box>
                </Popover>

                {/* Signin / Signup buttons */}
                {!isLoggedIn && (
                  <div className={`lg:flex hidden space-x-2 rtl:space-x-reverse text-white text-sm ms-1.5`}>
                    {/* Sign in button  */}
                    <button
                      className='flex items-center cursor-pointer justify-center rounded-md py-1'
                      onClick={() => {
                        navigate('/auth/login');
                        handleClose();
                      }}
                    >
                      <span>{staticTexts.signIn}</span>
                    </button>

                    {/* Vertical Divide Line */}
                    <span className='text-[#FFFFFF4D] rounded-md py-1'>|</span>

                    {/* Sign up button  */}
                    <button
                      className='flex items-center cursor-pointer justify-center rounded-md py-1'
                      onClick={() => {
                        navigate('/auth/signup');
                        handleClose();
                      }}
                    >
                      <span>{staticTexts.signUp}</span>
                    </button>
                  </div>
                )}

                {/* Profile Section */}
                {isLoggedIn && (
                  <Box className='hidden lg:block rounded-md ms-1.5'>
                    {/* Profile Icon */}
                    <div
                      onClick={handleProfileClick}
                      className='flex-row flex justify-center items-center cursor-pointer'
                    >
                      {/* User Profile Picture */}
                      <Suspense fallback={<div>Loading...</div>}>
                        <Avatar
                          className='bg-green-500'
                          src={user?.profile ? user?.profile : defaultProfileImg}
                          imgProps={{ loading: 'lazy' }}
                          style={{ height: 32, width: 32 }}
                        ></Avatar>
                      </Suspense>

                      {/* User Name Text */}
                      <span className='text-sm p-2 rtl:text-right text-FFF7E6'>{user?.name}</span>
                      {!Boolean(state.profileAnchorEl) && <KeyboardArrowDown className='text-FFF7E6' />}

                      {Boolean(state.profileAnchorEl) && <KeyboardArrowUp className='text-FFF7E6' />}
                    </div>

                    {/* Profile Menus */}
                    <Menu
                      anchorEl={state.profileAnchorEl}
                      open={Boolean(state.profileAnchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      className='rtl:ltr' // add this class to reverse the menu direction in RTL layout
                    >
                      {!isLoggedIn
                        ? guestUserOptions.map((option: any) => (
                            <MenuItem
                              key={option.label}
                              onClick={() => {
                                console.log('option', option.path);
                                if (option.path) navigate(option.path);
                                handleClose();
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))
                        : isBusinessUser
                        ? businessMenuItems.map((option) => (
                            <MenuItem
                              key={option.label}
                              className={`${option.label === staticTexts.signOut && 'text-accent'}`}
                              onClick={() => {
                                if (option.label === staticTexts.signOut) {
                                  setLogoutModal(true);
                                }
                                if (option.label === staticTexts.dashboard && option.path) {
                                  navigate(option?.path, {
                                    state: { showOverview: true },
                                  });
                                  handleClose();
                                  return;
                                }
                                if (option.path) navigate(option.path);
                                handleClose();
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))
                        : individualMenuItems.map((option) => (
                            <MenuItem
                              className={`${option.label === staticTexts.signOut && '!text-accent'}`}
                              key={option.label}
                              onClick={() => {
                                if (option.label === staticTexts.signOut) {
                                  setLogoutModal(true);
                                }
                                if (option.path) navigate(option.path);
                                handleClose();
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                    </Menu>
                  </Box>
                )}

                {/* Mobile View: Hamburger Icon */}
                <div className='lg:hidden me-4'>
                  <IconButton
                    className='!text-[#FFF7E6]'
                    onClick={toggleDrawer}
                  >
                    {state.isOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </div>
              </div>
            </>
          )}
        </Box>
      </div>

      {/* Drawer */}
      <Drawer
        anchor='bottom'
        open={state.isOpen}
        sx={{
          height: 'fit-content',
          '& .MuiDrawer-paper': {
            height: 'calc(100vh - 56px)',
            overflow: 'hidden',
            backgroundColor: '#4A403A',
            opacity: 1,
            boxShadow: 'none',
            color: '#FFF7E6',
          },
        }}
        onClose={toggleDrawer}
        hideBackdrop={true}
      >
        <Box
          sx={{ width: 'auto' }}
          role='presentation'
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <List>
            {/* Profile Menu */}
            {isLoggedIn && (
              <ListItem
                key={user?.name}
                disablePadding
                onClick={() => navigate('/profile')}
              >
                <ListItemButton
                  sx={{
                    paddingX: '24px',
                    fontSize: '18px',
                  }}
                >
                  <ListItemIcon>
                    {/* User avatar */}
                    <Avatar
                      src={user?.profile || ProfileIcon}
                      imgProps={{ loading: 'lazy' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        letterSpacing: '0.01px',
                        fontSize: '18px',
                      },
                    }}
                    primary={user?.name}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {/* Home Menu */}
            <ListItem
              key='home'
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
                onClick={() =>
                  isBusinessUser
                    ? navigate('/business-manage-items', {
                        state: { showOverview: true },
                      })
                    : navigate('/home')
                }
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '18px',
                    },
                  }}
                  primary={staticTexts.home}
                />
              </ListItemButton>
            </ListItem>

            {/* Other Menus */}
            {getMenuOptions()}

            {/* Help Menu */}
            <ListItem
              key='help'
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
                onClick={() => navigate('/help')}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '18px',
                    },
                  }}
                  primary={staticTexts.help}
                />
              </ListItemButton>
            </ListItem>

            {/* Language Menu */}
            <ListItem
              key='language'
              disablePadding
            >
              {/* Language Button */}
              <ListItemButton
                onClick={handleLanguageChange}
                className='flex items-center cursor-pointer justify-center rounded-md'
                sx={{
                  paddingX: '24px',
                }}
              >
                {/* Flag Icon */}
                <img
                  src={language === Language.AR ? englishFlag : arabicFlag}
                  alt='language'
                  loading='lazy'
                  width='20'
                  style={{ borderRadius: 2 }}
                />
                <ListItemText
                  className='rtl:mr-1 ltr:ms-1 text-FFF7E6'
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '18px',
                    },
                  }}
                  primary={staticTexts.language}
                />
              </ListItemButton>
            </ListItem>

            {/* Sign In For Business Menu */}
            {userType?.toUpperCase() !== UserType.BUSINESS && (
              <ListItem
                key='signInForBusiness'
                disablePadding
              >
                <ListItemButton
                  sx={{
                    paddingX: '24px',
                  }}
                  onClick={() => navigate('/auth/business-login')}
                >
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '18px',
                      },
                    }}
                    primary={staticTexts.signInForBusiness}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {/* Signout Menu */}
            {isLoggedIn && (
              <ListItem
                key='signout'
                disablePadding
              >
                <ListItemButton
                  sx={{
                    paddingX: '24px',
                  }}
                  onClick={() => setLogoutModal(true)}
                >
                  <ListItemText
                    className='text-FF6F61'
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '18px',
                      },
                    }}
                    primary={staticTexts.signOut}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>

      {/* Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        title={staticTexts.claimItemDetails}
        maxWidth='lg'
        titlePadding='16px 24px'
        contentPadding='0px 24px'
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Typography
              variant='h6'
              fontSize='16px'
              color='#4A403A'
              gutterBottom
            >
              {staticTexts.itemDetails}
            </Typography>
            <Grid2
              container
              spacing={2}
            >
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.itemName}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Black wallet with driver’s license and credit cards.
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.description}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Black wallet with driver’s license and credit cards.
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.location}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Downtown Café, 5th Avenue
                  </Typography>
                </Box>
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.fullName}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    John Doe
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Email Address
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    johndoe@example.com
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    {staticTexts.dateLost}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    16 Oct, 2024
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    What's the brand
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Gucci
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Last 4 digits of the account number{' '}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    XXXXXX1234{' '}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>

            {/* Uploaded Images Section */}
            <Box mt={4}>
              <Typography
                variant='h6'
                fontSize='16px'
                color='#4A403A'
                gutterBottom
              >
                {staticTexts.uploadImages}
              </Typography>
              <Box
                display='flex'
                gap='8px'
                paddingBottom='24px'
              >
                <img
                  src={iPhoneImg}
                  alt='Item 1'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
                <img
                  src={iPhoneImg}
                  alt='Item 2'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
                <img
                  src={iPhoneImg}
                  alt='Item 3'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
                <img
                  src={iPhoneImg}
                  alt='Item 4'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
              </Box>
            </Box>
          </Box>
        }
      />

      <Modal
        open={openLogoutModal}
        onClose={() => setLogoutModal(false)}
        onConfirm={handleLogout}
        confirmText={staticTexts.signOut}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.logoutTitle}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.logoutTextDescription}
        </Typography>
      </Modal>
    </nav>
  );
};
