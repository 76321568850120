import { useSelector } from 'react-redux';
import { StyledContainer, SectionWrapper, Section, SectionTitle, SectionText, SectionHeader } from '../Static.styles';
import { getStaticTexts } from '../../../assets';

export const PrivacyPolicy = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <StyledContainer maxWidth='md'>
      {/* Header Section */}
      <Section>
        <SectionHeader variant='h4'>{staticTexts.privacyPolicy}</SectionHeader>
      </Section>

      {/* Content Section */}
      <SectionWrapper>
        {/* Privacy Policy */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.introduction}</SectionTitle>
          <SectionText>{staticTexts.privacyPolicyDescription}</SectionText>
        </Section>

        {/* Information we collect */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.informationWeCollect}</SectionTitle>
          <SectionText>
            • <b>{staticTexts.personalInformation}</b>: {staticTexts.personalInformationDescription} <br />•{' '}
            <b>{staticTexts.technicalInformation}</b>: {staticTexts.technicalInformationDescription} <br />•{' '}
            <b>{staticTexts.easeOfUse}</b>: {staticTexts.easeOfUseDescription}
          </SectionText>
        </Section>

        {/* How do we use information */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.howDoWeUseYourInformation}</SectionTitle>
          <SectionText>
            • <b>{staticTexts.serviceProvision}</b>: {staticTexts.serviceProvisionDescription} <br />•{' '}
            <b>{staticTexts.communication}</b>: {staticTexts.communicationDescription} <br />•{' '}
            <b>{staticTexts.dataAnalysis}</b>: {staticTexts.dataAnalysisDescription}
          </SectionText>
        </Section>

        {/* Information Protection */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.informationProtection}</SectionTitle>
          <SectionText> • {staticTexts.informationProtectionDescription1}</SectionText>
          <SectionText> • {staticTexts.informationProtectionDescription2}</SectionText>
        </Section>

        {/* User Access Rights */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.userAccessRights}</SectionTitle>
          <SectionText>
            • <b>{staticTexts.accessToInformation}</b>: {staticTexts.accessToInformationDescription} <br />•{' '}
            <b>{staticTexts.correctionOfInformation}</b>: {staticTexts.correctionOfInformationDescription} <br />•{' '}
            <b>{staticTexts.deletionOfInformation}</b>: {staticTexts.deletionOfInformationDescription}
          </SectionText>
        </Section>

        {/* Information Sharing */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.informationSharing}</SectionTitle>
          <SectionText>• {staticTexts.informationsharingDescription}</SectionText>
        </Section>

        {/* Information Sharing */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.changesToPrivacyPolicy}</SectionTitle>
          <SectionText>• {staticTexts.changesToPrivacyPolicyDescription}</SectionText>
        </Section>
      </SectionWrapper>
    </StyledContainer>
  );
};
