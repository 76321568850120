import React, { ChangeEvent, memo } from 'react';
import { TextField, MenuItem, InputAdornment, Select, SelectChangeEvent } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import { countries, getStaticTexts } from '../../assets';
import STORE from '../../redux/store';

const language = STORE.getState().language;
const staticTexts = getStaticTexts(language);

interface PhoneNumberInputProps {
  label?: string;
  className?: string;
  name: string;
  helperText?: string;
  countryCode?: string;
  phoneNumber?: string;
  control: Control<any>;
  error?: FieldError;
  helperTextColor?: string;
  validationRules?: Record<string, any>;
  handlePhoneNumberChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange?: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean
}

const PhoneNumberInputComponent: React.FC<PhoneNumberInputProps> = ({
  label = staticTexts.phoneNumber,
  className,
  name,
  helperText,
  countryCode,
  control,
  error,
  validationRules = {},
  helperTextColor,
  handleCountryCodeChange,
  disabled = false
}) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: `${label} ${staticTexts.isRequired}`,
        ...validationRules,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          disabled={disabled}
          label={label}
          className={className}
          variant='outlined'
          fullWidth
          placeholder='00000 00000'
          size='small'
          error={!!error}
          helperText={error?.message || helperText}
          autoComplete='off'
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, '');
            field.onChange(numericValue);
          }}
          sx={{
            '& .MuiFormHelperText-root': {
              color: error ? 'error.main' : helperTextColor || '#4A403A',
            },
          }}
          slotProps={{
            input: {
              inputMode: 'numeric',
              startAdornment: (
                <InputAdornment position='start'>
                  <Select
                    disabled={disabled}
                    value={countryCode}
                    onChange={(e: any) => {
                      handleCountryCodeChange?.(e.target.value)
                    }}
                    variant='standard'
                    disableUnderline
                    sx={{
                      marginInlineEnd: '8px',
                      '& .MuiSelect-select': {
                        paddingTop: '8px',
                        color: '#4A403A',
                        fontSize: '14px',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px',
                        },
                      },
                    }}
                  >
                    {countries.map((code) => (
                      <MenuItem
                        key={code.name}
                        value={`+${code.dial_code}`}
                      >
                        {`+${code.dial_code}`}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            },
          }}
        />
      )}
    />
  );
};

export const PhoneNumberInput = memo(PhoneNumberInputComponent);
