import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';
import { Typography } from '@mui/material';
import { useSnackbar } from '../Snackbar';
import { upload } from '../../services/common';
import { getFileNameFromUrl, startLoader, stopLoader } from '../../utils';

interface ImageInputProps {
  helperText?: string;
  uploadedImages?: string[];
  onFileChange?: (fileUrls: string[]) => void;
}

export const ImageUpload: React.FC<ImageInputProps> = ({ helperText,uploadedImages, onFileChange }) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const enqueueSnackbar = useSnackbar();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const uploadImage = async (file: File) => {
    try {
      // Prepare File to Upload
      const maxSize = 5 * 1024 * 1024; // 5MB
      const maxDimension = 1024; // Max width/height in pixels

      // Show File Selection Error
      if (!file) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile);
        return;
      }

      // Show File Size Error
      const fileSize = file.size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan5MB);
        return;
      }

      // Check image dimensions
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = async () => {
        URL.revokeObjectURL(objectUrl);

        if (img.width > maxDimension || img.height > maxDimension) {
          // Create canvas to resize image
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxDimension) {
              height = Math.round((height * maxDimension) / width);
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width = Math.round((width * maxDimension) / height);
              height = maxDimension;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          // Convert canvas to blob
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                // Show Loading
                startLoader();
                const profileUrl = await upload(blob);
                if (profileUrl) {
                  setFileUrls((prev) => [...prev, profileUrl]);
                  setUploadedFiles((prev) => [...prev, profileUrl].slice(0, 3));
                  if (onFileChange) {
                    onFileChange([...uploadedFiles, profileUrl]);
                  }
                }
                // Stop Loading
                stopLoader();
              }
            },
            'image/jpeg',
            0.9
          );
        } else {
          // Show Loading
          startLoader();

          const profileUrl = await upload(file);
          if (profileUrl) {
            setFileUrls((prev) => [...prev, profileUrl]);
            setUploadedFiles((prev) => [...prev, profileUrl].slice(0, 3));
            if (onFileChange) {
              onFileChange([...uploadedFiles, profileUrl]);
            }
          }

          // Stop Loading
          stopLoader();
        }
      };

      img.src = objectUrl;
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };

  // Handle input file selection
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files);
      for (const file of newFiles) {
        await uploadImage(file);
      }
    }
  };

  // Handle removing a specific file
  const removeFile = (index: number) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    const updatedUrls = fileUrls.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
    setFileUrls(updatedUrls);
    
    if (onFileChange) {
      onFileChange(updatedFiles);
    }
  };
  

  // Handle drag-over state
  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  // Handle drag-leave state
  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  // Handle dropped files
  const handleDrop = async (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const newFiles = Array.from(event.dataTransfer.files);
      for (const file of newFiles) {
        await uploadImage(file);
      }
      setUploadedFiles((prev) => [...prev, ...newFiles].slice(0, 3));
    }
  };

  useEffect(() => {
      if(uploadedImages?.length && isInitialRender){
        setUploadedFiles(uploadedImages);
        setIsInitialRender(false);
      }
  },[uploadedImages])

  return (
    <div className='rounded-[4px]'>
      {/* Upload Input with Drag-and-Drop */}
      <label
        htmlFor='file-upload'
        className={`flex items-center justify-between w-full p-3 border-2 border-dashed rounded-lg cursor-pointer 
          ${isDragOver ? 'border-[#93897F] bg-[#F7EFDE]' : 'border-[#776E65]'} 
          ${uploadedFiles?.length >= 3 ? 'opacity-50 cursor-not-allowed' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <span className='text-[#776E65]'>{isDragOver ? staticTexts.dropHere : staticTexts.chooseFile}</span>
        <AddPhotoAlternateIcon sx={{ color: '#776E65' }} />
        <input
          id='file-upload'
          type='file'
          className='hidden'
          accept='.png, .jpg, .jpeg, .webp'
          multiple
          disabled={uploadedFiles?.length >= 3}
          onChange={handleFileChange}
        />
      </label>

      {/* Helper Text */}
      <Typography
        color='#93897F'
        fontSize='12px'
        marginInlineStart={'4px'}
        marginTop='4px'
      >
        {helperText || staticTexts.allowedImageFormatText}
      </Typography>

      {/* File List */}
      <ul className='my-4 space-y-2'>
        {uploadedFiles.map((file, index) => (
          <li
            key={index}
            className='flex items-center justify-between p-2 bg-[#F7EFDE] rounded-lg'
          >
            <span className='text-sm text-accent truncate'>{file.name || getFileNameFromUrl(file)}</span>
            <button
              type='button'
              onClick={() => removeFile(index)}
              className='p-1 text-gray-600 hover:text-gray-900'
            >
              <CloseIcon fontSize='small' />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
