import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { styled } from '@mui/material/styles';
import { Dialog } from '../Dialog';
import { GoogleMap } from '../GoogleMap';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';
import { handleUseCurrentLocation } from '../../utils';

interface LocationInputProps {
  name: string;
  control: any;
  label?: string;
  onCurrentLocationClick: (location: { latitude: number; longitude: number } | null, error: string | null) => void;
  handleLocationSelect: (location: { lat: number; lng: number; description: string }) => void;
  rules?: Record<string, any>;
  className?: string;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[400],
  },
  '& .MuiInputAdornment-root': {
    color: theme.palette.grey[500],
  },
}));

export const LocationInput: React.FC<LocationInputProps> = ({
  name,
  control,
  label = 'Location',
  handleLocationSelect,
  className = '!mb-5',
  rules,
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            {...field}
            fullWidth
            variant='outlined'
            className={className}
            label={label}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LocationOnIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <button
                    className='flex items-center space-x-1 text-accent text-sm'
                    type='button'
                  >
                    <span
                      onClick={async () => {
                        const { latitude, longitude, locationName } = (await handleUseCurrentLocation()) as any;
                        handleLocationSelect({ lat: latitude, lng: longitude, description: locationName });
                      }}
                    >
                      {staticTexts.useCurrentLocation}
                    </span>
                    <MyLocationIcon
                      fontSize='small'
                      className='text-accent'
                      onClick={() => setOpenDialog(true)}
                    />
                  </button>
                </InputAdornment>
              ),
              readOnly: true,
            }}
          />
        )}
      />

      <Dialog
        open={openDialog}
        content={
          <GoogleMap
            onLocationSelect={(location: { lat: number; lng: number; description: string }) => {
              handleLocationSelect(location);
              setOpenDialog(false);
            }}
          />
        }
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};
