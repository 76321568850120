import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { getStaticTexts, productPlaceHolder } from '../../../assets';
import Slider from 'react-slick';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './index.css';
import { Modal } from '../../Modal';
import ImageViewer from '../ImageViewer';
import { formatDate } from '../../../utils';
import CustomButton from '../../Button/CustomButton';
import { UserType } from '../../../constants/AppConstants';
import StringFormatting from '../../../utils/extensions/StringFormatting';

const ProductCard = (props: {
  itemId?: string;
  userType?: string;
  isDeleteIconDisabled?: boolean;
  image?: string;
  itemCategory: string;
  itemName: string;
  description: string;
  location: string;
  date: string;
  type: string;
  images?: string[];
  showDate?: boolean;
  showPrivacy?: boolean;
  showLocation?: boolean;
  showActions?: boolean;
  showClaimButton?: boolean;
  showMoreIcon?: boolean;
  menuOptions?: { label: string; onClick: () => void }[];
  onEdit?: () => void;
  onDelete?: () => void;
  showEditButton?: boolean;
}) => {
  const {
    itemId,
    isDeleteIconDisabled,
    itemCategory,
    itemName,
    description,
    location,
    date,
    images,
    type,
    showDate = true,
    showPrivacy = true,
    showLocation = true,
    showActions = true,
    showClaimButton = false,
    showMoreIcon = true,
    menuOptions = [],
    onEdit,
    onDelete,
    showEditButton = true,
    userType,
  } = props;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [anchorEl, setAnchorEl] = React.useState<null | (EventTarget & SVGSVGElement)>(null);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleClose = () => setIsOpen(false);
  const handleMenuClose = () => setAnchorEl(null);
  const handleConfirm = () => {
    if (userType?.toUpperCase() === UserType.BUSINESS) {
      navigate('/business-manage-items');
    } else {
      navigate('/my-items');
    }
    setIsOpen(false);
  };

  const imagesToShow = (images || [])?.length > 0 ? images : [...(images || []), productPlaceHolder].slice(0, 1);

  const sliderSettings = {
    dots: (imagesToShow || []).length > 1,
    infinite: (imagesToShow || []).length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: (imagesToShow || []).length > 1,
    autoplaySpeed: 3000,
    arrows: false,
    appendDots: (dots: React.ReactNode) => (
      <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: () => <div className='w-2 h-2 bg-white rounded-full opacity-70 hover:opacity-100'></div>,
    dotsClass: 'slick-dots',
  };

  return (
    <Card
      className='rounded-[14px] overflow-hidden p-[2px] w-full md:max-w-[280px] lg:max-w-[256px]'
      sx={{ background: '#F7EFDE', boxShadow: 'none', cursor: 'pointer' }}
    >
      <Slider
        {...sliderSettings}
        className='carousel-container'
      >
        {imagesToShow?.map((imgSrc, index) => (
          <Box
            key={index}
            height='150px'
            borderRadius='12px'
          >
            <img
              src={imgSrc || productPlaceHolder}
              alt={`product-image ${index + 1}`}
              className='object-cover h-full w-full rounded-xl'
              onClick={() => {
                setSelectedImage(imgSrc);
                setOpenImage(true);
              }}
            />
          </Box>
        ))}
      </Slider>
      <CardContent
        className='px-4 py-2'
        sx={{ padding: '4px' }}
      >
        {/* Item Category */}
        <span className='truncate max-w-full block text-xs text-regular text-help_text'>
          {StringFormatting.capitalizeFirstLetterOnly(itemCategory?.[language])}
        </span>

        {/* Item Name */}
        <span
          style={{ fontWeight: '500' }}
          className='truncate max-w-full block text-sm text-semibold text-primary_text'
        >
          {itemName}
        </span>

        {/* Items Description */}
        <span
          style={{ direction: 'ltr', textAlign: 'left' }}
          className='truncate max-w-full block text-xs text-regular text-primary_text'
        >
          {description}
        </span>

        {/* Location */}
        {showLocation && (
          <div className='flex items-center mt-1 space-x-1 text-xs'>
            <LocationOnIcon
              sx={{
                fontSize: '16px',
                marginInlineEnd: '4px',
                color: '#776E65',
              }}
            />
            <span
              style={{ direction: 'ltr' }}
              className='truncate max-w-full block'
            >
              {location}
            </span>
          </div>
        )}

        {/* Privacy Type */}
        {showPrivacy && (
          <div className='flex items-center mt-1 space-x-1 text-xs'>
           {type === 'Public' ? <PublicIcon sx={{ fontSize: '16px', marginInlineEnd: '4px', color: '#776E65' }} /> : <LockIcon
              sx={{
                fontSize: '16px',
                marginInlineEnd: '4px',
                color: '#776E65',
              }}
            />}{' '}
            {type}
          </div>
        )}

        {/* Date */}
        {showDate && (
          <div className='flex items-center mt-1 space-x-1 text-xs'>
            <AccessTimeFilledIcon
              sx={{
                fontSize: '16px',
                marginInlineEnd: '4px',
                color: '#776E65',
              }}
            />{' '}
            {formatDate(date)}
          </div>
        )}
      </CardContent>

      {/* Actions */}
      {showActions && (
        <div className='flex justify-between px-1 py-1'>
          <div>
            {showEditButton && (
              <IconButton
                aria-label='edit'
                size='small'
                onClick={onEdit}
              >
                <EditIcon fontSize='small' />
              </IconButton>
            )}
            {!isDeleteIconDisabled && (
              <IconButton
                aria-label='delete'
                size='small'
                color='error'
                onClick={onDelete}
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            )}
          </div>
          {showMoreIcon && (
            <IconButton
              aria-label='more'
              size='small'
            >
              <MoreVertIcon
                fontSize='small'
                onClick={(event) => setAnchorEl(event.currentTarget)}
              />
            </IconButton>
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => {
                  option.onClick();
                  handleMenuClose();
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {userType?.toUpperCase() !== UserType.BUSINESS && showClaimButton && (
        <div className='flex justify-between px-1.5 py-2'>
          <CustomButton
            width='100%'
            title={staticTexts.claimItem}
            onPress={() => navigate(`/item-detail/${itemId}`)}
          />
        </div>
      )}

      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={staticTexts.delete}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
        cancelButtonStyle={{ color: '#4A403A', border: '1px solid #C9C2B4' }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.deleteItem}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.deleteItemWarning}
        </Typography>
      </Modal>

      <ImageViewer
        open={openImage}
        onClose={() => setOpenImage(false)}
        imageUrl={selectedImage}
      />
    </Card>
  );
};

export default ProductCard;
