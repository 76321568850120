import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Button, PasswordInput, useSnackbar } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getStaticTexts } from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import { login, resetPassword } from '../../../../services';
import { startLoader, stopLoader } from '../../../../utils';
import { SET_PROFILE_DETAILS, UPDATE_LOGIN_STATUS } from '../../../../redux';
import { claimItem } from '../../../../services/claim-item';
import { LocalStorageKeys } from '../../../../constants/AppConstants';
import { createItem } from '../../../../services/aggregation';

interface FormFields {
  prefix: string;
  password: string;
  number: string;
}

const LoginWithPassword = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      prefix: '',
      password: '',
      number: '',
    },
  });

  const enqueueSnackbar = useSnackbar();

  const handlePasswordLogin = async (data: FormFields) => {
    try {
      startLoader();
      const { password } = data;
      const storedUserData = sessionStorage.getItem('login-data');
      const claimItemData = sessionStorage.getItem('claim-item-data');
      const reportFoundItemData = sessionStorage.getItem('report-found-item-data');

      if (storedUserData) {
        const phone = JSON.parse(storedUserData) || {};

        const payload = {
          phone: phone.phone,
          password,
          type: 'INDIVIDUAL',
          loginWithOtp: false,
        };

        const response = (await login(payload)) as any;
        sessionStorage.removeItem('login-data');
        localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, response.data.token);
        localStorage.setItem(LocalStorageKeys.USER_TYPE, response.data.user.type);

        if (claimItemData) {
          const payload = JSON.parse(claimItemData) || {};
          await claimItem(payload);
          sessionStorage.removeItem('claim-item-data');
          enqueueSnackbar(staticTexts.itemClaimedSuccessfully);
        }

        if (reportFoundItemData) {
          const payload = JSON.parse(reportFoundItemData) || {};
          await createItem(payload);
          sessionStorage.removeItem('report-found-item-data');
          enqueueSnackbar(staticTexts.itemPostedSuccessfully);
        }

        dispatch({
          type: UPDATE_LOGIN_STATUS,
          payload: {
            isLoggedIn: true,
          },
        });
        // Dismiss Loading
        stopLoader();

        const user = response.data.user

        // Store Default Language
        localStorage.setItem(LocalStorageKeys.LANGUAGE, user.defaultLanguage);

        // Set user details in the redux store
        dispatch({
          type: SET_PROFILE_DETAILS,
          payload: {
            _id: user?._id,
            name: user?.name,
            email: user?.email,
            profile: user?.profile,
            phone: user?.phone,
            gender: user?.gender,
            dob: user?.dob,
            address: user?.address,
            description: user?.description,
            coverProfile: user?.coverProfile,
            type: user?.type,
            defaultLanguage: user?.defaultLanguage,
          },
        });

        // Navigate to Home
        navigate('/home');
      }
    } catch (error: any) {
      stopLoader();
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    }
  };

  const handleForgotPassword = async () => {
    try {
      startLoader();
      const storedUserData = sessionStorage.getItem('login-data');
      if (!storedUserData) {
        enqueueSnackbar(staticTexts.somethingWentWrong);
        return;
      }
      const phone = JSON.parse(storedUserData) || {};
      await resetPassword({
        phone: phone.phone,
        type: 'INDIVIDUAL',
      });
      enqueueSnackbar(staticTexts.weWillSendAnOTP);
      navigate('/auth/forgot-password');
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  const sendOTP = async () => {
    try {
      const loginData = sessionStorage.getItem('login-data');
      if (loginData) {
        const { phone: { prefix, number } } = JSON.parse(loginData) || {};
        await login({
          phone: {
            prefix: prefix.replace('+', ''),
            number,
          },
          type: 'INDIVIDUAL',
          loginWithOtp: true,
        });
        navigate('/auth/login/otp');
      }
    } catch (error) {
      enqueueSnackbar((error as any)?.message || staticTexts.somethingWentWrong);
    }
  };

  return (
    <form onSubmit={handleSubmit(handlePasswordLogin)}>
      <Box
        className='flex flex-col items-center rounded-md'
        marginTop='45px'
      >
        <PasswordInput
          name='password'
          label={staticTexts.password}
          className='!mb-1'
          control={control}
          error={errors.password}
          validationRules={{
            required: `${staticTexts.password} ${staticTexts.isRequired}`,
            minLength: {
              value: 6,
              message: staticTexts.passwordMustBeAtleast6char,
            },
          }}
        />

        <Typography
          className='text-accent w-full text-xs cursor-pointer'
          fontSize='14px'
          marginTop='8px'
          textAlign='right'
          onClick={handleForgotPassword}
        >
          {staticTexts.forgotPassword}
        </Typography>

        <Typography
          width='100%'
          fontSize='12px'
          color='#4A403A'
          marginTop='18px'
          textAlign='left'
        >
          {staticTexts.preferLoggingInWithCode}{' '}
          <span
            className='underline text-accent cursor-pointer'
            onClick={sendOTP}
          >
            {staticTexts.useOTPInstead}
          </span>
        </Typography>

        <Button
          type='submit'
          className='px-6 py-2 rounded-lg w-fit text-center text-sm'
          buttonStyle={{ marginTop: '40px' }}
        >
          {staticTexts.loginWithPassword}
        </Button>
      </Box>
    </form>
  );
};

export default LoginWithPassword;
