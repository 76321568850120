import {
  SET_SEARCH_PRODUCTS,
  RESET_SEARCH_PRODUCTS,
  RESET_SEARCH_FILTERS,
  SET_APPLIED_SEARCH_FILTERS,
  SET_DATE_FILTERS,
  SET_SORT_FILTERS,
  SET_PAGE,
  RESET_DATE_FILTER,
  SET_MOBILE_SEARCH_FILTERS,
} from '../../actions';

const initialState: any[] = [];
const initialSearchFilterState: any = {
  category: '',
  location: {
    latitude: '',
    longitude: '',
  },
  text: '',
  dateFilter: {
    startDate: '',
    endDate: '',
  },
  sortFilter: '',
  page: 1,
};

const initialMobileSearchFilterState = {
  sortBy: 'newest',
  viewMode: 'grid',
  dateRange: 'today',
  customDate: { from: '', to: '' },
}

export const searchProductReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SEARCH_PRODUCTS:
      return action.payload;
    case RESET_SEARCH_PRODUCTS:
      return initialState;
    default:
      return state;
  }
};

export const searchFilterReducer = (state = initialSearchFilterState, action: any) => {
  switch (action.type) {
    case SET_APPLIED_SEARCH_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DATE_FILTERS:
      return {
        ...state,
        dateFilter: {
          ...action.payload,
        },
      };
    case SET_SORT_FILTERS:
      return {
        ...state,
        sortFilter: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload
      }
    case RESET_SEARCH_FILTERS:
      return initialSearchFilterState;
    case RESET_DATE_FILTER:
      return {
        ...state,
        dateFilter: {
          startDate: '',
          endDate: '',
        },
      }
    default:
      return state;
  }
};


export const mobileSearchFilterReducer = (state = initialMobileSearchFilterState, action: any) => {
   switch (action.type) {
    case SET_MOBILE_SEARCH_FILTERS:
      return action.payload;
    default:
      return state;
   }
} 