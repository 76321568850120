import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import {
  StyledContainer,
  Section,
  SectionTitle,
  SectionHeader,
  FaqWrapper,
  LeadText,
  IndentedText,
} from '../Static.styles';
export const Help = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const faqs = [
    {
      question: staticTexts.faqQuestion1,
      answer: staticTexts.answer1,
    },
    {
      question: staticTexts.faqQuestion2,
      answer: staticTexts.answer2,
    },
    {
      question: staticTexts.faqQuestion3,
      answer: staticTexts.answer3,
    },
    {
      question: staticTexts.faqQuestion4,
      answer: staticTexts.answer4,
    },
    {
      question: staticTexts.faqQuestion5,
      answer: staticTexts.answer5,
    },
  ];

  const guidelines = [
    { title: staticTexts.step1, description: staticTexts.step1Description },
    { title: staticTexts.step2, description: staticTexts.step2Description },
    { title: staticTexts.step3, description: staticTexts.step3Description },
    { title: staticTexts.step4, description: staticTexts.step4Description },
    { title: staticTexts.step5, description: staticTexts.step5Description },
    { title: staticTexts.step6, description: staticTexts.step6Description },
  ];

  return (
    <StyledContainer maxWidth='md'>
      {/* Header Section */}
      <Section>
        <SectionHeader variant='h4'>{staticTexts.help}</SectionHeader>
      </Section>

      {/* Content Section */}
      {/* Frequently Asked Questions */}
      <Section>
        <SectionTitle variant='h5'>{staticTexts.frequentlyAskedQuestions}</SectionTitle>
      </Section>

      <FaqWrapper>
        {faqs.map((faqQuestion, index) => (
          <Fragment key={index}>
            <LeadText>
              <b>{staticTexts.question}</b>: {faqQuestion.question} <br />
            </LeadText>
            <IndentedText>
              <b>{staticTexts.answer}</b>: {faqQuestion.answer} <br />
            </IndentedText>
          </Fragment>
        ))}
      </FaqWrapper>

      {/* Usage Guidelines */}
      <SectionTitle marginTop='1rem'>{staticTexts.usageGuidelines}</SectionTitle>
      {guidelines.map((guideline, index) => (
        <Fragment key={index}>
          <LeadText>
            <b>{guideline.title}</b> <br />
          </LeadText>
          <IndentedText>
            {guideline.description} <br />
          </IndentedText>
        </Fragment>
      ))}
    </StyledContainer>
  );
};
