import { Fragment, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { ControlToolbar } from '../../../components';
import ProductCard from '../../../components/Layouts/ProductCard';
import ProductList from '../../../components/Layouts/ProductList';
import { useDispatch, useSelector } from 'react-redux';
import NoItems from '../../../components/Layouts/NoItems';
import { SET_DATE_FILTERS, SET_SORT_FILTERS } from '../../../redux';
import CustomButton from '../../../components/Button/CustomButton';
import { getStaticTexts } from '../../../assets';
import { AppButtonVariant } from '../../../constants/AppConstants';
import StringFormatting from '../../../utils/extensions/StringFormatting';

/**
 * The SearchItems component renders a search result page with a filter drawer.
 * It fetches products from the API based on the search filters and displays them
 * in a grid or list view. The user can also sort the products by date or name.
 * The component also handles pagination and shows a "Show More" button at the
 * bottom of the page if there are more products to be loaded.
 *
 * @returns {JSX.Element} The SearchItems component.
 */
const SearchItems = () => {
  // Get Translations
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 10,
  });

  const [viewType, setViewType] = useState('Grid');
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const products = useSelector((state: any) => state.searchProducts);
  const searchFilter = useSelector((state: any) => state.searchFilters);
  const dispatch = useDispatch();

  /**
   * Updates the view type state with the given value.
   *
   * @param value - The new view type to be set.
   */

  const handleViewTypeChange = (value: string) => {
    setViewType(value);
  };

  /**
   * Updates the date filter settings with the given values.
   *
   * @param filters - An object containing date filter settings, with keys
   *                  'from' and 'to' for the start and end dates of the
   *                  selected date range.
   */
  const handleDateFilter = (filters: any) => {
    const {
      date: { from, to },
    } = filters || {};
    dispatch({
      type: SET_DATE_FILTERS,
      payload: {
        startDate: from,
        endDate: to,
      },
    });
  };

  /**
   * Handles the application of mobile sorting settings by updating the sort
   * filter in the Redux store.
   *
   * @param filters - An object containing sort filter settings, with a key
   *                  'sort' containing an object with a 'value' key for the
   *                  selected sort value.
   */
  const handleSorting = (filters: any) => {
    const {
      sort: { value },
    } = filters || {};
    dispatch({
      type: SET_SORT_FILTERS,
      payload: value,
    });
  };

  return (
    <Box className='w-full mb-20'>
      <Box
        className='mx-auto'
        width={{
          xs: '92%',
          md: '80%',
        }}
      >
        <ControlToolbar
          selectedTab={`${StringFormatting.capitalizeFirstLetterOfEveryWord(searchFilter.categoryName) ?? ''}`}
          viewType={viewType}
          onViewChange={handleViewTypeChange}
          itemCount={products.length}
          showButton={false}
          handleFilters={() => setOpen(true)}
          handleDateFilter={handleDateFilter}
          handleSorting={handleSorting}
          onSearch={(event: any) => {}}
        />
        {products?.length > 0 ? (
          <Fragment>
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
              width='100%'
            >
              {viewType === 'List' ? (
                products?.map((product: any, index: number) => (
                  <div
                    className='mb-2'
                    key={index}
                  >
                    <ProductList
                      title={product?.name}
                      itemId={product?._id}
                      description={product?.description}
                      location={product?.location?.name}
                      visibility={product?.visibleToEveryone}
                      date={product?.eventDate}
                      imageUrl={product?.images[0]}
                      showActions={false}
                      showClaimButton={true}
                      showMoreOptions={false}
                    />
                  </div>
                ))
              ) : (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr 1fr',
                      sm: '1fr 1fr 1fr 1fr',
                      lg: '1fr 1fr 1fr 1fr 1fr',
                    },
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  {products?.map((product: any, index: number) => (
                    <ProductCard
                      key={index}
                      itemId={product._id}
                      images={product.images}
                      itemCategory={product?._category?.name}
                      itemName={product.name}
                      description={product.description}
                      location={product?.location?.name}
                      date={product.eventDate}
                      type={product.type}
                      showActions={false}
                      showPrivacy={false}
                      showClaimButton={true}
                    />
                  ))}
                </Box>
              )}

              {meta.page < meta.totalPages && (
                <div className='flex justify-center mt-4'>
                  <CustomButton
                    variant={AppButtonVariant.OUTLINED}
                    buttonStyle={{ backgroundColor: '#FFF7E6', borderColor: '#FFF7E6' }}
                    textColor='#4A403A'
                    title={staticTexts.showMoreItems}
                    onPress={() => {
                      setPage(page + 1);
                    }}
                  />
                </div>
              )}
            </Box>
          </Fragment>
        ) : (
          <NoItems />
        )}
      </Box>
    </Box>
  );
};

export default SearchItems;
