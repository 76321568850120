import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import { ControlToolbar, CustomToggle, Modal, useSnackbar } from '../../../../components';
import { getStaticTexts } from '../../../../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductCard from '../../../../components/Layouts/ProductCard';
import { useSelector } from 'react-redux';
import ProductList from '../../../../components/Layouts/ProductList';
import { Dialog } from '../../../../components/Dialog';
import { deleteItem, getItemAnalytics, getItems, resolveItemSelf } from '../../../../services/aggregation';
import { formatDate, startLoader, stopLoader } from '../../../../utils';
import CustomButton from '../../../../components/Button/CustomButton';
import { AppButtonVariant } from '../../../../constants/AppConstants';
import NoItems from '../../../../components/Layouts/NoItems';
import { Product } from '../../../../interfaces';
import FilterDrawer from '../../../../components/Layouts/FilterDrawer';
import { ExpandedSectionsType } from '../../../homepage/search-items/mobile-view';
import CustomPagination from '../../../../components/Pagination';

/**
 * A reusable component for displaying a title, count, and description.
 * @param {{ title: string, description: string, count: any }} props
 * @returns {JSX.Element}
 */
const Overview = (props: { title: string; description: string; count: any }) => {
  return (
    <Box
      sx={{
        textAlign: 'left',
        width: { xs: '100%', md: 'auto' },
      }}
    >
      <Typography
        component='p'
        fontSize={{ xs: '12px', md: '14px' }}
        letterSpacing='0px'
        color='#655B53FA'
      >
        {props.title}
      </Typography>

      <Typography
        component='p'
        marginTop='16px'
        fontSize={{ xs: '20px', md: '26px' }} // Adjust font size
        letterSpacing='0.01px'
        color='#FF6F61'
      >
        {props.count || 0}
      </Typography>

      <Typography
        color='#776E65'
        fontSize={{ xs: '10px', md: '12px' }} // Adjust font size
        marginTop='8px'
        marginRight={{ xs: '0px', md: '8px' }} // Adjust margin for small screens
      >
        {props.description}
      </Typography>
    </Box>
  );
};

/**
 * A page for business users to manage their items.
 *
 * This page displays a tabbed list of items, with separate tabs for found items and resolved items.
 * Each item is displayed as a card with its name, description, location, and photos.
 * The user can edit or delete an item, or report that they have found its owner.
 * The page also displays an overview of the total number of found items, resolved items, active listings, and pending claims.
 */
export const BusinessManageItems = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [selected, setSelected] = useState<ReturnType<typeof getStaticTexts>[keyof ReturnType<typeof getStaticTexts>]>(
    staticTexts.foundItems
  );
  const location = useLocation();
  const { showOverview } = location.state || {};
  const [view, setView] = React.useState(staticTexts.list.toLowerCase());
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => setOpenDialog(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openResolveItemModal, setOpenResolveItemModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilters] = useState({
    viewMode: view,
  });
  const [expandedSections, setExpandedSections] = useState<Partial<ExpandedSectionsType>>({
    viewMode: false,
  });

  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 20,
  });
  const [searchText, setSearchText] = useState('');

  /**
   * Close the product card modal and reset the product state.
   *
   * This function is called when the user clicks the close button on the modal.
   */
  const handleClose = () => {
    setIsOpen(false);
    setProduct(null);
  };

  const enqueueSnackbar = useSnackbar();
  const [product, setProduct] = useState<any>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [analytics, setAnalytics] = useState<any>(null);

  /**
   * Handles the confirmation action for deleting an item.
   *
   * This function initiates a loading state, attempts to delete the selected item,
   * displays a success message upon successful deletion, and fetches updated items.
   * If an error occurs during the deletion process, an error message is displayed.
   * Finally, the modal is closed and the loading state is stopped.
   */
  const handleConfirm = async () => {
    try {
      startLoader();
      await deleteItem([product?._id]);
      enqueueSnackbar(staticTexts.itemDeletedSuccessfully);
      fetchItems();
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      setIsOpen(false);
      stopLoader();
    }
  };

  /**
   * Returns the selected tab's value.
   * @returns {string} The selected tab's value, either "FOUND" or "RESOLVED".
   */
  const getSelectedTab = () => {
    switch (selected) {
      case staticTexts.foundItems:
        return 'FOUND';
      case staticTexts.resolvedItems:
        return 'RESOLVED';
      default:
        return '';
    }
  };

  /**
   * Resolves a selected found item by the business.
   *
   * This function initiates a loading state, attempts to resolve the selected item,
   * displays a success message upon successful resolution, and fetches updated items.
   * If an error occurs during the resolution process, an error message is displayed.
   * Finally, the modal is closed and the loading state is stopped.
   */
  const onResolve = async () => {
    try {
      startLoader();
      await resolveItemSelf(product._id);
      fetchItems();
      enqueueSnackbar(staticTexts.itemResolvedSuccessfully);
    } catch (error: any) {
      enqueueSnackbar(error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
      setProduct(null);
      setOpenResolveItemModal(false);
    }
  };

  /**
   * Fetches item analytics data from the server and updates the state.
   *
   * This function initiates a loading state, calls the getItemAnalytics service to
   * retrieve item analytics data, and updates the analytics state with the response.
   * If an error occurs during the fetch operation, an error message is displayed.
   * Finally, the loading state is stopped.
   */

  const fetchItemAnalytics = async () => {
    try {
      startLoader();
      const response = await getItemAnalytics();
      setAnalytics(response);
    } catch (error: any) {
      enqueueSnackbar(error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  /**
   * Fetches items of a given type and status from the server, and updates the state.
   *
   * This function initiates a loading state, calls the getItems service with the
   * specified parameters, and updates the products state with the response.
   * If an error occurs during the fetch operation, an error message is displayed.
   * Finally, the loading state is stopped.
   *
   * @param {number} page - The page number of the items to fetch.
   * @param {number} limit - The limit of items to fetch in each page.
   * @param {string} type - The type of items to fetch, either "FOUND" or "RESOLVED".
   * @param {string} status - The status of items to fetch, either "ACTIVE" or "RESOLVED".
   * @param {string} text - The search text to filter items with.
   */
  const fetchItems = async () => {
    startLoader();
    try {
      const params: any = {
        page: page,
        limit: meta.limit,
      };

      if (searchText) {
        params.text = searchText;
      }

      const type = getSelectedTab();

      switch (type) {
        case 'FOUND':
          params.type = 'FOUND';
          params.status = 'ACTIVE';
          break;
        case 'RESOLVED':
          params.status = 'RESOLVED';
          break;
        default:
          break;
      }

      if (!params.type && !params.status) {
        console.log('Warning: Missing type or status in params');
      }

      const response = (await getItems(params)) as any;
      setProducts(response?.items);
      setMeta({
        totalPages: response?.meta?.totalPages || 0,
        page: response?.meta?.page || 1,
        limit: response?.meta?.limit || 20,
      });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    fetchItems();
  }, [page, selected, searchText]);

  useEffect(() => {
    fetchItemAnalytics();
  }, []);

  /**
   * Handles the toggle event on the item type tabs.
   *
   * Updates the selected state with the option value.
   *
   * @param {string} option - The selected item type, either "FOUND" or "RESOLVED".
   */
  const handleToggle = (option: string) => {
    setSelected(option);
  };

  /**
   * Updates the view state with the provided view type.
   *
   * @param {string} value - The new view type to be set.
   */

  const handleViewTypeChange = (value: string) => {
    setView(value);
  };

  const handleViewModeChange = (event: any) => {
    setFilters({
      ...filter,
      viewMode: event.target.value,
    });
  };

  const handleClearAll = () => {
    setFilters({
      viewMode: 'grid',
    });

    toggleDrawer(false);
  };

  /**
   * Handles the search input key down event.
   *
   * If the pressed key is the Enter key, the search text state is updated with the value of the input element.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event.
   */
  const handleSearch = (event: any) => {
    if (event.key === 'Enter') {
      setSearchText(event.target.value);
    }
  };

  const toggleDrawer = (state: boolean) => {
    setOpenFilter(state);
    setExpandedSections({
      viewMode: false,
      dateRange: false,
    });
  };

  const handleFilter = () => {
    setView(filter.viewMode);
    toggleDrawer(false);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    fetchItems();
  };
  
  return (
    <Fragment>
      {showOverview && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          minWidth='384px'
        >
          {/* Top Header Container */}
          <Box
            className='w-full flex justify-between bg-grey-500 '
            sx={{ width: { xs: '90%', md: '70%' }, marginTop: { xs: 2.5, md: 6 }, marginBottom: { xs: 2.5, md: 6 } }}
          >
            {/* Overview Text */}
            <Typography
              textAlign='left'
              component='p'
              className='text-primary_text font-bold'
              fontSize={{ xs: '26px', md: '26px' }}
            >
              {staticTexts.overview}
            </Typography>

            {/* View Business Page Button */}
            <CustomButton
              variant={AppButtonVariant.OUTLINED}
              title={staticTexts.viewBusiness}
              onPress={() => {
                navigate('/organization-page');
              }}
            />
          </Box>

          {/* Overview Container */}
          <Box
            sx={{
              bgcolor: '#FFF7E6',
              boxShadow: '0px 1px 1px #0000000D',
              borderRadius: '8px',
              p: { xs: '16px', md: '24px' },
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: '16px',
              width: { xs: '90%', md: '70%' },
              alignItems: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Overview
              title={staticTexts.totalFoundItems}
              description={staticTexts.totalNumberOfItemThatHaveBeenFound}
              count={analytics?.totalFoundItems}
            />
            <Overview
              title={staticTexts.resolvedItems}
              description={staticTexts.totalNumberOfItemThatHaveBeenResolved}
              count={analytics?.totalResolvedItems}
            />
            <Overview
              title={staticTexts.activeListing}
              description={staticTexts.itemVisibleToPublicForClaim}
              count={analytics?.activeListings}
            />
            <Overview
              title={staticTexts.pendingClaims}
              description={staticTexts.itemThatHaveBeenRequestedForClaim}
              count={analytics?.claimItemsCount}
            />
          </Box>
        </Box>
      )}

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        className='pb-20'
      >
        {/* Manage Items Heading */}
        <Typography
          textAlign='left'
          component='p'
          width={{ xs: '90%', md: '70%' }}
          className='text-primary_text font-bold'
          padding={0}
          sx={{ marginTop: { xs: 2.5, md: 5 }, marginBottom: { xs: 2.5, md: 5 } }}
          fontSize={{ xs: '26px', md: '26px' }}
        >
          {staticTexts.manageItems}
        </Typography>

        <Box
          width={{ xs: '90%', md: '70%' }}
          display='flex'
          justifyContent='start'
          alignItems='center'
          borderRadius='8px'
          flexDirection='column'
          boxShadow='0px 1px 1px #0000000D'
          overflow='scroll'
          padding={{ xs: '16px', md: '24px' }}
          className='bg-FFF7E6'
        >
          {/* Found/Resolved Items Tabs */}
          <CustomToggle
            selected={selected}
            options={[
              { key: staticTexts.foundItems, labelKey: 'foundItems' },
              { key: staticTexts.resolvedItems, labelKey: 'resolvedItems' },
            ]}
            handleToggle={handleToggle}
          />

          {/* Found/Lost Items Title, Report Found Item Button Container */}
          <Box
            display='flex'
            flexDirection={{
              xs: 'row',
              md: 'row',
            }}
            justifyContent={{
              xs: 'space-between',
              md: 'space-between',
            }}
            alignItems='center'
            className='w-full'
            marginTop={{ xs: '20px', md: '26px' }}
          >
            {/* Found/Lost Items Title */}
            <Typography
              component='p'
              className='flex-grow'
              letterSpacing='0.01px'
              color='#4A403A'
              textAlign={{
                xs: 'left',
                md: 'left',
              }}
              fontSize={{ xs: '18px', md: '22px' }}
            >
              {selected}
            </Typography>

            {/* Report Found Item Button */}
            <CustomButton
              onPress={() => {
                navigate('/report-found-item');
              }}
              title={staticTexts.reportFoundItem}
            />
          </Box>

          {/* Search Bar Component */}
          <Box
            className='w-full'
            marginTop='16px'
          >
            <ControlToolbar
              viewType={view}
              onViewChange={handleViewTypeChange}
              itemCount={products?.length}
              showButton={false}
              showSearchInput
              searchValue={searchText}
              onSearch={handleSearch}
              handleFilters={() => setOpenFilter(true)}
              padding='0'
            />
          </Box>

          {/* List Container */}
          {products?.length > 0 ? (
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
              marginTop='18px'
              width='100%'
            >
              {view.toLowerCase() === staticTexts.list.toLowerCase() ? (
                (products || []).map((product, index) => (
                  <ProductList
                    key={index}
                    title={product?.name}
                    description={product?.description}
                    location={product?.location?.name}
                    visibility={product?.visibleToEveryone}
                    date={product?.eventDate}
                    imageUrl={product?.images[0]}
                    showResolvedTag={selected === staticTexts.resolvedItems}
                    menuOptions={[
                      {
                        label: staticTexts.view,
                        onClick: () => {
                          setOpenDialog(true);
                          setProduct(product);
                        },
                      },
                      ...(getSelectedTab() === 'FOUND'
                        ? [
                            {
                              label: staticTexts.iHaveFoundOwner,
                              onClick: () => {
                                setProduct(product);
                                setOpenResolveItemModal(true);
                              },
                            },
                          ]
                        : []),
                    ]}
                    showEdit={selected !== staticTexts.resolvedItems}
                    onDelete={() => {
                      setIsOpen(true);
                      setProduct(product);
                    }}
                    onEdit={() => navigate('/report-found-item', { state: product })}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr 1fr',
                      sm: '1fr 1fr 1fr',
                      lg: '1fr 1fr 1fr 1fr',
                    },
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  {(products || []).map((product, index) => (
                    <ProductCard
                      key={index}
                      image={product?.images[0]}
                      images={product?.images}
                      itemCategory={product?._category?.name}
                      itemName={product?.name}
                      description={product?.description}
                      location={product?.location?.name}
                      date={product?.createdAt}
                      type={product?.visibleToEveryone ? staticTexts.public : staticTexts.private}
                      onEdit={() => navigate('/report-found-item', { state: product })}
                      onDelete={() => {
                        setIsOpen(true);
                        setProduct(product);
                      }}
                      showEditButton={selected !== staticTexts.resolvedItems}
                      menuOptions={[
                        {
                          label: staticTexts.view,
                          onClick: () => {
                            setOpenDialog(true);
                            setProduct(product);
                          },
                        },
                        ...(getSelectedTab() === 'FOUND'
                          ? [
                              {
                                label: staticTexts.iHaveFoundOwner,
                                onClick: () => {
                                  setProduct(product);
                                  setOpenResolveItemModal(true);
                                },
                              },
                            ]
                          : []),
                      ]}
                    />
                  ))}
                </Box>
              )}
            </Box>
          ) : (
            <NoItems />
          )}
        </Box>

        {meta.totalPages > 1 && (
          <CustomPagination
            count={meta.totalPages}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        title={`${selected} ${staticTexts.details}`}
        maxWidth='lg'
        titlePadding='16px 24px'
        contentPadding='0px 24px'
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Typography
              variant='h6'
              fontSize='16px'
              color='#4A403A'
              gutterBottom
            >
              {staticTexts.itemDetails}
            </Typography>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.name}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ textTransform: 'capitalize' }}
                >
                  {product?.name}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.description}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {product?.description}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.location}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {product?.location?.name}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.dateFound}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                >
                  {formatDate(product?.createdAt)}
                </Typography>
              </Box>
            </Grid2>

            {/* Uploaded Images Section */}
            <Box mt={4}>
              <Typography
                variant='h6'
                fontSize='16px'
                color='#4A403A'
                gutterBottom
              >
                {staticTexts.uploadImages}
              </Typography>
              <Box
                display='flex'
                gap='8px'
                paddingBottom='24px'
              >
                {(product?.images || []).map((image: string, index: number) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Item ${index + 1}`}
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '8px',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        }
      />
      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={() => handleConfirm()}
        confirmText={staticTexts.delete}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
        cancelButtonStyle={{ color: '#4A403A', border: '1px solid #C9C2B4' }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.deleteItem}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.deleteItemWarning}
        </Typography>
      </Modal>

      <Modal
        open={openResolveItemModal}
        onClose={() => setOpenResolveItemModal(false)}
        onConfirm={onResolve}
        confirmText={staticTexts.confirm}
        cancelText={staticTexts.close}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.areYouSureYouWantToResolveThisItem}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.yourItemWillBeResolved}
        </Typography>
      </Modal>

      <FilterDrawer
        filters={filter}
        expandedSections={expandedSections}
        showCategoryFilter={false}
        setExpandedSections={setExpandedSections}
        onApply={handleFilter}
        isOpen={openFilter}
        toggleDrawer={toggleDrawer}
        handleClearAll={handleClearAll}
        handleClose={handleClose}
        showDateFilter={false}
        showSortFilter={false}
        handleViewModeChange={handleViewModeChange}
      />
    </Fragment>
  );
};
