import moment from 'moment';
import store from '../../redux/store/index';
import { UPDATE_LOADER } from '../../redux';
import axios from 'axios';
import { LocalStorageKeys } from '../../constants/AppConstants';

export const clipText = (text: string, maxLength: number = 20) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

export const formatDate = (dateString: string) => {
  return moment(dateString).format('DD MMM YYYY');
};

export const transformImages = (imageUrls: Array<string> = []) => {
  return (imageUrls || []).map((url, index) => ({
    src: url,
    alt: `img-${index + 1}`,
  }));
};

export const handleUseCurrentLocation = async (): Promise<{
  locationName: string | null;
  latitude: number | null;
  longitude: number | null;
}> => {
  if (navigator.geolocation) {
    try {
      // Show Loading
      startLoader();

      const position = await new Promise<GeolocationPosition>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );

      const { latitude, longitude } = position.coords;

      // Google Maps Geocoding API URL with your API key
      const apiKey = String(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      // Fetch location using axios and await the response
      const response = await axios.get(apiUrl);
      const addressComponents = response?.data?.results[0]?.address_components;

      // Extract desired details
      const locality =
        addressComponents?.find((component: any) => component.types.includes('locality'))?.long_name || '';

      const adminLevel3 =
        addressComponents?.find((component: any) => component.types.includes('administrative_area_level_3'))
          ?.long_name || '';

      const adminLevel1 =
        addressComponents?.find((component: any) => component.types.includes('administrative_area_level_1'))
          ?.long_name || '';

      const country = addressComponents?.find((component: any) => component.types.includes('country'))?.long_name || '';
      const locationName = `${locality}, ${adminLevel3}, ${adminLevel1}, ${country}`;

      return { locationName, latitude, longitude };
    } catch (error) {
      console.error('Error getting location or fetching location name:', error);
      return { locationName: null, latitude: null, longitude: null };
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  } else {
    console.error('Geolocation is not supported by this browser.');
    return { locationName: null, latitude: null, longitude: null };
  }
};

/**
 * Starts the global loader.
 *
 * @function startLoader
 */
export const startLoader = () => {
  store.dispatch({
    type: UPDATE_LOADER,
    payload: true,
  });
};

/**
 * Stops the global loader.
 *
 * @function stopLoader
 */
export const stopLoader = () => {
  store.dispatch({
    type: UPDATE_LOADER,
    payload: false,
  });
};

export const getUserType = () => {
  return (
    localStorage.getItem(LocalStorageKeys.USER_TYPE) || localStorage.getItem(LocalStorageKeys.IMPERSONATE_USER_TYPE)
  );
};

export const isAuthenticated = () => {
  return (
    !!localStorage.getItem(LocalStorageKeys.AUTH_TOKEN) || !!localStorage.getItem(LocalStorageKeys.IMPERSONATE_TOKEN)
  );
};

/**
 * Function to return file name from url
 * @param url file url
 */
export const getFileNameFromUrl = (url: string) => {
  if (!url) return null;

  try {
    // Use the URL object to parse the URL
    const parsedUrl = new URL(url);

    // Get the pathname and extract the file name
    const { pathname } = parsedUrl;
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);

    return fileName;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
