import React from 'react';
import { pageNotFound } from '../../assets/images';

export const PageNotFound: React.FC = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen w-100'>
      <div className='h-1/2 w-1/2 rounded-2xl'>
      <img
        className='h-full w-full object-cover rounded-2xl'
        src={pageNotFound}
        alt='Page Not Found'
        loading='lazy'
      />
      </div>
    </div>
  );
};
