import React, { useEffect, useState } from 'react';
import { Search, MyLocation } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dialog } from '../../Dialog';
import { GoogleMap } from '../../GoogleMap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import { textTransform } from '../../../services/common';
import { searchItems } from '../../../services/home';
import { SET_APPLIED_SEARCH_FILTERS, SET_SEARCH_PRODUCTS } from '../../../redux';
import { startLoader, stopLoader } from '../../../utils';
import { useSnackbar } from '../../Snackbar';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface SearchBarProps {
  handleFocus?: () => void;
}
export const SearchBar: React.FC<SearchBarProps> = ({ handleFocus }) => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  // Categories List
  const categories = useSelector((state: any) => [
    {
      id: '',
      name: staticTexts.all,
    },
    ...state.category,
  ]);

  const [state, setState] = useState({
    search: '',
    category: categories[0].name,
    isDialogOpen: false,
    categoryId: categories[0].id,
  });

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    description: string;
  } | null>(null);
  const [page, setPage] = useState(1);
  const searchFilter = useSelector((state: any) => state.searchFilters);
  const enqueueSnackbar = useSnackbar();

  const dispatch = useDispatch();

  const handleLocationSelect = (location: { lat: number; lng: number; description: string }) => {
    setSelectedLocation(location);
    closeDialog();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      search: event.target.value,
    }));
  };

  const openDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: true,
    }));
  };

  const closeDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: false,
    }));
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: any) => {
    setState((prev) => ({
      ...prev,
      category: textTransform(option.name),
      categoryId: option._id,
    }));
    handleMenuClose();
  };

  const handleSearch = async () => {
    try {
      startLoader();
      const { dateFilter, sortFilter } = searchFilter;
        const response = (await searchItems(
          {
            text: state.search,
            ...(state.categoryId && { categoryId: state.categoryId }),
            ...(selectedLocation?.lat &&
              selectedLocation.lng && {
                location: {
                  latitude: selectedLocation?.lat,
                  longitude: selectedLocation?.lng,
                },
              }),
              ...(searchFilter.category && { categoryId: searchFilter.category }),
              ...((searchFilter?.location?.lat && searchFilter?.location?.lng) && { location: searchFilter.location }),
              ...((dateFilter?.startDate && dateFilter?.endDate) ? { startDate: (dateFilter as any)?.startDate, endDate: (dateFilter as any)?.endDate } : {}),
              ...(sortFilter ? { sort: sortFilter } : {}),
          },
          {
            page,
          }
        )) as any;
        dispatch({
          type: SET_APPLIED_SEARCH_FILTERS,
          payload: {
            text: state.search,
            categoryId: state.categoryId,
            categoryName: state.category,
            ...(selectedLocation?.lat &&
              selectedLocation.lng && {
                location: {
                  latitude: selectedLocation?.lat,
                  longitude: selectedLocation?.lng,
                },
              }),
          },
        });
        dispatch({ type: SET_SEARCH_PRODUCTS, payload: response?.items || [] });
    } catch (error) {
      console.log('error', error);
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    const { sortFilter, dateFilter : {startDate = '' , endDate = '' } } = searchFilter || {};
    // if (sortFilter || (startDate && endDate )) {
      handleSearch();
    // }
  }, [searchFilter.sortFilter, searchFilter.dateFilter]);

  return (
    <div className='flex items-center justify-center rounded-lg flex-wrap bg-[#FFF7E6]'>
      <input
        type='text'
        placeholder={staticTexts.searchLostItems}
        value={state.search}
        onChange={handleInputChange}
        className='px-4 py-2 lg:rounded-l-lg rounded focus:outline-none bg-transparent custom-placeholder'
        onFocus={() => {
          isMobile && navigate('/search-items');
        }}
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            handleSearch();
          }
        }}
      />

      <div className='relative lg:flex items-center hidden'>
        <input
          type='text'
          value={selectedLocation?.description || ''}
          placeholder={staticTexts.location}
          className='px-4 py-2 pr-8 focus:outline-none bg-transparent custom-placeholder'
          readOnly
        />
        {!selectedLocation ? (
          <MyLocation
            sx={{ fontSize: '14px' }}
            className='absolute right-1 text-accent text-sm cursor-pointer'
            onClick={openDialog}
          />
        ) : (
          <HighlightOffIcon
            sx={{ fontSize: '14px' }}
            className='absolute right-1 text-accent text-sm cursor-pointer'
            onClick={() => setSelectedLocation(null)}
          />
        )}
      </div>

      <div className='relative px-1 lg:flex rounded-r-lg justify-center items-center'>
        <button
          className='lg:flex hidden items-center px-4 py-2 focus:outline-none text-[#776E65]'
          onClick={handleMenuOpen}
        >
          {state.category}
          <span className='ml-2'>
            <KeyboardArrowDownIcon />
          </span>
        </button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {(categories || []).map((option: any, index: number) => (
            <MenuItem
              key={option._id}
              onClick={() => handleOptionSelect(option)}
            >
              {textTransform(option.name)}
            </MenuItem>
          ))}
        </Menu>
        <button
         onClick={() => {
          handleSearch();
          if (!isMobile) {
            navigate('/home/search');
          } else {
            navigate('/search-items');
          }
        }}
          className='bg-[#EC2A3B] text-white px-4 py-2 rounded-full h-8 w-8 flex justify-center items-center'
        >
          <Search />
        </button>
      </div>

      <Dialog
        open={state.isDialogOpen}
        content={<GoogleMap onLocationSelect={handleLocationSelect} />}
        onClose={closeDialog}
      />
    </div>
  );
};
