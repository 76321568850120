import React, { Fragment, useEffect, useState } from 'react';
import ChatList from './chat-list';
import ChatWindow from './chat-window';
import { Box, Typography } from '@mui/material';
import { chatList, getMessages } from '../../services/chat';
import { useDispatch, useSelector } from 'react-redux';
import { FETCHING_CHATS, RESET_MESSAGES, SCROLL_TO_BOTTOM, SET_MESSAGES, UPDATE_CHAT_LIST } from '../../redux';
import { useLocation } from 'react-router-dom';
import { getStaticTexts } from '../../assets';
import { User } from '../../interfaces';
import { startLoader, stopLoader } from '../../utils';

/**
 * ChatScreen is a component that displays a chat interface with a chat list and
 * a chat window. The chat list displays all the users with whom the current user
 * has a chat. The chat window displays the messages of the selected chat. The user
 * can select a user from the chat list to view the messages of that chat. The user
 * can also search for a user in the chat list. The user can also view more chats
 * by clicking on the "Show more" button.
 *
 * @returns {ReactElement} - The chat interface component
 */
const ChatScreen: React.FC = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const userList = useSelector((state: any) => state?.chatlist?.chats);
  const { state } = useLocation();
  const { isFirstTime = false, _item = null, _receiver = null, username = '', userProfile = '' } = state || {};
  const messages = useSelector((state: any) => state?.messages || []);
  const [page, setPage] = useState(1);
  const [chatPage, setChatPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [search, setSearch] = useState('');
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * Fetches the list of chat users based on the current page and search query.
     *
     * Makes an asynchronous request to retrieve the chat list with pagination
     * and search functionality. On success, dispatches an action to update
     * the chat list in the state with the response data.
     *
     * Catches and logs any errors that occur during the fetching process.
     */
    const fetchChatList = async () => {
      try {
        startLoader();
        const response = await chatList({ page, limit: 10, text: search });
        dispatch({ type: UPDATE_CHAT_LIST, payload: response });
      } catch (error) {
        console.error('Error fetching chat list:', error);
      } finally {
        stopLoader();
      }
    };

    fetchChatList();
  }, [search, dispatch, page]);

  useEffect(() => {
    /**
     * Fetches the messages of the selected chat user.
     *
     * Resets the messages in the state and fetches the messages of the selected
     * chat user. If the user is the first time user, fetches the messages by making
     * an asynchronous request to the server. Dispatches an action to update
     * the messages in the state with the response data.
     */
    const fetchData = async () => {
      if (isFirstTime && _item && _receiver) {
        dispatch({ type: RESET_MESSAGES });
        const messages = await fetchMessages(_item, _receiver, 1, 500);
        dispatch({ type: SET_MESSAGES, payload: messages });
      }
    };

    fetchData();
  }, [isFirstTime, _item, _receiver]);

  const handleSearch = (value: string) => setSearch(value);
  const handleShowMore = () => setPage((prev) => prev + 1);

  /**
   * Fetches the messages of the selected chat user.
   *
   * Makes an asynchronous request to the server to fetch the messages of
   * the selected chat user. Dispatches an action to indicate that the fetching
   * of messages is in progress. On success, returns the messages in reverse
   * chronological order. Catches and logs any errors that occur during the
   * fetching process. Finally, dispatches an action to indicate that the
   * fetching of messages is complete.
   *
   * @param {string} itemId - The id of the item of the chat user.
   * @param {string} receiverId - The id of the receiver of the chat user.
   * @param {number} page - The page number of the messages to fetch.
   * @param {number} [limit=10] - The limit of messages to fetch in each page.
   * @returns {Promise<Array<any>>} - A promise that resolves with the messages.
   */
  const fetchMessages = async (itemId: string, receiverId: string, page: number, limit = 10) => {
    try {
      dispatch({
        type: FETCHING_CHATS,
        payload: true,
      });
      const response = (await getMessages({ itemId, receiverId, page, limit })) as any;
      const newMessages = response?.messages?.reverse();
      return newMessages;
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      dispatch({
        type: FETCHING_CHATS,
        payload: false,
      });
    }
  };

  /**
   * Handles the selection of a user from the chat list.
   *
   * Resets the message list and sets the scroll to bottom flag.
   * Calls the onSelectUser callback with the selected user.
   *
   * @param {User} user The selected user
   * @returns {Promise<void>} - A promise that resolves when the messages are fetched.
   */
  const handleSelectUser = async (user: any) => {
    setChatPage(1);
    setHasMoreMessages(true);
    const { _receiver, _item } = user || {};
    setSelectedUser(user);
    dispatch({ type: RESET_MESSAGES });
    const messages = await fetchMessages(_item, _receiver, 1);
    dispatch({ type: SET_MESSAGES, payload: messages });
  };

  /**
   * Fetches more messages from the server when the user reaches the bottom of the chat window.
   *
   * Increments the chat page number and fetches the messages from the server.
   * If the length of the fetched messages is 0, sets the hasMoreMessages flag to false.
   * Dispatches an action to set the messages by concatenating the new messages
   * with the existing messages. Finally, dispatches an action to set the scroll
   * to bottom flag to false.
   *
   * @returns {Promise<void>} - A promise that resolves when the messages are fetched.
   */
  const loadMoreMessages = async () => {
    let previousMessages;
    setChatPage((prev) => prev + 1);
    previousMessages = await fetchMessages(
      String(selectedUser?._item?._id),
      String(selectedUser?._receiver),
      chatPage + 1
    );
    if (previousMessages?.length === 0) setHasMoreMessages(false);
    const fetchedMessages = previousMessages || [];
    dispatch({
      type: SET_MESSAGES,
      payload: [...fetchedMessages, ...messages],
    });
    dispatch({
      type: SCROLL_TO_BOTTOM,
      payload: false,
    });
    return;
  };

  return (
    <Box
      className='h-screen block md:flex justify-center w-full'
      marginTop={{ xs: '0px', md: '32px' }}
      position='fixed'
    >
      <Box
        sx={{
          width: { xs: '100%', md: '75%' },
          height: { xs: '100%', md: '85%' },
        }}
        className={`block md:flex gap-2 ${!isFirstTime ? 'justify-between' : 'justify-center'} h-full md:h-4/5`}
      >
        {!isFirstTime ? (
          <Fragment>
            <Box
              sx={{ width: { xs: '100%', md: '35%' }, minWidth: { md: '300px' } }}
              className={`${
                selectedUser ? 'hidden md:block' : 'block'
              } rounded-lg bg-[#FFF7E6] h-full w-full md:w-1/3 border-r border-gray-200`}
            >
              <ChatList
                users={userList}
                onSelectUser={handleSelectUser}
                className='h-full'
                onSearchUser={handleSearch}
                onShowMore={handleShowMore}
              />
            </Box>

            <Box
              sx={{ width: { xs: '100%', md: '65%' }, height: { xs: '93%', md: '100%' } }}
              className={`${selectedUser ? 'block' : 'hidden md:block'} rounded-lg bg-[#FFF7E6]`}
            >
              {selectedUser ? (
                <ChatWindow
                  user={selectedUser}
                  userProfile={selectedUser?.receiverProfile}
                  onBack={() => setSelectedUser(null)}
                  className='h-full'
                  messages={messages}
                  hasMore={hasMoreMessages}
                  onLoadMore={loadMoreMessages}
                />
              ) : (
                <Box className='hidden md:flex items-center justify-center h-full'>
                  <Typography
                    component='p'
                    color='text.secondary'
                    className='text-gray-500'
                  >
                    {userList?.length > 0 ? staticTexts.selectAChatToStartMessaging : staticTexts.noChatsAvailable}
                  </Typography>
                </Box>
              )}
            </Box>
          </Fragment>
        ) : (
          <Box
            sx={{ width: '80%', height: { xs: '93%', md: '100%' } }}
            className={`${selectedUser ? 'block' : 'hidden md:block'} rounded-lg bg-[#FFF7E6]`}
          >
            <ChatWindow
              user={{ _item, _receiver }}
              username={username}
              userProfile={userProfile}
              onBack={() => setSelectedUser(null)}
              className='h-full'
              messages={messages}
              hasMore={hasMoreMessages}
              onLoadMore={loadMoreMessages}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChatScreen;
