import { SET_CATEGORY_DETAILS, UPDATE_CATEGORY_LANGUAGE } from '../../actions';

const initialState: any = [];

export const categoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
   
    case SET_CATEGORY_DETAILS:
      return action.payload.categories.map((category: any) => ({
        ...category,
        rawName: category.name,
        name: category.name?.[action.payload.language] || '',
      }));

    case UPDATE_CATEGORY_LANGUAGE:
      return state.map((category: any) => ({
        ...category,
        name: category.rawName?.[action.payload.language] || '',
      }));
  }
  return state;
};
