import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { getUserType, isAuthenticated } from '../../utils';


interface ProtectedRouteProps {
  children: ReactNode;
  allowedUserTypes: Array<string>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedUserTypes }) => {
  if (!isAuthenticated()) {
    // Redirect to login if not authenticated
    return <Navigate to="/auth/login" replace />;
  }

  const userType = getUserType()?.toLowerCase();
  if (!userType || !allowedUserTypes.includes(userType)) {
    // Redirect to a "Forbidden" page or homepage if user type is not allowed
    return <Navigate to="/home" replace />;
  }

  // Allow access if authenticated and user type is allowed
  return <>{children}</>;
};

export default ProtectedRoute;
