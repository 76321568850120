import { useSelector } from 'react-redux';
import { StyledContainer, SectionWrapper, Section, SectionTitle, SectionText, SectionHeader } from '../Static.styles';
import { getStaticTexts } from '../../../assets';

export const TermsAndConditions = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <StyledContainer maxWidth='md'>
      {/* Header Section */}
      <Section>
        <SectionHeader variant='h4'>{staticTexts.termsAndCondition}</SectionHeader>
      </Section>

      <SectionText fontWeight={800}>{staticTexts.disclaimer}</SectionText>
      {/* Content Section */}

      <SectionWrapper>
        {/* Use of application */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.useOfApplication}</SectionTitle>•{' '}
          {staticTexts.useOfApplicationPurpose} <br />• {staticTexts.useOfApplicationAccuracy} <br />•{' '}
          {staticTexts.useOfApplicationResponsibility}
        </Section>

        {/* Content Responsibility */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.contentResponsibility}</SectionTitle>• {staticTexts.contentOwnership}{' '}
          <br />• {staticTexts.contentAccuracy} <br />
        </Section>

        {/* Liability for Damages */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.liabilityForDamages}</SectionTitle>• {staticTexts.liabilityDisclaimer}{' '}
          <br />• {staticTexts.liabilityLossOrDamage} <br />
        </Section>

        {/* User Interaction */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.userInteractions}</SectionTitle>•{' '}
          {staticTexts.userTransactionResponsibility} <br />• {staticTexts.userDisputeResolution} <br />
        </Section>

        {/* Security Policies */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.securityPolicies}</SectionTitle>• {staticTexts.securityMeasures}{' '}
          <br />• {staticTexts.userAccountSecurity} <br />
        </Section>

        {/* Service Modification */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.serviceModifications}</SectionTitle>• {staticTexts.modificationRights}{' '}
          <br />• {staticTexts.modificationLiability} <br />
        </Section>

        {/* Applicable laws */}
        <Section>
          <SectionTitle variant='h5'>{staticTexts.applicableLaws}</SectionTitle>• {staticTexts.jurisdiction} <br />•{' '}
          {staticTexts.legalDisputeResolution} <br />
        </Section>

        <Section>
          <SectionTitle variant='h5'>{staticTexts.userAcceptance}</SectionTitle>• {staticTexts.userAcknowledgment}{' '}
          <br />
        </Section>
      </SectionWrapper>
    </StyledContainer>
  );
};
