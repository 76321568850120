import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header } from '../../components';
import { LocalStorageKeys, UserType } from '../../constants/AppConstants';
import { getUserProfile } from '../../services/common';
import { useDispatch } from 'react-redux';
import { SET_PROFILE_DETAILS } from '../../redux';
import { startLoader, stopLoader } from '../../utils';

/**
 * Layout component that serves as the base layout for the application.
 * It renders the Header, Footer, and any child components specified
 * by the Outlet. The Footer is conditionally rendered based on the
 * current pathname, and it is excluded on the 'chat' page.
 *
 * @returns {JSX.Element} The rendered layout component.
 */

export const Layout = () => {
  const [initialized, setIsInitialized] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeUser = async () => {
      startLoader();
      const urlParams = new URLSearchParams(window.location.search);
      const impersonateToken = urlParams.get('impersonateToken');
      const userType = urlParams.get('user-type');

      if (impersonateToken) {
        // Clear local storage and set impersonate data
        localStorage.clear();
        localStorage.setItem(LocalStorageKeys.IMPERSONATE_TOKEN, impersonateToken);
        if (userType) {
          localStorage.setItem(LocalStorageKeys.IMPERSONATE_USER_TYPE, userType);
        }

        try {
          const user = await getUserProfile() as any;
          if (user) {
            // Set default language in localStorage
            localStorage.setItem(LocalStorageKeys.LANGUAGE, user.defaultLanguage || '');

            // Dispatch user details to Redux
            dispatch({
              type: SET_PROFILE_DETAILS,
              payload: {
                _id: user._id,
                name: user.name,
                email: user.email,
                profile: user.profile,
                phone: user.phone,
                gender: user.gender,
                dob: user.dob,
                address: user.address,
                description: user.description,
                coverProfile: user.coverProfile,
                type: user.type,
                defaultLanguage: user.defaultLanguage,
              },
            });

            // Navigate based on user type
            const navigateTo = userType === UserType.BUSINESS ? '/organization-page' : '/home';
            navigate(navigateTo, { replace: true });
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      } 

     setIsInitialized(true);
     stopLoader();
    };

    // Call async function
    initializeUser();
  }, [dispatch]);

  if(!initialized) {
    return null
  };
  
  return (
    <>
      <Header />
      <div className='min-w-96'>
        <Outlet />
      </div>
      {initialized && !['chat'].includes(pathname.split('/').pop() || '') && <Footer />}
    </>
  );
};
