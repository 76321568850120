import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import {
  DateInput,
  Dropdown,
  Input,
  ImageUpload,
  LocationInput,
  Modal,
  ContentWrapper,
  useSnackbar,
} from '../../components';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';
import { createItem, getQuestionnaire, updateItem } from '../../services/aggregation';
import { startLoader, stopLoader } from '../../utils';
import CustomButton from '../../components/Button/CustomButton';
import { AppButtonVariant } from '../../constants/AppConstants';

/**
 * Page to report a lost item.
 * This page provides a form to input information about the lost item,
 * such as its name, description, location, and images.
 * The form also includes a section for the user to input answers to questionnaire
 * questions related to the lost item.
 *
 * @param {{_category: {name: string, _id: string}}} [state] - Optional state from the previous page.
 * @returns {JSX.Element} - JSX element containing the form.
 */
export const ReportLostItem = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const { state } = useLocation();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    itemName: string;
    category: { label: string; value: string };
    description: string;
    date: string;
    location: string;
    privacy: string;
    public: boolean;
    questions: { [key: string]: string };
  }>({
    defaultValues: {
      category: state?._category
        ? {
            label: state._category.name?.[language]?.charAt(0).toUpperCase() + state._category.name?.[language]?.slice(1).toLowerCase(),
            value: state._category._id,
          }
        : undefined,
    },
  });
  const enqueueSnackbar = useSnackbar();
  const [locationValue, setLocationValue] = useState({
    longitude: 0,
    latitude: 0,
    description: '',
  });
  const [images, setImages] = useState<string[]>([]);
  const [questions, setQuestions] = useState<any[]>([]);
  const staticTexts = getStaticTexts(language);
  const categories = useSelector((state: any) => state.category);
  const selectedCategory = watch('category');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (state) {
      setValue('itemName', state.name || '');
      setValue('description', state.description || '');
      setValue('date', state.eventDate || '');
      setValue('location', state.location?.name || '');
      setValue('public', state.visibleToEveryone || false);
      setImages(state.images || []);

      if (state.location) {
        setLocationValue({
          longitude: state.location.coordinates[0] || 0,
          latitude: state.location.coordinates[1] || 0,
          description: state.location.name || '',
        });
      }

      if (state.questions) {
        const questionValues: { [key: string]: string } = {};
        state.questions.forEach((q: any) => {
          questionValues[q._question._id] = q.value;
        });
        setValue('questions', questionValues);
      }
    }
  }, [state, setValue]);

  useEffect(() => {
    /**
     * Fetches the questions related to a lost item category.
     * @param {string} categoryId - The ID of the category to fetch questions for.
     * @returns {Promise<void>} - A promise that resolves when the questions have been fetched.
     */
    const fetchQuestions = async (categoryId: string) => {
      try {
        const questionData = (await getQuestionnaire('LOST', categoryId)) as any;
        setQuestions(questionData.questions);
      } catch (error: any) {
        enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
      }
    };

    if (selectedCategory) {
      fetchQuestions(selectedCategory.value);
    }
  }, [selectedCategory, enqueueSnackbar, staticTexts.somethingWentWrong]);

  /**
   * Handles the closing of the modal.
   * Reloading the window to reset the state and closes the modal.
   */
  const handleClose = () => {
    window.location.reload();
    setIsOpen(false);
  };

  /**
   * Handles the confirmation of a lost item submission.
   * Navigates to the '/my-items' page and closes the submission modal.
   */
  const handleConfirm = () => {
    navigate('/my-items');
    setIsOpen(false);
  };

  /**
   * Handles changes to the user's selected location.
   * @param {Object} location - An object containing the latitude, longitude, and description of the selected location.
   */
  const handleLocationValueChange = (location: { lat: number; lng: number; description: string }) => {
    setLocationValue({
      latitude: location.lat,
      longitude: location.lng,
      description: location.description || '',
    });
  };

  /**
   * Handles the user clicking on the "Use current location" button.
   * @param {Object} location - An object containing the latitude and longitude of the user's current location.
   * @param {String} error - An error message if there was an issue getting the user's location.
   */
  const handleCurrentLocationClick = (
    location: { latitude: number; longitude: number } | null,
    error: string | null
  ) => {
    if (error) {
      enqueueSnackbar(error);
      return;
    }

    if (location) {
      setLocationValue({
        latitude: location.latitude,
        longitude: location.longitude,
        description: '',
      });
      setValue('location', '');
    }
  };

  /**
   * Handles changes to the user's selected images.
   * @param {string[]} fileUrls - An array of URLs for the selected images.
   */
  const handleFileUrls = (fileUrls: string[]) => {
    setImages(fileUrls);
  };

  /**
   * Handles the creation of a lost item report.
   *
   * This function prepares a payload based on the provided data and sends a request to create a lost item.
   * It also manages the loading state and displays appropriate feedback messages based on the success or failure of the operation.
   *
   * @param {Object} data - The form data containing information about the lost item, including its name, images, category, description, location, date, visibility, and related questions.
   *
   * @returns {Promise<void>} - A promise that resolves when the item has been successfully created or rejects if there is an error.
   */
  const handleCreateItem = async (data: any) => {
    try {
      // Show Loading
      startLoader();

      // Prepare Request Payload
      const payload = {
        name: data.itemName,
        images: images,
        categoryId: data.category.value,
        description: data.description,
        location: {
          name: locationValue.description,
          coordinates: [locationValue.longitude, locationValue.latitude],
        },
        eventDate: new Date(data.date).toISOString(),
        visibleToEveryone: data.public,
        type: 'LOST',
        questions: Object.entries(data.questions || []).map(([id, answer]) => ({
          _question: id,
          value: answer,
        })),
      };
      // API Call: Report Found Item
      await createItem(payload);

      // Show Message Modal
      setIsOpen(true);
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };

  /**
   * Handles the update of an existing lost item report.
   *
   * This function prepares a payload based on the provided data and sends a request to update the lost item.
   * It also manages the loading state and displays appropriate feedback messages based on the success or failure of the operation.
   *
   * @param {Object} data - The form data containing information about the lost item, including its name, images, category, description, location, date, visibility, and related questions.
   *
   * @returns {Promise<void>} - A promise that resolves when the item has been successfully updated or rejects if there is an error.
   */
  const handleUpdateItem = async (data: any) => {
    try {
      const payload = {
        name: data.itemName,
        images: images,
        categoryId: data.category.value,
        description: data.description,
        location: {
          name: locationValue.description,
          type: 'Point',
          coordinates: [locationValue.longitude, locationValue.latitude],
        },
        eventDate: new Date(data.date).toISOString(),
        visibleToEveryone: data.public,
        type: 'LOST',
        questions: Object.entries(data.questions || []).map(([id, answer]) => ({
          _question: id,
          value: answer,
        })),
      };
      await updateItem(state._id, payload);
      enqueueSnackbar(staticTexts.itemUpdatedSuccessfully);
      navigate('/my-items');
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    }
  };

  /**
   * Handles the submission of the lost item report form.
   *
   * Based on the presence of an existing state, this function either updates
   * an existing lost item report or creates a new one. It manages error handling
   * and displays appropriate feedback messages.
   *
   * @param {Object} data - The form data containing information about the lost item.
   * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
   */

  const onSubmit = async (data: any) => {
    try {
      if (state) {
        await handleUpdateItem(data);
      } else {
        await handleCreateItem(data);
      }
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    }
  };

  return (
    <ContentWrapper title={staticTexts.whatHaveYouLost}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='itemName'
          className='!mb-6'
          label={staticTexts.itemNameWhatILost}
          placeholder={staticTexts.egWalletLaptopKeys}
          control={control}
          error={errors.itemName}
          isRequired
          onBlur={(e) => {
            if (!e.target.value) {
              setValue('itemName', '');
            }
          }}
        />

        <Dropdown
          name='category'
          dropdownStyle={{ marginBottom: '20px' }}
          control={control}
          options={(categories || []).map((category: any) => ({
            label: category.name.charAt(0).toUpperCase() + category.name.slice(1).toLowerCase(),
            value: category._id,
          }))}
          placeholderText={staticTexts.selectCategory}
          labelText={staticTexts.selectCategory}
          isRequired={true}
          error={
            errors.category?.message
              ? {
                  type: 'manual',
                  message: errors.category.message,
                }
              : undefined
          }
        />

        <label className='text-sm text-secondary-400'>{staticTexts.uploadImages}</label>
        <ImageUpload
          uploadedImages={images}
          onFileChange={handleFileUrls}
        />

        {/* Updated Image Preview Section with smaller sizes */}
        {/* {images.length > 0 && (
          <Box className='flex flex-wrap gap-2 my-4'>
            {(images || []).map((imageUrl, index) => (
              <div
                key={index}
                className='relative group'
              >
                <img
                  src={imageUrl}
                  alt={`Selected item ${index + 1}`}
                  className='w-12 h-12 object-cover rounded-full' // Changed to small, circular avatar size (48px × 48px)
                  style={{
                    width: '44px', // 40px + 10%
                    height: '44px', // 40px + 10%
                  }}
                />
                <button
                  type='button'
                  onClick={() => {
                    const newImages = [...images];
                    newImages.splice(index, 1);
                    setImages(newImages);
                  }}
                  className='absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity'
                  style={{ fontSize: '10px' }}
                >
                  ×
                </button>
              </div>
            ))}
          </Box>
        )} */}

        <Input
          name='description'
          label={staticTexts.lostDescription}
          className='!mb-6'
          placeholder={staticTexts.describeItemInDetail}
          fullWidth
          multiline
          rows={4}
          control={control}
          error={errors.description}
          isRequired
          onBlur={(e) => {
            if (!e.target.value) {
              setValue('description', '');
            }
          }}
        />

        <Controller
          name='location'
          control={control}
          rules={{
            required: staticTexts.locationIsRequired,
          }}
          render={({ field: { onChange, ...field } }) => (
            <LocationInput
              {...field}
              control={control}
              label={staticTexts.lostLocation}
              onCurrentLocationClick={handleCurrentLocationClick}
              handleLocationSelect={(location) => {
                handleLocationValueChange(location);
                onChange(location.description);
              }}
            />
          )}
        />

        <DateInput
          name='date'
          className='w-full'
          label={staticTexts.whenYouHaveLostIt}
          control={control}
          error={errors.date}
          helperText={errors.date?.message || ' '}
          validationRules={{
            required: staticTexts.dateIsRequired,
          }}
        />

        {(questions || []).map((question) => {
          const stateQuestion = state?.questions?.find((q: any) => q._question._id === question._id);
          return (
            <Input
              key={question._id}
              name={`questions.${question._id}`}
              label={language === 'en' ? question.label.en : question.label.ar}
              control={control}
              sx={{ margin: '16px 0' }}
              fullWidth
              multiline
              minRows={1}
              maxRows={4}
              defaultValue={stateQuestion?.value || ''}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={(e) => {
                if (!e.target.value) {
                  setValue(`questions.${question._id}`, '');
                }
              }}
            />
          );
        })}

        <Box marginTop='8px'>
          <FormControl>
            <FormLabel
              id='privacy-setting'
              sx={{
                color: '#4A403A',
                fontSize: '14px',
                '&.Mui-focused': {
                  color: '#4A403A',
                },
              }}
            >
              {staticTexts.privacySetting}
            </FormLabel>
            <Controller
              name='public'
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby='privacy-setting'
                  className='flex gap-4 !flex-row'
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={staticTexts.private}
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={staticTexts.public}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>

        {/* Buttons Container */}
        <Box
          display='flex'
          justifyContent='space-between'
          marginTop='24px'
        >
          {/* Back Button */}
          <CustomButton
            buttonStyle={{ marginLeft: -2.1 }}
            variant={AppButtonVariant.TEXT}
            title={staticTexts.back}
            onPress={() => navigate(-1)}
          />

          {/* Submit Button */}
          <CustomButton
            title={staticTexts.submit}
            type='submit'
            // onPress={handleSubmit(onSubmit)}
          />
        </Box>
      </form>

      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={staticTexts.viewMyListings}
        cancelText={staticTexts.close}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 40, color: '#FF6F59', mb: 2 }} />
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.itemPostedSuccessfully}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.yourLostItemIsListed}
        </Typography>
      </Modal>
    </ContentWrapper>
  );
};

export default ReportLostItem;
