import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid2, IconButton, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { ContentWrapper, CustomToggle, Modal, useSnackbar } from '../../../components';
import { Search } from '@mui/icons-material';
import { getStaticTexts } from '../../../assets';
import ViewListIcon from '@mui/icons-material/ViewListRounded';
import GridViewIcon from '@mui/icons-material/GridViewRounded';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useDispatch, useSelector } from 'react-redux';
import { SET_VIEW } from '../../../redux/actions/my-items';
import ProductCard from '../../../components/Layouts/ProductCard';
import { useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ProductList from '../../../components/Layouts/ProductList';
import CustomPagination from '../../../components/Pagination';
import { deleteItem, getItems, getMyClaims, resolveItemSelf } from '../../../services/aggregation';
import NoItems from '../../../components/Layouts/NoItems';
import { startLoader, stopLoader } from '../../../utils';
import DateTimeFormatting from '../../../utils/extensions/DateTimeFormatting';
import { Dialog } from '../../../components/Dialog';

interface ToolbarProps {
  active: string;
  itemCount: number;
  onReportClick: () => void;
  onViewChange: (view: string) => void;
}

/**
 * ManageMyItems component renders a page for managing my items.
 * @param {Object} staticTexts - An object containing static texts.
 * @param {string} selected - The selected item category.
 * @param {string} viewType - The current view type (grid or list).
 * @param {Function} onViewChange - A function to change the view type.
 * @param {number} itemCount - The number of items in the list.
 * @param {Function} onReportClick - A function to handle the report button click.
 * @returns {React.ReactElement} A React element containing the ManageMyItems component.
 */
export const ManageMyItems = () => {
  const language = useSelector((state: any) => state.language);
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 20,
  });
  const staticTexts = getStaticTexts(language);
  const [selected, setSelected] = useState<ReturnType<typeof getStaticTexts>[keyof ReturnType<typeof getStaticTexts>]>(
    staticTexts.lostItems
  );
  const [viewType, setViewType] = useState(staticTexts.grid);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:767px)');
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const isDeleteIconDisabled = selected === staticTexts.claimHistory || selected === staticTexts.resolvedItems;
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => setOpenDialog(false);

  const enqueueSnackbar = useSnackbar();

  /**
   * Updates the current page number in the pagination state.
   *
   * @param {React.ChangeEvent<unknown>} event - The page change event.
   * @param {number} value - The new page number to set.
   */
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [product, setProduct] = useState<any>(null);

  /**
   * Resets the state of the item deletion modal to its initial state.
   *
   * This function is called when the user closes the item deletion modal.
   */
  const handleClose = () => {
    setIsOpen(false);
    setProduct(null);
  };

  /**
   * Handles the confirmation of item deletion.
   *
   * This function is called when the user confirms the deletion of an item.
   * It attempts to delete the item and displays a success message if the deletion is successful.
   * If an error occurs during deletion, an appropriate error message is displayed.
   * Finally, the item deletion modal is closed.
   */
  const handleConfirm = async () => {
    try {
      await deleteItem([product?._id]);
      enqueueSnackbar(staticTexts.itemDeletedSuccessfully);
      fetchItems();
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      setIsOpen(false);
    }
  };

  /**
   * Returns the selected tab's value.
   * @returns {string} The selected tab's value, either "LOST", "FOUND", "RESOLVED", or "CLAIM".
   */
  const getSelectedTab = () => {
    switch (selected) {
      case staticTexts.lostItems:
        return 'LOST';
      case staticTexts.foundItems:
        return 'FOUND';
      case staticTexts.resolvedItems:
        return 'RESOLVED';
      case staticTexts.claimHistory:
        return 'CLAIM';
      default:
        return '';
    }
  };

  /**
   * Handles the resolution of an item by the user itself.
   *
   * This function attempts to resolve the item specified by its ID,
   * displays a success message upon successful resolution, and fetches
   * the updated items. If an error occurs during the resolution process,
   * an error message is displayed.
   *
   * @param {string} itemId - The ID of the item to be resolved.
   */

  const handleResolveItemSelf = async (itemId: string) => {
    try {
      await resolveItemSelf(itemId);
      enqueueSnackbar(staticTexts.itemResolvedSuccessfully);
      fetchItems();
    } catch (error: any) {
      enqueueSnackbar(error.message || staticTexts.somethingWentWrong);
    }
  };

  const fetchItems = async () => {
    startLoader();
    try {
      const params: any = {
        page: page,
        limit: meta.limit,
      };
      const data: any = {};

      const type = getSelectedTab();

      if (type === 'CLAIM') {
        const claims = (await getMyClaims(params)) as any;
        setProducts(claims?.claims);
        return;
      }

      switch (type) {
        case 'LOST':
          data.type = 'LOST';
          data.status = 'ACTIVE';
          break;
        case 'FOUND':
          data.type = 'FOUND';
          data.status = 'ACTIVE';
          break;
        case 'RESOLVED':
          data.status = 'RESOLVED';
          break;
        default:
          break;
      }

      const response = (await getItems(data, params)) as any;
      setProducts(response?.items);

      setMeta({
        totalPages: response?.meta?.totalPages || 0,
        page: response?.meta?.page || 1,
        limit: response?.meta?.limit || 20,
      });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    fetchItems();
  }, [page, selected]);

  const Toolbar: React.FC<ToolbarProps> = ({ itemCount, active, onReportClick, onViewChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const dispatch = useDispatch();

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleViewChange = (newView: string) => {
      setViewType(newView);
      dispatch({ type: SET_VIEW, payload: newView });
      onViewChange(newView);
      handleMenuClose();
    };

    const getToolbarButtonText = () => {
      switch (active) {
        case staticTexts.lostItems:
          return staticTexts.reportLostItem;
        default:
          return staticTexts.reportFoundItem;
      }
    };

    return (
      <Fragment>
        <Box className='w-full flex items-center justify-between py-8 pb-2 min-w-16'>
          {/* Title and Count */}
          <Box className='flex items-center space-x-4'>
            <Typography
              variant='h6'
              className='text-2xl font-semibold'
            >
              {active}
            </Typography>
          </Box>

          {/* View Options and Report Button */}
          <Box className='flex items-center'>
            {/* View Button with Dropdown */}
            <Box className='hidden md:flex space-x-2'>
              <span className='text-secondary-200 text-sm'>
                {itemCount} {selected?.toLowerCase()}
              </span>
              <IconButton
                onClick={handleMenuClick}
                className='border border-blue-500'
                size='small'
                disableRipple
                sx={{
                  height: 18,
                  '& .MuiSvgIcon-root': {
                    fontSize: '16px',
                  },
                }}
              >
                {viewType === staticTexts.grid ? <GridViewIcon /> : <ViewListIcon />}
                <span className='ms-1 text-sm'>{viewType}</span>
                <ArrowDownRoundedIcon sx={{ marginInlineStart: '2px' }} />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: 1,
                    backgroundColor: '#FFF7E6',
                    minWidth: 150,
                  },
                }}
              >
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#FFE0B5',
                    },
                  }}
                  onClick={() => handleViewChange(staticTexts.grid)}
                >
                  <GridViewIcon fontSize='small' />
                  <span
                    style={{
                      marginInlineStart: 8,
                      marginTop: 4,
                      fontSize: '0.875rem',
                    }}
                  >
                    {staticTexts.grid}
                  </span>
                </MenuItem>
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#FFE0B5',
                    },
                  }}
                  onClick={() => handleViewChange(staticTexts.list)}
                >
                  <ViewListIcon fontSize='small' />
                  <span
                    style={{
                      marginInlineStart: 8,
                      marginTop: 4,
                      fontSize: '0.875rem',
                    }}
                  >
                    {staticTexts.list}
                  </span>
                </MenuItem>
              </Menu>
            </Box>
            {/* Report Button */}
            <Button
              variant='contained'
              size='small'
              sx={{
                background: '#FF6F61',
              }}
              onClick={onReportClick}
            >
              {getToolbarButtonText()}
            </Button>
          </Box>
        </Box>
        <Box className='flex justify-between md:hidden space-x-2 ms-auto'>
          <span className='text-secondary-200 text-sm'>{itemCount} lost items</span>
          <IconButton
            onClick={handleMenuClick}
            className='border border-blue-500'
            size='small'
            disableRipple
            sx={{
              height: 18,
              '& .MuiSvgIcon-root': {
                fontSize: 14,
              },
            }}
          >
            {viewType === staticTexts.grid ? <GridViewIcon /> : <ViewListIcon />}
            <span className='ms-1 text-sm'>{viewType}</span>
            <ArrowDownRoundedIcon fontSize='small' />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: 1,
                backgroundColor: '#FFF7E6',
                minWidth: 150,
              },
            }}
          >
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#FFE0B5',
                },
              }}
              onClick={() => handleViewChange(staticTexts.grid)}
            >
              <GridViewIcon fontSize='small' />
              <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>{staticTexts.grid}</span>
            </MenuItem>
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#FFE0B5',
                },
              }}
              onClick={() => handleViewChange(staticTexts.list)}
            >
              <ViewListIcon fontSize='small' />
              <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>{staticTexts.list}</span>
            </MenuItem>
          </Menu>
        </Box>
      </Fragment>
    );
  };

  const handleToggle = (option: ReturnType<typeof getStaticTexts>[keyof ReturnType<typeof getStaticTexts>]) => {
    setSelected(option);
  };

  const getMobileOptions = (product: any) => {
    const selectedTab = getSelectedTab();

    if (selectedTab === 'CLAIM' || selectedTab === 'RESOLVED') {
      return [
        {
          label: staticTexts.view,
          onClick: () => {
            setOpenDialog(true);
            setProduct(product);
          },
        },
      ];
    }

    switch (selected) {
      case staticTexts.lostItems:
        return [
          {
            label: staticTexts.view,
            onClick: () => {
              setOpenDialog(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.edit,
            onClick: () => {
              const navigateTo = selected === staticTexts.lostItems ? '/report-lost-item' : '/report-found-item';
              navigate(navigateTo, { state: product });
            },
          },
          {
            label: staticTexts.delete,
            onClick: () => {
              setIsOpen(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.iHaveFoundItem,
            onClick: () => {
              handleResolveItemSelf(product?._id);
            },
          },
        ];
      case staticTexts.foundItems:
        return [
          {
            label: staticTexts.view,
            onClick: () => {
              setOpenDialog(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.edit,
            onClick: () => {
              const navigateTo = selected === staticTexts.lostItems ? '/report-lost-item' : '/report-found-item';
              navigate(navigateTo, { state: product });
            },
          },
          {
            label: staticTexts.delete,
            onClick: () => {
              setIsOpen(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.iHaveFoundOwner,
            onClick: () => {
              handleResolveItemSelf(product?._id);
            },
          },
        ];
      default:
        return [
          {
            label: staticTexts.view,
            onClick: () => {
              setOpenDialog(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.edit,
            onClick: () => {
              const navigateTo = selected === staticTexts.lostItems ? '/report-lost-item' : '/report-found-item';
              navigate(navigateTo, { state: product });
            },
          },
          {
            label: staticTexts.delete,
            onClick: () => {
              setIsOpen(true);
              setProduct(product);
            },
          },
        ];
    }
  };

  const getDesktopMenuOptions = (product: any) => {
    const selectedTab = getSelectedTab();

    if (selectedTab === 'CLAIM' || selectedTab === 'RESOLVED') {
      return [
        {
          label: staticTexts.view,
          onClick: () => {
            setOpenDialog(true);
            setProduct(product);
          },
        },
      ];
    }

    switch (selected) {
      case staticTexts.lostItems:
        return [
          {
            label: staticTexts.view,
            onClick: () => {
              setOpenDialog(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.iHaveFoundItem,
            onClick: () => {
              handleResolveItemSelf(product?._id);
            },
          },
        ];
      case staticTexts.foundItems:
        return [
          {
            label: staticTexts.view,
            onClick: () => {
              setOpenDialog(true);
              setProduct(product);
            },
          },
          {
            label: staticTexts.iHaveFoundOwner,
            onClick: () => {
              handleResolveItemSelf(product?._id);
            },
          },
        ];
      default:
        return [];
    }
  };

  const getMenuOptions = (product: any) => {
    return isMobile ? getMobileOptions(product) : getDesktopMenuOptions(product);
  };

  return (
    <Fragment>
      <ContentWrapper
        title={staticTexts.manageMyItems}
        alignText='left'
        maxWidth='700px'
        mdWidth='52%'
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CustomToggle
            selected={selected}
            options={[
              {
                key: staticTexts.lostItems,
                labelKey: 'lostItems',
                icon: <SentimentVeryDissatisfiedIcon />,
              },
              {
                key: staticTexts.foundItems,
                labelKey: 'foundItems',
                icon: <ZoomInIcon />,
              },
              {
                key: staticTexts.resolvedItems,
                labelKey: 'resolvedItems',
                icon: <Search />,
              },
              {
                key: staticTexts.claimHistory,
                labelKey: 'claimHistory',
                icon: <Search />,
              },
            ]}
            handleToggle={handleToggle}
          />
        </Box>
        <Toolbar
          active={selected}
          onViewChange={() => {}}
          itemCount={products?.length ?? 0}
          onReportClick={() => {
            if (selected === staticTexts.lostItems) {
              navigate('/report-lost-item');
            } else {
              navigate('/report-found-item');
            }
          }}
        />
        {!products?.length ? (
          <NoItems />
        ) : (
          <Fragment>
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
              marginTop='18px'
              width='100%'
            >
              {viewType === staticTexts.list ? (
                products.map((product: any, index) => (
                  <ProductList
                    isDeleteIconDisabled={isDeleteIconDisabled}
                    key={index}
                    title={product?.name}
                    description={product?.description}
                    location={product?.location?.name}
                    visibility={product?.visibleToEveryone}
                    date={product?.eventDate}
                    imageUrl={product?.images[0]}
                    onEdit={() => {
                      const navigateTo =
                        selected === staticTexts.lostItems ? '/report-lost-item' : '/report-found-item';
                      navigate(navigateTo, { state: product });
                    }}
                    onDelete={() => {
                      setIsOpen(true);
                      setProduct(product);
                    }}
                    menuOptions={getMenuOptions(product)}
                    showEdit={getSelectedTab() !== 'CLAIM' && getSelectedTab() !== 'RESOLVED'}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr 1fr',
                      sm: '1fr 1fr',
                      lg: '1fr 1fr 1fr',
                    },
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  {(products || []).map((product: any, index) => (
                    <ProductCard
                      isDeleteIconDisabled={isDeleteIconDisabled}
                      key={index}
                      image={product?.images[0]}
                      images={product?.images}
                      itemCategory={product?._category?.name}
                      itemName={product?.name}
                      description={product?.description}
                      location={product?.location?.name}
                      date={product?.eventDate}
                      type={product?.visibleToEveryone ? staticTexts.public : staticTexts.private}
                      onEdit={() => {
                        const navigateTo =
                          selected === staticTexts.lostItems ? '/report-lost-item' : '/report-found-item';
                        navigate(navigateTo, { state: product });
                      }}
                      onDelete={() => {
                        setIsOpen(true);
                        setProduct(product);
                      }}
                      showMoreIcon={getSelectedTab() !== 'CLAIM' && getSelectedTab() !== 'RESOLVED'}
                      showEditButton={getSelectedTab() !== 'CLAIM' && getSelectedTab() !== 'RESOLVED'}
                      menuOptions={getMenuOptions(product)}
                    />
                  ))}
                </Box>
              )}
            </Box>
            <CustomPagination
              count={meta.totalPages}
              page={meta.page}
              onChange={handlePageChange}
            />
          </Fragment>
        )}
      </ContentWrapper>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        title={`${selected} ${staticTexts.details}`}
        maxWidth='lg'
        titlePadding='16px 24px'
        contentPadding='0px 24px'
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Typography
              variant='h6'
              fontSize='16px'
              color='#4A403A'
              gutterBottom
            >
              {staticTexts.itemDetails}
            </Typography>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.name}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ textTransform: 'capitalize' }}
                >
                  {product?.name}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.description}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {product?.description}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.location}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {product?.location?.name}
                </Typography>
              </Box>

              <Box marginBottom='24px'>
                <Typography
                  component='p'
                  fontSize='12px'
                  color='#776E65'
                >
                  {staticTexts.dateLost}
                </Typography>
                <Typography
                  component='p'
                  fontSize='14px'
                  color='#4A403A'
                >
                  {DateTimeFormatting.getDateByFormatCustomUTC(
                    product?.createdAt ?? '',
                    true,
                    DateTimeFormatting.DateTimeFormat.DD_MMM_yyyy
                  )}
                </Typography>
              </Box>
            </Grid2>

            {/* Uploaded Images Section */}
            <Box mt={4}>
              <Typography
                variant='h6'
                fontSize='16px'
                color='#4A403A'
                gutterBottom
              >
                {staticTexts.uploadImages}
              </Typography>
              <Box
                display='flex'
                gap='8px'
                paddingBottom='24px'
              >
                {(product?.images || []).map((image: string, index: number) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Item ${index + 1}`}
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '8px',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        }
      />

      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={() => handleConfirm()}
        confirmText={staticTexts.delete}
        cancelText={staticTexts.cancel}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
        cancelButtonStyle={{ color: '#4A403A', border: '1px solid #C9C2B4' }}
      >
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.deleteItem}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.deleteItemWarning}
        </Typography>
      </Modal>
    </Fragment>
  );
};
