import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateInput, ImageUpload, Input, LocationInput, Modal, useSnackbar } from '../../components';
import { getStaticTexts } from '../../assets';
import LocationIcon from '@mui/icons-material/LocationOn';
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQuestionnaire } from '../../services/aggregation';
import { claimItem } from '../../services/claim-item';
import { startLoader, stopLoader } from '../../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '../../components/Checkbox';
import CustomButton from '../../components/Button/CustomButton';
import { AppButtonVariant } from '../../constants/AppConstants';
import { ClaimItemFormFields } from '../../interfaces';

/**
 * ClaimItem component
 * @description This component is used to claim an item.
 * @returns A JSX element for the claim item component.
 */
const ClaimItem = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ClaimItemFormFields>();

  const language = useSelector((state: any) => state.language);
  const [customQuestions, setCustomQuestion] = useState<any>([]);
  const {
    pathname,
    state: { name, description, image, location },
  } = useLocation();
  const [_, __, categoryId, itemId] = pathname.split('/');
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const staticTexts = getStaticTexts(language);
  const [isOpen, setIsOpen] = useState(false);
  const [locationValue, setLocationValue] = useState({
    longitude: 0,
    latitude: 0,
    description: '',
  });
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const [images, setImages] = useState<any[]>([]);

  /**
   * Closes the modal and reloads the window.
   * This function is used to reset the state and close the modal.
   */

  const handleClose = () => {
    window.location.reload();
    setIsOpen(false);
  };
  const [isTermsChecked, setIsTermsChecked] = useState(true);

  // Handle Checkbox Click
  const handleCheckboxChange = (isChecked: boolean) => {
    setIsTermsChecked(isChecked);
  };

  /**
   * Navigates to /my-items and closes the modal.
   * This function is used as the confirm callback for the modal.
   */
  const handleConfirm = () => {
    navigate('/my-items');
    setIsOpen(false);
  };

  /**
   * Sets the images state to the given array of file URLs.
   * @param {string[]} fileUrls - An array of URLs for the selected images.
   */
  const handleFileChange = (fileUrls: string[]) => {
    setImages(fileUrls);
  };

  /**
   * Handles the submission of the form.
   * If the terms and conditions checkbox is unchecked, it shows an error message.
   * If the user is not logged in, it saves the payload to local storage and navigates to the login page.
   * If the user is logged in, it calls the claim item API and shows a modal on success.
   * If there is an error, it shows the error message and dismisses the loading state.
   * @param {Object} data - The form data containing information about the item, including its description, event date, location, images, and related questions.
   */
  const onSubmit = async (data: any) => {
    try {
      if (!isTermsChecked) {
        enqueueSnackbar(staticTexts.pleaseAcceptTnC);
        return;
      }

      // Show Loading
      startLoader();

      // Prepare Payload
      const payload = {
        itemId,
        description: data.description,
        eventDate: data.date,
        location: {
          type: 'Point',
          coordinates: [locationValue.longitude, locationValue.latitude],
          name: data.location,
        },
        images,
        questions: Object.entries(data)
          .filter(([key]) => key.startsWith('customQuestion_'))
          .map(([key, value]) => ({
            _question: key.split('_')[1],
            value,
          })),
      };

      if (!isLoggedIn) {
        sessionStorage.setItem('claim-item-data', JSON.stringify(payload));
        navigate('/auth/login');
        return;
      }
      // API Call: Claim Item
      await claimItem({
        ...payload,
      });

      // Show Modal
      setIsOpen(true);
    } catch (error: any) {
      // Show Modal
      setIsOpen(false);

      // Show Message
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
  };

  /**
   * Updates the location value state with the provided location details.
   *
   * @param {Object} location - An object containing details of the location.
   * @param {number} location.lat - The latitude of the location.
   * @param {number} location.lng - The longitude of the location.
   * @param {string} location.description - The description of the location.
   */

  const handleLocationValueChange = (location: { lat: number; lng: number; description: string }) => {
    setLocationValue({
      latitude: location.lat,
      longitude: location.lng,
      description: location.description,
    });
  };

  /**
   * Handles the user clicking on the "Use current location" button.
   * @param {Object} location - An object containing the latitude and longitude of the user's current location.
   * @param {String} error - An error message if there was an issue getting the user's location.
   */
  const handleCurrentLocationClick = (
    location: { latitude: number; longitude: number } | null,
    error: string | null
  ) => {
    if (error) {
      enqueueSnackbar(error);
      return;
    }

    if (location) {
      setLocationValue({
        latitude: location.latitude,
        longitude: location.longitude,
        description: '',
      });
      setValue('location', '');
    }
  };

  useEffect(() => {
    /**
     * Fetches the custom questions for a given category ID.
     * @returns {Promise<void>} - A promise that resolves when the custom questions have been fetched.
     */
    const fetchCustomQuestions = async () => {
      try {
        const response = (await getQuestionnaire('CLAIM', String(categoryId))) as any;
        setCustomQuestion(response?.questions);
      } catch (error) {
        console.error('Error fetching custom questions:', error);
      }
    };
    fetchCustomQuestions();
  }, [categoryId]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        bgcolor='#FFF7E6'
        paddingX={{
          xs: '24px',
          md: '32px',
        }}
        paddingY={{
          xs: '16px',
          md: '40px',
        }}
        width={{
          xs: '90%',
          md: '50%',
        }}
        borderRadius='8px'
        marginTop='64px'
        marginBottom={{
          xs: '16px',
          md: '64px',
        }}
      >
        <Typography
          component='p'
          fontSize='26px'
          color='#4A403A'
          letterSpacing='0.01px'
        >
          {staticTexts.claimItem}
        </Typography>

        <Box
          bgcolor='#F7EFDE'
          borderRadius='14px'
          marginTop='16px'
          marginBottom='24px'
          padding='8px'
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Box
            height='64px'
            width='64px'
            minWidth='64px'
            borderRadius='6px'
          >
            <img
              className='rounded-md object-cover h-full'
              src={image}
              alt='item-image'
              height='100%'
              width='100%'
            />
          </Box>

          <Box>
            <Typography
              component='p'
              fontSize='14px'
              color='#4A403A'
            >
              {name}
            </Typography>

            <Typography
              component='p'
              fontSize='12px'
              color='#655B53FA'
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
              }}
            >
              {description}
            </Typography>

            <Typography
              component='p'
              fontSize='12px'
              color='#655B53FA'
              display='flex'
              alignItems='center'
              gap='8px'
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 1,
              }}
            >
              <LocationIcon sx={{ fontSize: '12px', color: '#655B53FA' }} /> {location?.name}
            </Typography>
          </Box>
        </Box>

        <Box
          bgcolor='#FFE0B5'
          padding='8px'
          borderRadius='8px'
          color='#655B53FA'
          marginBottom='24px'
          fontSize='12px'
        >
          <DirectionsWalkRoundedIcon
            sx={{ fontSize: '14px' }}
            className='me-1 text-secondary-100'
          />
          {staticTexts.claimNote}
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Description Input */}
          <Input
            name='description'
            className='!mb-6'
            label={staticTexts.pleaseStateWhyThisItemIsYours}
            placeholder={staticTexts.describeAnyUnique}
            fullWidth
            multiline
            rows={4}
            control={control}
            error={errors.description}
            isRequired
            helperText={staticTexts.claimDescription}
          />

          {/* Custom Questions Input List */}
          {customQuestions?.map((question: any, index: number) => {
            const fieldName = `customQuestion_${question._id}`;
            return (
              <Input
                key={index}
                name={fieldName}
                className='!mb-6'
                label={language === 'en' ? question.label?.en : question.label?.ar}
                placeholder={question.placeholder || ''}
                control={control}
                isRequired={question.isRequired}
                validationRules={{
                  required: question.isRequired ? 'This field is required' : undefined,
                }}
              />
            );
          })}

          {/* Location Input */}
          <Controller
            name='location'
            control={control}
            rules={{
              required: staticTexts.locationIsRequired,
            }}
            render={({ field: { onChange, ...field } }) => (
              <LocationInput
                {...field}
                control={control}
                label={staticTexts.location}
                onCurrentLocationClick={handleCurrentLocationClick}
                handleLocationSelect={(location) => {
                  handleLocationValueChange(location);
                  onChange(location.description);
                }}
              />
            )}
          />

          {/* Date Input */}
          <DateInput
            name='date'
            className='!mb-1 w-full'
            label={staticTexts.selectDate}
            control={control}
            error={errors.date}
            helperText={errors.date?.message || ' '}
            validationRules={{
              required: 'Date is required',
            }}
          />

          {/* Upload Images Text */}
          <Typography
            marginBottom='8px'
            color='#655B53'
            fontSize='14px'
          >
            {staticTexts.uploadImages}
          </Typography>

          {/* Image Upload Component */}
          <ImageUpload
            uploadedImages={images}
            helperText={staticTexts.claimItemImageHelperText}
            onFileChange={handleFileChange}
          />

          {/* Checkbox Container */}
          <Box
            display='flex'
            gap='12px'
            alignItems={'center'}
          >
            <Checkbox
              checked={isTermsChecked}
              onChange={handleCheckboxChange}
            />

            <Typography
              component='p'
              fontSize='12px'
              color='#776E65'
            >
              {staticTexts.iAgreeTo}{' '}
              <span>
                <a
                  href='/terms-and-condition'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='underline cursor-pointer'
                >
                  {staticTexts.termsAndCondition}
                </a>{' '}
                {staticTexts.and}{' '}
                <a
                  href='/privacy-policy'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='underline cursor-pointer'
                >
                  {staticTexts.privacyPolicy}
                </a>
              </span>
            </Typography>
          </Box>

          {/* Buttons Container */}
          <Box
            display='flex'
            justifyContent='space-between'
            marginTop='24px'
          >
            {/* Back Button */}
            <CustomButton
              buttonStyle={{ marginLeft: -2.1 }}
              variant={AppButtonVariant.TEXT}
              title={staticTexts.back}
              onPress={() => navigate(-1)}
            />

            {/* Submit Button */}
            <CustomButton
              title={staticTexts.submit}
              type='submit'
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </Box>

      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText={staticTexts.ok}
        cancelText={staticTexts.close}
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 40, color: '#FF6F59', mb: 2 }} />
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          {staticTexts.claimSubmittedSuccessfully}
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          {staticTexts.claimSubmittedMessage}
        </Typography>
      </Modal>
    </Box>
  );
};

export default ClaimItem;
