import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  title?: string;
  mdWidth?: string;
  maxWidth?: string;
  alignText?: 'center' | 'left' | 'right';
}

export const ContentWrapper: React.FC<Props> = ({
  children,
  title,
  mdWidth = '50%',
  maxWidth = '640px',
  alignText = 'center'
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      sx={{ marginTop: { xs: 2.5, md: 6 }, marginBottom: { xs: 2.5, md: 6 } }}
    >
      <Box
        width={{
          xs: '90%',
          md: mdWidth || '50%',
        }}
        maxWidth={maxWidth}
      >

        {/* Heading */}
        <Typography
          component='p'
          color='#4A403A'
          textAlign={alignText || 'center'}
          letterSpacing='0.01px'
          className="text-primary_text font-bold"
          fontSize={{ xs: "26px", md: "26px" }}
          marginBottom={{ xs: 2.5, md: 3 }}
        >
          {title}
        </Typography>

        {/* Content Container */}
        <Box
          bgcolor='#FFF7E6'
          padding={{
            xs: '16px',
            md: '24px',
          }}
          borderRadius='8px'
        >
          {children}
        </Box>
      </Box>
    </Box >
  );
};
