import React, { createContext, useState, useContext } from 'react';
import { CustomizedSnackbar } from './Snackbar';

interface SnackbarContextType {
  enqueueSnackbar: (message: string, description?: string) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    description: '',
  });

  const enqueueSnackbar = (message: string, description: string = '') => {
    setSnackbar({ open: true, message, description });
  };

  const handleClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar }}>
      {children}
      <CustomizedSnackbar
        open={snackbar.open}
        message={snackbar.message}
        status={snackbar.description}
        onClose={handleClose}
        autoHideDuration={7000}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context.enqueueSnackbar;
};
