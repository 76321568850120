import { Box, Typography } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OTPInput, useSnackbar } from '../../../components';
import { Controller, useForm } from 'react-hook-form';
import { sendSignupOTP, signup, verifySignupOTP } from '../../../services';
import { SET_PROFILE_DETAILS, UPDATE_LOGIN_STATUS } from '../../../redux';
import { claimItem } from '../../../services/claim-item';
import { createItem } from '../../../services/aggregation';
import { startLoader, stopLoader } from '../../../utils';
import { LocalStorageKeys, UserType } from '../../../constants/AppConstants';

/**
 * Component for verifying OTP sent for signup.
 *
 * This component displays a form for user to input the OTP received on their
 * mobile number. It also provides a button to resend the OTP if not received.
 * Once the OTP is verified, user data is saved to local storage, and optional
 * claim or report actions are executed. The user is then redirected to the home
 * page, and their profile details are updated in the redux store.
 *
 * @returns {JSX.Element} JSX element for verifying OTP.
 */
export const VerifySignupOTP = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: ['', '', '', ''],
    },
  });

  const enqueueSnackbar = useSnackbar();

  /**
   * Handles the submission of the OTP form.
   *
   * This function validates the provided OTP, verifies it with the server, and
   * performs subsequent actions based on the verification response. If the OTP
   * is invalid, an error message is displayed. If successful, user data is saved
   * to local storage, and optional claim or report actions are executed. The user
   * is then redirected to the home page, and their profile details are updated in
   * the redux store.
   *
   * @param {object} data - The form data containing the OTP value.
   * @param {string[]} data.otp - The OTP entered by the user.
   */
  const onSubmit = async (data: { otp: string[] }) => {
    const otp = data.otp.join('');
    if (otp.length !== 4) {
      setError('otp', { type: 'manual', message: '' });
      return;
    }
    try {
      startLoader();
      const signupData = sessionStorage.getItem('signup-data');
      const claimItemData = sessionStorage.getItem('claim-item-data');
      const reportFoundItemData = sessionStorage.getItem('report-found-item-data');
      if (signupData) {
        const { name, email, prefix, number, gender,dob, idProof, password, confirmPassword, profile } =
          JSON.parse(signupData);

        const payload = {
          name,
          ...(email && { email }),
          phone: { prefix: prefix.replace('+', ''), number },
          idProof,
          password,
          dob,
          confirmPassword,
          ...(profile && { profile }),
          gender: gender.toUpperCase(),
          type: UserType.INDIVIDUAL,
        };

        await verifySignupOTP({
          phone: { prefix: prefix.replace('+', ''), number },
          otp,
        });
        const response = (await signup(payload)) as any;
        sessionStorage.removeItem('signup-data');
        if (response.status === 'INACTIVE') {
          enqueueSnackbar(staticTexts.yourAccountHasBeenCreated);
          reset();
          return;
        }

        if(!response.data.token){
          enqueueSnackbar(response?.message);
          return navigate('/home');
        };

        localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, response.data.token);
        localStorage.setItem(LocalStorageKeys.USER_TYPE, response.data.user.type);

        if (claimItemData) {
          const payload = JSON.parse(claimItemData) || {};
          await claimItem(payload);
          sessionStorage.removeItem('claim-item-data');
          enqueueSnackbar(staticTexts.itemClaimedSuccessfully);
        }

        if (reportFoundItemData) {
          const payload = JSON.parse(reportFoundItemData) || {};
          await createItem(payload);
          sessionStorage.removeItem('report-found-item-data');
          enqueueSnackbar(staticTexts.itemPostedSuccessfully);
        }

        dispatch({
          type: UPDATE_LOGIN_STATUS,
          payload: {
            isLoggedIn: true,
          },
        });
        const user = response?.data?.user;

        dispatch({
          type: SET_PROFILE_DETAILS,
          payload: {
            _id: user?._id,
            name: user?.name,
            email: user?.email,
            profile: user?.profile,
            phone: user?.phone,
            gender: user?.gender,
            dob: user?.dob,
            address: user?.address,
            description: user?.description,
            coverProfile: user?.coverProfile,
            type: user?.type,
            defaultLanguage: user?.defaultLanguage,
          },
        });

        navigate('/home');
      }
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  /**
   * Resends the OTP for signup verification.
   *
   * This function attempts to resend the OTP to the user's registered
   * phone number using the data stored in session storage. If the signup
   * data is available, it extracts the phone details and sends a request
   * to resend the OTP. If an error occurs during the process, an error
   * message is displayed using a snackbar. A loading indicator is shown
   * while the operation is in progress.
   */

  const handleResendOTP = async () => {
    try {
      startLoader();
      const signupData = sessionStorage.getItem('signup-data');
      if (signupData) {
        const {
          phone: { prefix, number },
        } = JSON.parse(signupData) || {};
        await sendSignupOTP({
          phone: {
            prefix: prefix.replace('+', ''),
            number,
          },
        });
      }
      return;
    } catch (error) {
      enqueueSnackbar((error as any).response?.data?.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <Typography
            component='p'
            fontSize='24px'
            variant='body2'
            className='font-medium'
          >
            {staticTexts.verifyNumber}
          </Typography>
          <Typography
            component='p'
            fontSize='14px'
            className='text-secondary-200 mt-2'
          >
            {staticTexts.verifyYourMobileNumber}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className='flex flex-col items-center rounded-md py-2'>
            <Box className='mt-6 sm:justify-center md:self-start'>
              <Controller
                name='otp'
                control={control}
                rules={{
                  validate: (value) => value.every((v) => /^[0-9]$/.test(v)) || `OTP ${staticTexts.isRequired}`,
                }}
                render={({ field }) => (
                  <OTPInput
                    otp={field.value}
                    onChange={(index: number, value: string) => {
                      const updatedOtp = [...field.value];
                      updatedOtp[index] = value;
                      field.onChange(updatedOtp);
                    }}
                    error={errors?.otp?.message}
                    onResendOTP={handleResendOTP}
                  />
                )}
              />
              <Typography
                component='p'
                fontSize='12px'
                marginTop='24px'
                textAlign='left'
                className='text-secondary-200 mt-2'
              >
                {staticTexts.youWillReceiveOTPforVerification}
              </Typography>
            </Box>

            <Box className='flex gap-4 mt-8 justify-center items-center'>
              <Button
                variant='outlined'
                className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
                onClick={() => navigate(-1)}
                buttonStyle={{
                  color: '#111111D8',
                  border: '1px solid #C9C2B4',
                }}
              >
                {staticTexts.back}
              </Button>

              <Button
                type='submit'
                className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
              >
                {staticTexts.verifyAndContinue}
              </Button>
            </Box>
          </Box>
        </form>

        <Typography
          variant='body1'
          fontSize='12px'
          color='#776E65'
          marginTop='40px'
          className='text-center cursor-pointer'
        >
          {staticTexts.needHelp}{' '}
          <span
            className='underline'
            onClick={() => navigate('/contact-support')}
          >
            {staticTexts.contactSupport}
          </span>
        </Typography>
      </Box>
    </Fragment>
  );
};
