import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SxProps, TextFieldProps } from '@mui/material';
import { Controller, Control, FieldError, RegisterOptions } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface DateInputProps {
  control?: Control<any>;
  name: string;
  label?: string;
  className?: string;
  defaultValue?: Dayjs | null;
  error?: FieldError;
  helperText?: string;
  onChange?: (newValue: Dayjs | null) => void;
  validationRules?: RegisterOptions;
  dateInputStyles?: SxProps
  disabled?: boolean;
}

export const DateInput: React.FC<DateInputProps> = ({
  control,
  name,
  label = 'Date',
  defaultValue = null,
  error,
  helperText = '',
  onChange,
  validationRules = {},
  className,
  dateInputStyles,
  disabled = false,
}) => {

  const language = useSelector((state: any) => state.language);
  const dateFormat = language === 'ar' ? 'MMM DD YYYY' : 'DD MMM, YYYY';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <DatePicker
            className={`${className}`}
            value={field.value ? dayjs(field.value) : null} // Convert value to Dayjs
            onChange={(newValue) => {
              field.onChange(newValue ? dayjs(newValue) : null); // Ensure onChange returns Dayjs or null
              if (onChange) onChange(newValue ? dayjs(newValue) : null);
            }}
            format={dateFormat}
            disableFuture
            sx={{ ...dateInputStyles }}
            slotProps={{
              textField: {
                label,
                error: !!error,
                helperText: error?.message || helperText,
                placeholder: 'DD MM, YYYY'
              } as TextFieldProps,
              day: {
                sx: {
                  '&.Mui-selected': {
                    backgroundColor: '#FF6F61 !important',
                    color: '#FFF7E6',
                  },
                  '&.MuiPickersDay-today': {
                    border: '1px solid #FF6F61 !important',
                    background: '#FFF7E6',
                  },
                },
              },
            }}
            disabled={disabled}
          />

        )}
      />
    </LocalizationProvider>
  );
};
