import React, { Fragment, useRef, useState } from 'react';
import { CookieConsent, PartnerCarousel, Rating } from '../../../components';
import { Box, Typography } from '@mui/material';
import { LocationOn, AccessTime, LocalShipping } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import GoogleAd from '../../../components/AdSection';

/**
 * The main page of the website.
 *
 * This component is responsible for rendering the main content of the website, including the video
 * section, the partner carousel, and the LQEET awards.
 *
 * @returns The main page of the website.
 */
export const Main: React.FC = () => {
  const [state, setState] = useState({
    isOpen: false,
    showCookie: localStorage.getItem('hideCookie') !== 'true',
  });
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  /**
   * CenterVideoSection component responsible for rendering the central
   * video section of the homepage. It includes a video player with
   * controls for play/pause and mute/unmute functionalities.
   *
   * The component displays static texts and icons representing
   * search, find, and ship concepts. It also features hover actions
   * to reveal pause controls when the video is playing.
   *
   * @returns {JSX.Element} The rendered video section component.
   */
  const CenterVideoSection: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState<boolean>(true);
    const [isHovered, setIsHovered] = useState(false);

    /**
     * Toggles the play and pause state of the video.
     *
     * This function checks the current state of the video element
     * and plays the video if it is paused, or pauses it if it is
     * currently playing. It also updates the `isPlaying` state
     * accordingly.
     */
    const togglePlayPause = () => {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setIsPlaying(true);
        } else {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }
    };

    /**
     * Toggles the mute and unmute state of the video.
     *
     * This function checks the current state of the video element
     * and sets the muted property to the opposite of its current
     * value. It also updates the `isMuted` state accordingly.
     */
    const toggleMute = () => {
      if (videoRef.current) {
        videoRef.current.muted = !videoRef.current.muted;
        setIsMuted(videoRef.current.muted);
      }
    };

    return (
      <div className='flex flex-col items-center justify-center h-fit bg-[#4A403A] p-16 min-w-96'>
        <Typography
          variant='body1'
          className='text-center text-sm mb-4 font-bold'
          fontSize='26px'
          color='#FFF7E6'
          marginBottom='18px'
          letterSpacing='0.01px'
        >
          {staticTexts.search}. {staticTexts.find}. {staticTexts.ship}.
        </Typography>

        <div className='flex gap-4 mb-8 text-center text-[#FFF7E6]'>
          <div className='flex flex-col items-center'>
            <span className='text-xl'>
              <AccessTime />
            </span>
            <Typography variant='body1'>{staticTexts.anytime}.</Typography>
          </div>
          <div className='flex flex-col items-center'>
            <span className='text-xl'>
              <LocationOn />
            </span>
            <Typography variant='body1'>{staticTexts.anyplace}.</Typography>
          </div>
          <div className='flex flex-col items-center'>
            <span className='text-xl'>
              <LocalShipping />
            </span>
            <Typography variant='body1'>{staticTexts.anywhere}.</Typography>
          </div>
        </div>

        <Box
          className='relative w-1/2 h-[350px] rounded-lg shadow-lg overflow-hidden min-w-96'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <video
            ref={videoRef}
            className='w-full h-full object-cover z-20 relative'
            autoPlay
            muted
            playsInline
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={() => setIsPlaying(false)}
          >
            <source
              src='https://ik.imagekit.io/ikmedia/example_video.mp4'
              type='video/mp4'
            />
            {staticTexts.yourBrowserDoesntSupportVideoTag}
          </video>

          {/* Show play button if not playing */}
          {!isPlaying && (
            <div
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 bg-white bg-opacity-50 text-black p-2 rounded-full cursor-pointer'
              onClick={togglePlayPause}
            >
              <PlayArrowIcon
                sx={{
                  color: '#776E65',
                }}
              />
            </div>
          )}

          {/* Show pause button if playing and hovered */}
          {isPlaying && isHovered && (
            <div
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 bg-[#FFFFFFD9] bg-opacity-50 text-black p-2 rounded-full cursor-pointer'
              onClick={togglePlayPause}
            >
              <PauseIcon
                sx={{
                  color: '#776E65',
                }}
              />
            </div>
          )}

          {/* Mute/Unmute button at bottom-right */}
          <div
            className='absolute bottom-4 right-4 z-30 bg-[#FFFFFFD9] bg-opacity-50 text-black h-7 w-7 text-center rounded-full cursor-pointer'
            onClick={toggleMute}
          >
            {isMuted ? (
              <VolumeOffIcon
                sx={{
                  color: '#776E65',
                  fontSize: '16px',
                }}
              />
            ) : (
              <VolumeUpIcon
                sx={{
                  color: '#776E65',
                  fontSize: '16px',
                }}
              />
            )}
          </div>
        </Box>
      </div>
    );
  };

  /**
   * Sets showCookie to false and saves this preference to local storage.
   * Call this function when the user clicks the "Got it" button on the cookie consent banner.
   */
  const hideCookie = () => {
    localStorage.setItem('hideCookie', 'true');
    setState({ ...state, showCookie: false });
  };

  return (
    <Fragment>
      {/* <GoogleAd /> */}
      <div className='mt-12 mb-16'>
        <Typography
          className='h-4 text-center opacity-100 font-normal text-sm leading-5'
          textAlign='center'
          fontSize='14px'
          color='#655B53FA'
        >
          {staticTexts.someOrganizationsThatUseOurSoftware}
        </Typography>
        <div className='mt-8'>
          <PartnerCarousel />
        </div>
      </div>
      {state.showCookie && (
        <Box
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <CookieConsent
            onAccept={hideCookie}
            onIgnore={hideCookie}
          />
        </Box>
      )}
      <Rating />
      <div className='mt-32'>
        <CenterVideoSection />
      </div>

      <Box
        marginY='64px'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <Typography
          color='#655B53FA'
          fontSize='14px'
        >
          {staticTexts.lqeetAwards}
        </Typography>

        <Box width='80%'>
          <PartnerCarousel />
        </Box>
      </Box>
    </Fragment>
  );
};
