import React, { Fragment, SetStateAction, useMemo } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  TextField,
  SwipeableDrawer,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import moment from 'moment';
import StringFormatting from '../../../utils/extensions/StringFormatting';

const FilterDrawer = ({
  filters,
  onApply,
  showSortFilter = true,
  showDateFilter = true,
  showCategoryFilter = false,
  isOpen,
  categories,
  toggleDrawer,
  handleClearAll,
  handleSortFilterChange,
  handleCategoryChange,
  handleViewModeChange,
  handleDateRangeChange,
  handleCustomDateChange,
  handleClose,
  expandedSections,
  setExpandedSections,
}: {
  filters: {
    sortBy?: string;
    viewMode: string;
    dateRange?: any;
    customDate?: { from: string; to: string };
    _category?: string;
  };
  showSortFilter?: boolean;
  showDateFilter?: boolean;
  showCategoryFilter?: boolean;
  categories?: Array<any>;
  onApply: () => void;
  isOpen: boolean;
  toggleDrawer: (state: boolean) => void;
  handleClearAll: () => void;
  handleSortFilterChange?: (event: any) => void;
  handleCategoryChange?: (event: any) => void;
  handleViewModeChange: (event: any) => void;
  handleDateRangeChange?: (event: any) => void;
  handleCustomDateChange?: (event: any) => void;
  handleClose: () => void;
  expandedSections: {
    viewMode?: boolean;
    dateRange?: boolean;
    category?: boolean;
  };
  setExpandedSections: React.Dispatch<SetStateAction<{ viewMode?: boolean; dateRange?: boolean; category?: boolean }>>;
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const { sortBy, customDate, dateRange, viewMode } = filters;

  const handleToggle = (section: 'viewMode' | 'dateRange') => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const Puller = styled('div')(({ theme }) => ({
    width: 36,
    height: 6,
    backgroundColor: '#3C3C434D',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    ...theme.applyStyles('dark', {
      backgroundColor: '#3C3C434D',
    }),
  }));

  return (
    <Fragment>
      <SwipeableDrawer
        anchor='bottom'
        sx={{ '& .MuiDrawer-paper': { height: '90%', borderRadius: '12px 12px 0px 0px', bgcolor: '#FFF7E6' } }}
        open={isOpen}
        onClose={handleClose}
        onOpen={() => toggleDrawer(true)}
      >
        <Box sx={{ width: '100%', padding: 2 }}>
          {/* Header */}
          <Puller />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant='h6'
              color='#4A403A'
              fontSize='20px'
              textAlign='left'
            >
              {staticTexts.sortAndFilter}
            </Typography>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Button
              buttonStyle={{
                border: '1px solid #C9C2B4',
                color: '#776E65',
                width: '48%',
              }}
              onClick={handleClearAll}
              variant='outlined'
            >
              {staticTexts.clearAll}
            </Button>

            <Button
              buttonStyle={{
                color: '#FFF7E6',
                width: '48%',
              }}
              onClick={onApply}
              variant='contained'
              color='primary'
            >
              {staticTexts.apply}
            </Button>
          </Box>
          <Divider />

          {/* Category */}
          {showCategoryFilter && (
            <Box sx={{ mt: 2 }}>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize='18px'
                color='#4A403A'
                fontWeight={400}
                className='!mb-3'
              >
                {staticTexts.category}
              </Typography>
              <Select
                fullWidth
                placeholder={staticTexts.selectCategory}
                value={filters?._category ? filters?._category : ''}
                onChange={handleCategoryChange}
              >
                {(categories || []).map((category) => (
                  <MenuItem key={category?.name} value={category?._id}>{StringFormatting.capitalizeFirstLetter(category?.name)}</MenuItem>
                ))}
               
              </Select>
            </Box>
            
          )}

          {/* Sort By Section */}
          {showSortFilter && (
            <Box sx={{ mt: 2 }}>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize='18px'
                color='#4A403A'
                fontWeight={400}
                className='!mb-3'
              >
                {staticTexts.sortBy}
              </Typography>
              <Select
                fullWidth
                value={sortBy}
                onChange={handleSortFilterChange}
              >
                <MenuItem value='newest'>{staticTexts.newestToOldest}</MenuItem>
                <MenuItem value='oldest'>{staticTexts.oldestToNewest}</MenuItem>
                <MenuItem value='A-Z'>{staticTexts.alphabeticalAtoZ}</MenuItem>
                <MenuItem value='Z-A'>{staticTexts.alphabeticalZtoA}</MenuItem>
              </Select>
            </Box>
          )}

          {/* Filter By Text */}
          <Typography
            variant='subtitle1'
            sx={{ my: 2 }}
            component='p'
            fontSize='18px'
            color='#4A403A'
            fontWeight={400}
          >
            {staticTexts.filterBy}
          </Typography>

          {/* View Mode Filter */}
          <Accordion
            expanded={expandedSections.viewMode}
            onChange={() => handleToggle('viewMode')}
          >
            <AccordionSummary
              expandIcon={
                expandedSections.viewMode ? (
                  <IconButton size='small'>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton size='small'>
                    <Typography variant='h6'>+</Typography>
                  </IconButton>
                )
              }
            >
              <Typography>{staticTexts.viewMode}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={viewMode}
                onChange={(e) => {
                  e.stopPropagation();
                  handleViewModeChange(e);
                }}
              >
                {[
                  { value: 'list', label: staticTexts.listView },
                  { value: 'grid', label: staticTexts.gridView },
                ].map((item) => (
                  <Box
                    key={item.value}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <Box
                      component='span'
                      sx={{ flexGrow: 1, textAlign: 'left' }}
                    >
                      {item.label}
                    </Box>
                    <Radio
                      value={item.value}
                      checked={viewMode?.toLowerCase() === item.value.toLowerCase()}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>

          {/* Date Range */}
          {showDateFilter && (
            <Accordion
              expanded={expandedSections.dateRange}
              onChange={() => handleToggle('dateRange')}
            >
              <AccordionSummary
                expandIcon={
                  expandedSections.dateRange ? (
                    <IconButton size='small'>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton size='small'>
                      <Typography variant='h6'>+</Typography>
                    </IconButton>
                  )
                }
              >
                <Typography>{staticTexts.time}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  value={dateRange}
                  onChange={handleDateRangeChange}
                >
                  {[
                    { label: staticTexts.all, value: 'all' },
                    {
                      label: staticTexts.today,
                      value: {
                        from: moment().startOf('day').toISOString(),
                        to: moment().endOf('day').toISOString(),
                      },
                    },
                    {
                      label: staticTexts.yesterday,
                      value: {
                        from: moment().subtract(1, 'day').startOf('day').toISOString(),
                        to: moment().subtract(1, 'day').endOf('day').toISOString(),
                      },
                    },
                    {
                      label: staticTexts.lastWeek,
                      value: {
                        from: moment().subtract(1, 'week').startOf('week').toISOString(),
                        to: moment().subtract(1, 'week').endOf('week').toISOString(),
                      },
                    },
                    {
                      label: staticTexts.lastMonth,
                      value: {
                        from: moment().subtract(1, 'month').startOf('month').toISOString(),
                        to: moment().subtract(1, 'month').endOf('month').toISOString(),
                      },
                    },
                    {
                      label: staticTexts.lastYear,
                      value: {
                        from: moment().subtract(1, 'year').startOf('year').toISOString(),
                        to: moment().subtract(1, 'year').endOf('year').toISOString(),
                      },
                    },
                    {
                      label: staticTexts.customDate,
                      value: 'custom',
                    },
                  ].map((item) => (
                    <Box
                      key={item.label}
                      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
                    >
                      <Box
                        component='span'
                        sx={{ flexGrow: 1, textAlign: 'left' }}
                      >
                        {item.label}
                      </Box>
                      <Radio
                        value={typeof item.value === 'string' ? item.value : JSON.stringify(item.value)}
                        checked={
                          dateRange === item.value ||
                          (typeof dateRange === 'object' && JSON.stringify(dateRange) === JSON.stringify(item.value))
                        }
                      />
                    </Box>
                  ))}
                </RadioGroup>

                {/* Custom Date Range */}
                {dateRange === 'custom' && (
                  <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                    <TextField
                      type='date'
                      name='from'
                      label='From'
                      slotProps={{ inputLabel: { shrink: true } }}
                      value={customDate?.from}
                      onChange={handleCustomDateChange}
                      fullWidth
                    />
                    <TextField
                      type='date'
                      name='to'
                      label='To'
                      slotProps={{ inputLabel: { shrink: true } }}
                      value={customDate?.to}
                      onChange={handleCustomDateChange}
                      fullWidth
                    />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          <Divider />
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};

export default FilterDrawer;
