import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Grid2, Typography, useMediaQuery } from '@mui/material';
import { Button, ContentWrapper, CustomToggle, Modal, useSnackbar } from '../../components';
import { Search } from '@mui/icons-material';
import { getStaticTexts } from '../../assets';
import ProductList from '../../components/Layouts/ProductList';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomPagination from '../../components/Pagination';
import { Dialog } from '../../components/Dialog';
import { getClaimRequests, rejectClaimRequest } from '../../services/aggregation';
import { startLoader, stopLoader } from '../../utils';
import { acceptClaimRequest } from '../../services/claim-item';
import { ClaimRequestType } from '../../constants/ApiConstants';
import { textTransform } from '../../services/common';
import CustomButton from '../../components/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKeys } from '../../constants/AppConstants';
import DateTimeFormatting from '../../utils/extensions/DateTimeFormatting';
import { RESET_MESSAGES } from '../../redux';

/**
 * Component to show claim requests list
 *
 * @returns {JSX.Element} Component JSX
 */
const ClaimRequests = () => {
  const [selected, setSelected] = useState<ClaimRequestType>(ClaimRequestType.PENDING);
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => setOpenDialog(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const enqueueSnackbar = useSnackbar();
  const [claimRequests, setClaimRequests] = useState([]);
  const [claim, setClaim] = useState<any>();
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [selectedClaimToReject, setSelectedClaimToReject] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [itemId, setItemId] = useState('');
  const [claimedUserId, setClaimedUserId] = useState('');

  /**
   * Returns the selected tab's value.
   * @returns {string} The selected tab's value.
   */
  const getSelectedTab = () => {
    if (selected === ClaimRequestType.PENDING) {
      return 'ACTIVE';
    } else if (selected === ClaimRequestType.ACCEPTED) {
      return 'RESOLVED';
    } else if (selected === ClaimRequestType.REJECTED) {
      return 'REJECTED';
    }
  };

  /**
   * Rejects a claim request for a lost item.
   * @param {string} claimedUserId - The id of the user who is claiming the item.
   * @param {string} itemId - The id of the item being claimed.
   * @returns {Promise<void>} Resolves when the claim request is rejected.
   */
  const handleRejectClaim = async (claimedUserId: string, itemId: string) => {
    try {
      startLoader();
      await rejectClaimRequest(claimedUserId, itemId);
      enqueueSnackbar(staticTexts.claimRejectedSuccessfully);
      setOpenRejectModal(false);
      fetchClaimRequests();
    } catch (error: any) {
      enqueueSnackbar(error.response || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  /**
   * Fetches the list of claim requests based on the search query and status.
   * @returns {Promise<void>} Resolves when the claim requests are fetched.
   */
  const fetchClaimRequests = async () => {
    try {
      startLoader();
      const params = {
        page: page,
        limit: 10,
        text: searchQuery,
      } as any;

      const status = getSelectedTab();

      if (status) {
        params.status = status;
      }

      const response = (await getClaimRequests(params)) as any;
      setClaimRequests(response?.claims);
      setTotalPages(response?.meta?.totalPages);
      setTotalItems(response?.claims?.length ?? 0);
    } catch (error: any) {
      if (error.response.status === 409) {
        localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
        localStorage.removeItem(LocalStorageKeys.USER_TYPE);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_TOKEN);
        localStorage.removeItem(LocalStorageKeys.IMPERSONATE_USER_TYPE);
      }
      enqueueSnackbar(error.response || error.message || staticTexts.somethingWentWrong);
    } finally {
      stopLoader();
    }
  };

  useEffect(() => {
    fetchClaimRequests();
  }, [selected, page, searchQuery]);

  /**
   * Handles page change in pagination.
   * @param {React.ChangeEvent<unknown>} event - The page change event.
   * @param {number} value - The new page number.
   */
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  /**
   * Handles the toggle event on the claim request status tabs.
   * @param {ClaimRequestType} option - The selected claim request status.
   */
  const handleToggle = (option: ClaimRequestType) => {
    setSelected(option);
    setPage(1);
  };

  /**
   * Handles the search input event.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input event.
   */
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const getMenuOptions = () => {
    switch (selected) {
      case ClaimRequestType.PENDING:
        return [
          { label: staticTexts.view, onClick: () => setOpenDialog(true) },
          {
            label: staticTexts.decline,
            onClick: () => {
              setOpenRejectModal(true);
              setSelectedClaimToReject(claim);
            },
          },
          {
            label: staticTexts.accept,
            onClick: () => {},
          },
        ];
      case ClaimRequestType.REJECTED:
        return [
          { label: staticTexts.view, onClick: () => setOpenDialog(true) },
          {
            label: staticTexts.rejected,
            onClick: () => {},
          },
        ];
      default:
        return [{ label: staticTexts.view, onClick: () => setOpenDialog(true) }];
    }
  };

  /**
   * Handles the confirmation of a claim request.
   * Sends a request to accept the claim, fetches the updated list of claim requests,
   * resets the item and claimed user IDs, closes the modal, and displays a success message.
   * In case of an error, it displays the error message or a generic error message.
   */

  const handleConfirm = async () => {
    try {
      await acceptClaimRequest(claimedUserId, itemId);
      fetchClaimRequests();
      setItemId('');
      setClaimedUserId('');
      setIsOpen(false);
      enqueueSnackbar(staticTexts.claimRequestAccepted);
    } catch (error) {
      enqueueSnackbar((error as any).message || staticTexts.somethingWentWrong);
    }
  };

  /**
   * SearchInput component renders a styled search input field with an icon.
   * It allows users to enter search queries and submit them by pressing Enter.
   * The input value is managed by the searchText state, and changes are handled
   * by the handleSearch function. When Enter is pressed, the search query is
   * updated, and pagination resets to the first page.
   *
   * @returns {JSX.Element} JSX for the search input component.
   */

  const SearchInput = () => {
    return (
      <div className='relative w-1/2 rounded-lg border border-[#C9C2B4]'>
        <input
          type='text'
          placeholder={staticTexts.search}
          className='w-full ps-10 pr-4 py-2 rounded-lg border-none outline-none bg-[#FFF7E6] custom-placeholder'
          value={searchText}
          onChange={handleSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchQuery(searchText);
              setPage(1);
            }
          }}
          autoFocus
        />
        <span className='absolute left-3 top-1/2 transform -translate-y-1/2 text-[#776E65]'>
          <Search />
        </span>
      </div>
    );
  };

  const handleClose = () => setIsOpen(false);

  /**
   * Handles the chat navigation process.
   * Resets chat messages and navigates to the chat page with initial state setup.
   * The state includes information about the receiver, item, username, and user profile.
   * Logs any errors encountered during the process.
   */

  const handleChat = async () => {
    try {
      dispatch({
        type: RESET_MESSAGES,
      });
      navigate('/chat', {
        state: {
          isFirstTime: true,
          _receiver: claim?._userClaimBy,
          _item: claim?._item,
          username: claim?.claimeduserName,
          userProfile: claim?.claimedUserProfile,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ContentWrapper
        title={staticTexts.claimRequests}
        alignText='left'
        mdWidth='80%'
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
        >
          <CustomToggle
            selected={selected}
            width='132px'
            options={[
              { key: ClaimRequestType.PENDING, labelKey: 'pending' },
              { key: ClaimRequestType.ACCEPTED, labelKey: 'accepted' },
              { key: ClaimRequestType.REJECTED, labelKey: 'rejected' },
            ]}
            handleToggle={handleToggle}
          />
        </Box>
        <Typography
          textAlign='left'
          component='p'
          className='w-full'
          marginTop='24px'
          fontSize='22px'
          letterSpacing='0.01px'
          color='#4A403A'
          marginBottom='16px'
        >
          {staticTexts.claimRequests}
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
        >
          <SearchInput />

          <Box
            display='flex'
            alignItems='center'
          >
            <span className='text-secondary-200 text-sm me-4'>
              {!totalItems || totalItems === 0
                ? staticTexts.noClaimRequests
                : `${totalItems} ${staticTexts.claimRequests}`}
            </span>
          </Box>
        </Box>
        {claimRequests?.length > 0 ? (
          <Fragment>
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
              marginTop='16px'
              width='100%'
            >
              {(claimRequests || []).map((claimRequest: any, index) => (
                <ProductList
                  key={index}
                  title={claimRequest?.name}
                  description={claimRequest?.description}
                  location={claimRequest?.location?.name}
                  visibility={claimRequest?.visibleToEveryone}
                  date={moment(claimRequest?.eventDate).toLocaleString()}
                  imageUrl={claimRequest?.images[0]}
                  showDate={false}
                  showLocation={false}
                  showVisibility={false}
                  minWidth='100%'
                  showMoreOptions={isSmallScreen}
                  menuOptions={getMenuOptions()}
                  customActions={
                    <Box>
                      <Button
                        className='me-4'
                        variant='text'
                        buttonStyle={{ color: '#655B53FA' }}
                        onClick={() => {
                          setOpenDialog(true);
                          setClaim(claimRequest);
                        }}
                      >
                        <span className='underline'>{staticTexts.view}</span>
                      </Button>
                      <Button
                        className={`${selected !== ClaimRequestType.PENDING ? '!hidden' : ''}`}
                        variant='outlined'
                        buttonStyle={{
                          border: '1px solid #C9C2B4',
                          color: '#655B53FA',
                          marginInlineEnd: '8px',
                        }}
                        onClick={() => {
                          setOpenRejectModal(true);
                          setSelectedClaimToReject(claimRequest);
                        }}
                      >
                        {staticTexts.decline}
                      </Button>
                      <Button
                        className={`${selected !== ClaimRequestType.PENDING ? '!hidden' : ''}`}
                        variant='contained'
                        buttonStyle={{
                          background: '#FF6F61',
                          color: '#FFF7E6',
                        }}
                        onClick={() => {
                          setClaimedUserId(claimRequest?._userClaimBy);
                          setItemId(claimRequest?._item);
                          setIsOpen(true);
                        }}
                      >
                        {staticTexts.accept}
                      </Button>
                    </Box>
                  }
                />
              ))}
            </Box>

            <CustomPagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />

            <Modal
              open={openRejectModal}
              onClose={() => setOpenRejectModal(false)}
              onConfirm={() => {
                if (selectedClaimToReject) {
                  handleRejectClaim(selectedClaimToReject._userClaimBy, selectedClaimToReject._item);
                }
              }}
              confirmText={staticTexts.confirm}
              cancelText={staticTexts.cancel}
              customStyles={{
                width: '360px',
                bgcolor: '#FFF6E4',
                textAlign: 'center',
                borderRadius: '12px',
              }}
            >
              <Typography variant='body1'>{staticTexts.areYouSureWantToRejectClaimRequest}</Typography>
            </Modal>

            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              title={staticTexts.claimItemDetails}
              maxWidth='lg'
              titlePadding='16px 24px'
              contentPadding='0px 24px'
              fullWidth={true}
              content={
                <Box>
                  {/* Item Details Section */}
                  <Typography
                    variant='h6'
                    fontSize='16px'
                    color='#4A403A'
                    gutterBottom
                  >
                    {staticTexts.itemDetails}
                  </Typography>
                  <Grid2
                    container
                    spacing={2}
                  >
                    <Grid2 size={{ xs: 12, sm: 6 }}>
                      <Box marginBottom='24px'>
                        <Typography
                          component='p'
                          fontSize='12px'
                          color='#776E65'
                        >
                          {staticTexts.itemName}
                        </Typography>
                        <Typography
                          component='p'
                          fontSize='14px'
                          color='#4A403A'
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {textTransform(claim?.name)}
                        </Typography>
                      </Box>

                      <Box marginBottom='24px'>
                        <Typography
                          component='p'
                          fontSize='12px'
                          color='#776E65'
                        >
                          {staticTexts.location}
                        </Typography>
                        <Typography
                          component='p'
                          fontSize='14px'
                          color='#4A403A'
                        >
                          {textTransform(claim?.location?.name)}
                        </Typography>
                      </Box>
                    </Grid2>

                    <Grid2 size={{ xs: 12, sm: 6 }}>
                      <Box marginBottom='24px'>
                        <Typography
                          component='p'
                          fontSize='12px'
                          color='#776E65'
                        >
                          {staticTexts.fullName}
                        </Typography>
                        <Typography
                          component='p'
                          fontSize='14px'
                          color='#4A403A'
                        >
                          {claim?.claimeduserName}
                        </Typography>
                      </Box>

                      <Box marginBottom='24px'>
                        <Typography
                          component='p'
                          fontSize='12px'
                          color='#776E65'
                        >
                          {staticTexts.dateLost}
                        </Typography>
                        <Typography
                          component='p'
                          fontSize='14px'
                          color='#4A403A'
                        >
                          {DateTimeFormatting.getDateByFormatCustomUTC(
                            claim?.eventDate ?? '',
                            true,
                            DateTimeFormatting.DateTimeFormat.DD_MMM_yyyy
                          )}
                        </Typography>
                      </Box>
                    </Grid2>

                    <Box marginBottom='24px'>
                      <Typography
                        component='p'
                        fontSize='12px'
                        color='#776E65'
                      >
                        {staticTexts.description}
                      </Typography>
                      <Typography
                        component='p'
                        fontSize='14px'
                        color='#4A403A'
                      >
                        {textTransform(claim?.description)}
                      </Typography>
                    </Box>
                  </Grid2>

                  {claim?.questions?.length > 0 && (
                    <Box>
                      <Typography
                        component='p'
                        fontSize='12px'
                        color='#776E65'
                        gutterBottom
                      >
                        {staticTexts.questions}
                      </Typography>
                      <Grid2
                        container
                        spacing={2}
                      >
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                          {(claim.questions || []).map((question: any) => (
                            <Box
                              key={question._question?._id}
                              marginBottom='24px'
                            >
                              <Typography
                                component='p'
                                fontSize='12px'
                                color='#776E65'
                              >
                                {language === 'en' ? question.label?.en : question.label?.ar}
                              </Typography>
                              <Typography
                                component='p'
                                fontSize='14px'
                                color='#4A403A'
                              >
                                {question?.value || 'N/A'}
                              </Typography>
                            </Box>
                          ))}
                        </Grid2>
                      </Grid2>
                    </Box>
                  )}

                  {/* Uploaded Images Section */}
                  {claim?.images?.length > 0 && (
                    <Box mt={4}>
                      <Typography
                        variant='h6'
                        fontSize='16px'
                        color='#4A403A'
                        gutterBottom
                      >
                        {staticTexts.uploadImages}
                      </Typography>
                      <Box
                        display='flex'
                        gap='8px'
                        paddingBottom='24px'
                      >
                        {(claim?.images || []).map((image: any, index: number) => (
                          <img
                            key={index}
                            src={image}
                            alt={`Item ${index + 1}`}
                            style={{
                              width: '60px',
                              height: '60px',
                              borderRadius: '8px',
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}

                  <Box
                    display='flex'
                    gap='16px'
                    justifyContent='end'
                    width='100%'
                    paddingBottom='24px'
                    marginTop='auto'
                  >
                    <CustomButton
                      title={`${staticTexts.chatWith} ${claim?.claimeduserName}`}
                      onPress={() => handleChat()}
                    />
                  </Box>
                </Box>
              }
            />

            <Modal
              open={isOpen}
              onClose={handleClose}
              onConfirm={handleConfirm}
              confirmText={staticTexts.confirm}
              cancelText={staticTexts.close}
              customStyles={{
                width: '360px',
                bgcolor: '#FFF6E4',
                textAlign: 'center',
                borderRadius: '12px',
              }}
            >
              <Typography
                component='p'
                letterSpacing='0.01px'
                fontWeight={400}
                fontSize='22px'
                sx={{ mb: 1 }}
              >
                {staticTexts.areYouSureYouWantToResolveThisItem}
              </Typography>
              <Typography
                variant='body1'
                fontSize='14px'
                sx={{ color: '#655B53FA' }}
              >
                {staticTexts.yourItemWillBeResolved}
              </Typography>
            </Modal>
          </Fragment>
        ) : (
          <Box
            height='400px'
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            {staticTexts.noItemsAvailable}
          </Box>
        )}
      </ContentWrapper>
    </>
  );
};

export default ClaimRequests;
