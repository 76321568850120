import React, { Fragment, useRef, useState } from 'react';
import { Search, MyLocation } from '@mui/icons-material';
import { Button, ControlToolbar, GoogleMap } from '../../../../components';
import { Box, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../../assets';
import ProductCard from '../../../../components/Layouts/ProductCard';
import FilterDrawer from '../../../../components/Layouts/FilterDrawer';
import { Dialog } from '../../../../components/Dialog';
import { handleUseCurrentLocation, startLoader, stopLoader } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import ProductList from '../../../../components/Layouts/ProductList';
import { searchItems } from '../../../../services/home';
import { SET_SEARCH_PRODUCTS } from '../../../../redux';
import { textTransform } from '../../../../services/common';
import { CategoryData } from '../../../../model/CategoryData';
import StringFormatting from '../../../../utils/extensions/StringFormatting';
import CustomPagination from '../../../../components/Pagination';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoItems from '../../../../components/Layouts/NoItems';

export type ExpandedSectionsType = {
  viewMode?: boolean;
  dateRange?: boolean;
  category?: boolean;
};


/**
 * SearchMobile Component
 *
 * A React component to render search form and search items on mobile view
 *
 * @param {object} props - Component props
 * @returns {ReactElement} A React element representing the SearchMobile component
 */
export const SearchMobile: React.FC = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [searchItem, setSearchItem] = useState('');
  const locationInputRef = useRef<HTMLInputElement>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState<{
    viewMode: 'list' | 'grid';
    sortBy: 'newest' | 'oldest' | 'A-Z' | 'Z-A';
    dateRange: {
      from: string;
      to: string;
    } | string;
    customDate: {
      from: string;
      to: string;
    };
  }>({
    viewMode: 'grid',
    sortBy: 'newest',
    dateRange: 'all',
    customDate: { from: '', to: '' },
  });
    // State to control accordion expansion
    const [expandedSections, setExpandedSections] = useState<Partial<ExpandedSectionsType>>({
      viewMode: false,
      dateRange: false,
    });
    
  

  const [meta, setMeta] = useState({
    totalPages: 0,
    page: 1,
    limit: 20,
  });
  const [page, setPage] = useState(1);

  // Categories List
  const categories = useSelector((state: any) => [
    {
      _id: '',
      name: staticTexts.all,
    },
    ...state.category,
  ]);

  // Category Dropdown State
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Selected Category State
  const [selectedCategory, setSelectedCategory] = useState<CategoryData>(categories[0]);

  const dispatch = useDispatch();

  const [showSearchOptions, setShowSearchOptions] = useState(true);
  const [showProductListing, setShowProductListing] = useState(false);
  const [dateFilter, setDateFilter] = useState<any>(null);
  const [sortFilter, setSortFilter] = useState<any>(null);
  const [viewType, setViewType] = useState(staticTexts.grid);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    description: string;
  } | null>(null);
  const [state, setState] = useState({
    isDialogOpen: false,
  });
  const navigate = useNavigate();

  /**
   * Closes the dialog by updating the state to set isDialogOpen to false.
   */
  const closeDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: false,
    }));
  };

  /**
   * Opens the dialog by updating the state to set isDialogOpen to true.
   */
  const openDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: true,
    }));
  };

  /**
   * Handles the user selecting a location.
   * @param {Object} location - An object containing the latitude, longitude, and description of the selected location.
   */
  const handleLocationSelect = (location: { lat: number; lng: number; description: string }) => {
    setSelectedLocation(location);
    closeDialog();
  };

  /**
   * Handles the search query and filters.
   * @param {{
   *   dateFilter?: { startDate: string; endDate: string; };
   *   sortFilter?: { sortBy: string; };
   *   viewMode: string;
   * }} params - An object containing the search query, date filter, sort filter and view type.
   * The date filter object should have startDate and endDate properties.
   * The sort filter object should have sortBy property.
   * The viewMode should be either 'Grid' or 'List'.
   * @returns {Promise<void>}
   */
  const handleSearch = async ({
    dateFilter = {},
    sortFilter = {},
    viewMode = staticTexts.grid,
  }: {
    dateFilter?: any;
    sortFilter?: any;
    viewMode: string;
  }) => {
    try {
      // Show Loading
      startLoader();

      const params: any = {
        page: page,
        limit: meta.limit,
      };

      const response = (await searchItems(
        {
          text: searchItem,
          ...(selectedCategory._id && { categoryId: selectedCategory._id }),
          ...(selectedLocation?.lat &&
            selectedLocation.lng && {
            location: {
              latitude: selectedLocation?.lat,
              longitude: selectedLocation?.lng,
            },
          }),
          ...(dateFilter && {
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          }),
          ...(sortFilter && { sort: sortFilter.sortBy }),
        },
        {
          params,
        }
      )) as any;
      setViewType(viewMode);

      // Dispatch API Response List
      dispatch({
        type: SET_SEARCH_PRODUCTS,
        payload: response.items ?? [],
      });
      setMeta({
        totalPages: response?.meta?.totalPages || 0,
        page: response?.meta?.page || 1,
        limit: response?.meta?.limit || 20,
      });
    } catch (error) {
      console.log(error);
    } finally {
      // Dismiss Loading
      stopLoader();
    }
    setShowSearchOptions(false);
    setShowProductListing(true);
  };

  /**
   * A component that renders a search result page with a filter drawer.
   * It fetches products from the API based on the search filters and displays them
   * in a grid or list view. The user can also sort the products by date or name.
   * The component also handles pagination and shows a "Show More" button at the
   * bottom of the page if there are more products to be loaded.
   *
   * @returns {JSX.Element} The SearchItems component.
   */
  const SearchItems = (props: any) => {
    const { openFilter, setOpenFilter } = props;
    const language = useSelector((state: any) => state.language);
    const staticTexts = getStaticTexts(language);
    const products = useSelector((state: any) => state.searchProducts);
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    /**
     * Updates the view type state with the provided value.
     *
     * @param {string} value - The new view type to be set.
     */
    const handleViewTypeChange = (value: string) => {
      setViewType(value);
    };


    const handleMobileFilter = () => {
      const { dateRange, sortBy, customDate, viewMode } = filters;

      let dateRangeFrom = '';
      let dateRangeTo = '';

      // Check if "All" is selected (dateRange is 'all')
      if (dateRange === 'all') {
        dateRangeFrom = '';
        dateRangeTo = '';
      } else if(dateRange === 'custom'){

       const { from: customDateFrom, to: customDateTo } = customDate || {};
       dateRangeFrom = customDateFrom;
       dateRangeTo = customDateTo;
      }
       else if (typeof dateRange === 'object' && dateRange !== null) {
        // Check if dateRange is an object with valid 'from' and 'to'
        dateRangeFrom = dateRange.from;
        dateRangeTo = dateRange.to;
      }

      // Prepare API payload conditionally
      const dateFilter =
        dateRange === 'all'
          ? {}
          : {
            startDate:  dateRangeFrom,
            endDate: dateRangeTo,
          };

      handleSearch({
        dateFilter,
        sortFilter: {
          sortBy: sortBy?.toUpperCase(),
        },
        viewMode,
      });

      toggleDrawer(false);
    };



    /**
     * Sets the state of the drawer to the given boolean value.
     *
     * @param {boolean} state - The new state of the drawer.
     */
    const toggleDrawer = (state: boolean) => {
      setOpenFilter(state);
      setExpandedSections({
        viewMode: false,
        dateRange: false
      })
    };

    const handleClose = () => {
      setOpenFilter(false);
    }

    const handleSortFilterChange = (event: any) => {
      setFilters((prev) => ({
        ...prev,
        sortBy: event.target.value
      }))
    }

    const handleDateRangeChange = (event: any) => {
      const value = event.target.value;
      // Check if custom date selected
      if (value === 'custom') {
        setFilters({ ...filters, dateRange: 'custom' });
      } else if (value === 'all') {
        setFilters({ ...filters, dateRange: 'all' });
      } else {
        // If JSON string, parse the date range
        const parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
        setFilters({
          ...filters,
          dateRange: parsedValue,
          customDate: { from: '', to: '' }, 
        });
      }
    }

    const handleViewModeChange = (event: any) => {
      setFilters(({
        ...filters,
        viewMode: event.target.value
      }))
    };

    const handleCustomDateChange = (event: any) => {
      const { name, value } = event.target;

      setFilters({
        ...filters,
        customDate: {
          ...filters.customDate,
          [name]: value,
        },
      });
    };

    const handleClearAll = () => {
      setFilters({
        viewMode: 'grid',
        sortBy: 'newest',
        dateRange: 'all',
        customDate: { from: '', to: '' },
      })

      toggleDrawer(false)
     }

    return (
      <Box
        className='w-fullflex flex-col'
        sx={{ paddingBottom: '16px' }}
      >
        <Box
          className='mx-auto'
          width={{
            xs: '92%',
            md: '80%',
          }}
          minHeight={{
            xs: '0px',
            md: '700px',
          }}
        >
          <ControlToolbar
            selectedTab={`${StringFormatting.capitalizeFirstLetterOfEveryWord(selectedCategory?.name)}`}
            viewType='Grid'
            onViewChange={handleViewTypeChange}
            itemCount={100}
            showButton={false}
            handleFilters={() => setOpenFilter(true)}
            onSearch={(event: any) => console.log(event)}
          />

          <Box className='w-full'>
            {products.length > 0 && viewType.toUpperCase() === staticTexts.list.toUpperCase() ? (
              products.map((product: any, index: number) => (
                <div className='mb-2'>
                  <ProductList
                    key={index}
                    title={product.name}
                    description={product.description}
                    location={product.location?.name}
                    visibility={product.type}
                    date={product.date}
                    imageUrl={product.images[0]}
                    itemId={product._id}
                    showActions={false}
                    showClaimButton={true}
                    showMoreOptions={isSmallScreen}
                    onDelete={() => console.log('Delete clicked')}
                    menuOptions={[
                      {
                        label: staticTexts.claimItem,
                        onClick: () => navigate(`/item-detail/${product._id}`),
                      },
                    ]}
                  />
                </div>
              ))
            ) : products.length > 0 && viewType.toUpperCase() === staticTexts.grid.toUpperCase() ? (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: '1fr 1fr',
                    sm: '1fr 1fr',
                    lg: '1fr 1fr 1fr',
                  },
                  gap: '16px',
                  width: '100%',
                }}
              >
                {products.map((product: any, index: number) => (
                  <ProductCard
                    key={index}
                    itemId={product._id}
                    image={product.image}
                    images={product.images}
                    itemCategory={product?._category?.name}
                    itemName={product.name}
                    description={product.description}
                    location={product.location.name}
                    date={product.date}
                    type={product.type}
                    showActions={false}
                    showPrivacy={false}
                    showClaimButton={true}
                  />
                ))}
              </Box>
            ) : <NoItems />}
          </Box>

          <FilterDrawer
            filters={filters}
            expandedSections={expandedSections}
            setExpandedSections={setExpandedSections}
            onApply={handleMobileFilter}
            isOpen={openFilter}
            toggleDrawer={toggleDrawer}
            handleClearAll={handleClearAll}
            handleClose={handleClose}
            handleSortFilterChange={handleSortFilterChange}
            handleViewModeChange={handleViewModeChange}
            handleDateRangeChange={handleDateRangeChange}
            handleCustomDateChange={handleCustomDateChange}
          />
        </Box>

        {meta.totalPages > 1 && (
          <CustomPagination
            count={meta.totalPages}
            page={page}
            onChange={handlePageChange}
          />
        )
        }

      </Box>
    );
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Fragment>
      <div className='bg-[#ECE4D5]'>
        <div className={`w-full p-4 ${!showSearchOptions && 'bg-[#FFF7E6]'}`}>
          {/* Header */}
          {showSearchOptions && (
            <div className='flex items-center mb-6 text-accent'>
              <button
                className='text-coral-500 flex items-center'
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon className='w-5 h-5 mr-2' />
                <span>{staticTexts.back}</span>
              </button>
            </div>
          )}

          {/* Search Form */}

          <div className='flex justify-center items-center w-full flex-col text-sm'>
            {showSearchOptions && (
              <Typography
                fontSize='22px'
                marginBottom='12px'
                color='#4A403A'
                variant='body2'
                className='self-start'
              >
                {staticTexts.searchLostItems}
              </Typography>
            )}

            {/* Search Input */}
            <div className='relative w-full'>
              <input
                type='text'
                placeholder={staticTexts.searchLostItems}
                autoFocus
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
                onFocus={() => {
                  setShowSearchOptions(true);
                  setShowProductListing(false);
                }}
                className='w-full custom-placeholder p-3 rounded-lg bg-[#FFF7E6] focus:outline-none shadow-[0_0_12px_rgba(0,0,0,0.08)]'
              />
              {!showSearchOptions && (
                <button
                  onClick={() => setShowProductListing(true)}
                  className='absolute h-8 w-8 bg-[#FF6F61] rounded-full ltr:right-1 rtl:left-1 top-1/2 -translate-y-1/2 text-coral-500 flex justify-center items-center text-sm'
                >
                  <SearchIcon
                    className='w-4 h-4 text-[#FFF7E6]'
                    sx={{ fontSize: '18px' }}
                  />
                </button>
              )}
            </div>

            {showSearchOptions && (
              <Fragment>
                {/* Location Input */}
                <div className='relative my-2 w-full'>
                  <input
                    type='text'
                    ref={locationInputRef}
                    readOnly
                    placeholder={staticTexts.searchCityAreaOrLocality}
                    value={selectedLocation?.description}
                    disabled
                    className='w-full custom-placeholder p-3 pe-44 rounded-lg bg-[#FFF7E6] focus:outline-none'
                  />

                  {
                    !selectedLocation?.description ? (
                      <button className='absolute ltr:right-3 rtl:left-3 top-1/2 -translate-y-1/2 text-coral-500 flex items-center text-sm'>
                        <span
                          className='text-accent me-2'
                          onClick={async () => {
                            const currentLocation = (await handleUseCurrentLocation()) as any;
                            if (currentLocation) {
                              setSelectedLocation({
                                lat: currentLocation?.latitude,
                                lng: currentLocation?.longitude,
                                description: currentLocation?.locationName,
                              });
                            }
                          }}
                        >
                          {staticTexts.useCurrentLocation}
                        </span>
                        <MyLocation
                          onClick={() => openDialog()}
                          className='w-4 h-4 ml-1 text-accent'
                          sx={{ fontSize: '16px' }}
                        />
                      </button>
                    ) : (
                      <HighlightOffIcon
                        sx={{ fontSize: '14px' }}
                        className='absolute right-2 text-accent text-sm cursor-pointer top-4'
                        onClick={() => {
                          setSelectedLocation(null);
                          if (locationInputRef?.current) {
                            locationInputRef.current.value = "";
                          }
                        }}
                      />
                    )

                  }



                </div>

                {/* Category Dropdown */}
                <div className='relative w-full'>
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className='w-full p-3 rounded-lg bg-[#FFF7E6] text-[#776E65] text-left flex items-center justify-between'
                  >
                    <span>{textTransform(selectedCategory?.name)}</span>
                    <KeyboardArrowDownIcon
                      className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                    />
                  </button>

                  {isDropdownOpen && (
                    <ul className='absolute w-full mt-1 rounded-lg shadow-lg bg-[#fff7e6]'>
                      {categories.map((category: any) => (
                        <li
                          key={category._id}
                          onClick={() => {
                            setSelectedCategory(category);
                            setIsDropdownOpen(false);
                          }}
                          className='w-full p-3 text-left hover:!bg-[#FFE0B5]'
                        >
                          {textTransform(category.name)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Search Button */}
                {!isDropdownOpen && (
                  <Button
                    buttonStyle={{
                      marginTop: '24px',
                    }}
                    onClick={() =>
                      handleSearch({
                        dateFilter,
                        sortFilter,
                        viewMode: staticTexts.grid,
                      })
                    }
                    className='p-3 rounded-lg self-end flex items-center justify-center space-x-2 hover:bg-coral-600 transition-colors'
                  >
                    <Search className='w-5 h-5' />
                    <span>{staticTexts.search}</span>
                  </Button>
                )}
              </Fragment>
            )}

            <Dialog
              open={state.isDialogOpen}
              title=''
              content={<GoogleMap onLocationSelect={handleLocationSelect} />}
              onClose={closeDialog}
            />
          </div>
        </div>
      </div>

      {/*Item Listing*/}
      {showProductListing && <SearchItems openFilter={openFilter} setOpenFilter={setOpenFilter} />}
    </Fragment>
  );
};
